import React, { useCallback, useEffect } from "react";
import { User, UserCredentials, userEmpty } from "../models/user";
import userService  from "../Services/user.service";
import TokenService from "../Services/token.service";
import Swal from "sweetalert2";
export interface AuthContextType {
  user: User;
   signin: (credentials: any) => Promise<User | null>;
   signout: () => Promise<void>;
  // signup: (credentials: CreateUserInput) => Promise<void>;
   refreshUser: () => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<User>({
    id: "unknown",
    email: "",
    name: "Guest",
    questionnaireSubmitted: false,
    role:"user",// [Roles.User],
    firstName:"Guest",
    lastName:""
  });
  // const [logIn] = useLogInLazyQuery();
  // const [signUp] = useCreateAccountMutation();
  // const [getCurrentUserQuery] = useGetCurrentUserLazyQuery();

  const signin = async (credentials: UserCredentials) => {
    try { 
      const userCredential = JSON.stringify({ email:  credentials.email,password:credentials.password });

      const result = await userService.Login(userCredential); 
      console.log(result);

      //If we got an error message back rather than a user, throw the error
      if(typeof result === 'string' || result instanceof String){
        throw result;
      }
      // if(result==="Login Failed")
      // {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Login Failed", 
      //   });
      // }
      TokenService.setUser(result); 
      const user =  getCurrentUser(result.user);
      return user;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getCurrentUser = (data:any) => {
    try {
      const user = data as User;
      if (user) { 
        setUser({
          email: user?.email,
          id: user?.id,
          name: user?.firstName + user?.lastName,
          questionnaireSubmitted: false,//user.questionnaireSubmitted,
          role: user.role,
          firstName:user.firstName,
          lastName: user.lastName
        });
      } else {
        setUser(userEmpty());
      }
      //console.log(user);
      return user ?? null;
    } catch (error) {
      setUser(userEmpty());
      console.log(error);
      throw error;
    }
  };

  const signout = async () => {
    setUser(userEmpty());
    TokenService.removeUser(); 
  };
 
  useEffect(() => {
    let response = TokenService.getUser(); 
    if(response)
    {
      getCurrentUser(response.user);
    }
  }, []);
 

  async function refreshUser() {
    getCurrentUser("");
  }
  const value = { user,signin,signout,refreshUser};

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}