
const MailingList = (props:any) => {
    return (
        <div>
    
              <div className="inset-0 p-10 overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center">
                    Thank you for your interest in Credental. <br></br>
                    Unfortunately, Credental is not yet available in your state.<br></br>
                    You will receive an email when Credental is ready to begin services in your state.
                </div>
              </div>
        </div>
    )
    }
export default MailingList