import { ChangeRequestModel } from './ChangeRequestModel';
import './InsuranceChangeRequest.css';
import { useEffect, useState } from 'react';
import { Textarea } from '@material-tailwind/react';
import 'react-toastify/dist/ReactToastify.css';
import {  useFormik } from 'formik';
import * as Yup from 'yup'
import { ToastContainer } from 'react-toastify';
import ChangeRequestConfirmation from './ChangeRequestConfirmation';

const initialValues = {
  answerId: 0,
  credentialRequestId: 0,
  comment: '',
  id: 0,
  resolved: true,
}

const chnageRequestsSchema = Yup.object({
  comment: Yup.string()
  //   .min(3, 'Name must be at least 3 characters')
  //   .max(100, 'Name cannot exceed 40 characters')
  // .matches(/^[a-zA-Z ]*$/, 'Enter a valid name')
})
function ChangeRequestItem({
  data,
  isOpen,
  btnOnClick,
  getChangeRequestsData,
}: {
  data: ChangeRequestModel;
  isOpen: boolean;
  btnOnClick: () => void;
  getChangeRequestsData: any;
}) {
  //const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [isOpenConfirmPopUp, setIsOpenConfirmPopUp] = useState(false);
  const [selectedComment, setSelectedComment] = useState([{}]);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  useEffect(() => {
    setIsOpenConfirmPopUp(false);
    if (isOpen) {
      //const contentEl = contentRef.current as HTMLDivElement;  
      setHeight(250);
    } else {
      setHeight(0);
    }
  }, [isOpen]);


  const { values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: chnageRequestsSchema,
    onSubmit: (values) => {
      //btnOnClickResolve(values)
      setIsOpenConfirmPopUp(true);
      setIsPopUpOpen(true);
      let payload = [{
        id: data.id,
        comment: values.comment,
        resolved: true
      }]
      setSelectedComment(payload)
      resetForm()
    },
  })

  const stateManager = () => {
    setIsPopUpOpen(false)
    setIsOpenConfirmPopUp(false);
  }

  return (
    <><form className='mt-3' onSubmit={handleSubmit}>
      <li className={`accordion-item mb-3 ${isOpen ? 'active' : ''}`}>

        <h2 className="accordion-item-title">
          <button type='button' className="accordion-item-btn" onClick={btnOnClick}>
            <span className='text-sm flex items-center justify-between w-full'><h1 className='text-xl text-left'>{data.questionPrompt}</h1>{data.resolved == true ? <h1 className='accordion-item-status-resolved px-2'>RESOLVED</h1> : <p className='accordion-item-status-pending px-2'>PENDING</p>}</span>
          </button>
        </h2>

        <div className="accordion-item-container relative" style={{ height }}>
          <div className='accordion-item-content grid grid-cols-2 gap-4'>

            <div className="">
              {data.answerText}
            </div>

            <div className="">
              <Textarea
                label="Comment"
                defaultValue={data.comment}
                onChange={(text) => { setFieldValue('comment', text.target.value); }}
                disabled={data.resolved == true ? true : false} />
            </div>
          </div>

          <div className='absolute insert-x-1 bottom-1 w-full border-t border-[#ccc] py-2'>
            <button className={`${data.resolved === true ? 'rounded-sm float-right text-white bg-[#D0D3D5] font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ' : 'rounded-sm float-right text-white bg-[#2196f3] font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'} `}
              type="submit"
              disabled={data.resolved == true ? true : false}>
              Resolve
            </button>
          </div>

        </div>
      </li>
      <ToastContainer />
    </form>
      <ChangeRequestConfirmation
        isOpen={isOpenConfirmPopUp}
        data={selectedComment}
        stateManager={stateManager}
        getChangeRequestsData={getChangeRequestsData} /></>
  );
}

export default ChangeRequestItem;