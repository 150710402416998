import { Form, Formik } from "formik";
import { PlanCardParams } from "../../../../models/planCard";
import InsurancePaymentForm from "./insurancePaymentForm";

export default function PaymentPlanCard({
  plan,
  setValue,
}: {
  plan: PlanCardParams;
  setValue: (value: string) => void;
}) {
  return (
    <div className="bg-onBackground flex flex-col items-stretch px-14 py-8 rounded-3xl mr-8 text-center shadow-xl">
      {/* <span className="font-bold text-primary">Plan {plan.}</span> */}
      <div className="flex items-end mt-10 leading-7 text-gray-900 border-0 border-gray-200 mb-4">
        <p className="m-0 text-4xl font-semibold border-solid">
          ${(plan.annualPrice / 12).toFixed(2)}
        </p>
        <p>/month</p>
      </div>
      <h4 className="mb-8">
        <span className="text-gray-900 text-xl">${plan.annualPrice}</span>/year
      </h4>
      <h4 className="mb-10">
        <span className="text-gray-900 text-xl">${plan.monthPrice}</span> paid
        montly
      </h4>
      <span className="mb-8">
        {plan.minInsurances} - {plan.maxInsurances} Insurances
      </span>
      <span className="mb-10">{plan.info}</span>

      <hr className="bg-blue-300 border-primary border-2" />
      <Formik
        initialValues={{
          paymentFrequency: "montly",
        }}
        onSubmit={async () => null}
      >
        {({ isSubmitting, values }) => {
          setValue(values.paymentFrequency);

          return (
            <Form className="mt-6 flex flex-col items-end">
              <InsurancePaymentForm montlyPrice={plan.monthPrice} annuallyPrice={plan.annualPrice} />

              {/* <div className="flex justify-end w-full">
                <button
                  className="w-full bg-blue-700 rounded-lg py-3 text-white my-4 max-w-sm"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Continue
                </button>
              </div> */}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
