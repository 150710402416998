import useAuth from "../../hooks/useAuth";
import { useNavigate } from 'react-router-dom'
import { Avatar } from "@material-tailwind/react";
import { Breadcrumbs } from "@material-tailwind/react";
import { ChevronDownIcon, ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { BellIcon, ChatIcon, LogoutIcon } from "@heroicons/react/outline";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button
} from "@material-tailwind/react";
import  defaultImage  from "../../screens/images/userdefault.png"
export default function TopBar() {
  const { user, signout } = useAuth();
  const navigate = useNavigate(); 
  const logOut = async () => {
    await signout()
    window.localStorage.clear();
    navigate('/login')
  }

  return (
    <div className="bg-white border-b border-solid border-grey-200 flex-shrink-0 px-5 py-2 flex justify-between items-center h-16">
      <div className="flex items-center">
        <ArrowNarrowLeftIcon width={20} className="hidden"/>
        <Breadcrumbs className="bg-white hidden">
          <a href="#" className="opacity-60">
            Credentialing
          </a>
          <a href="#">
            Insurance
          </a>
        </Breadcrumbs>
      </div>
      <div className="flex justify-between items-center gap-3">
        <div className="flex justify-center items-center w-10 h-12 text-bluish-grey rounded-md cursor-pointer p-2 hidden ">
          <ChatIcon />
        </div>
        <div className="flex justify-center items-center w-10 h-12 text-bluish-grey rounded-md cursor-pointer p-2 hidden ">
          <BellIcon />
        </div>
        <Menu placement={"bottom-end"} offset={{mainAxis: 8}}>
          <MenuHandler>
            <div className="flex justify-between items-center gap-1 cursor-pointer p-2">
              <Avatar size="sm" src={defaultImage}/>
              <div className="flex justify-center items-center w-6 h-8 text-bluish-grey rounded-md">
                <ChevronDownIcon />
              </div>
            </div>
          </MenuHandler>
          <MenuList>
          <MenuItem onClick={logOut}>
              <div className="flex items-center gap-2 text-grey-500">
              <span className="">Hello , {user.name}</span>
              </div>
            </MenuItem>
            <MenuItem onClick={logOut}>
              <div className="flex items-center gap-2 text-grey-500">
                <LogoutIcon width={20}/> <div>Log Out</div>
              </div>
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
    </div>
  );
}