import { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button, Input } from '@material-tailwind/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import credentialService from '../../../Services/credential.services';
import { useNavigate } from "react-router-dom";

const ChangeRequestConfirmation = (props: any) => {
    const credentialrequestData = JSON.parse(localStorage.getItem("credentialrequestid")!);
    const navigate = useNavigate();
    console.log(credentialrequestData);
    const resolvedData = {
        id: props.data?.id,
        comment: props.data?.comment,
        resolved: true
    }

    console.log(props);
    function closeModal() {
        props.stateManager()
        //resetForm()
    }

    const btnOnClickResolve = async () => {
       
        console.log(props.data);
        try {
            const result = await credentialService.PutResolvedChangeRequest(props.data);
            console.log(result);
            if(result){
                toast.success("Change request resolved successfully", { position: "top-center" });
                localStorage.setItem("credentialrequestid", JSON.stringify(credentialrequestData));
                navigate('/insuranceChangeRequest');
                props.getChangeRequestsData();
                closeModal();
            }            
        } catch (error) {
            console.log(error)
            toast.error("Failed to update change request", { position: "top-center" })
            throw error

        }
    }

    return (
        <div>
            <Transition appear show={props.isOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
                        </Transition.Child>

                        <span className="inline-block h-screen align-middle" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                    <h1 className="font-medium leading-tight text-2xl mt-0 mb-2 text-black-600">
                                        Are you sure you want to resolve the comment?
                                    </h1>
                                </Dialog.Title>

                                <div className="mt-4 flex justify-end">
                                    <Button
                                        type="button"
                                        //color="gray"
                                        onClick={closeModal}
                                        className="mr-2"
                                    >
                                        No
                                    </Button>
                                    <Button
                                        type="button"
                                        color="red"
                                        onClick={() => btnOnClickResolve()}
                                    >
                                        Yes
                                    </Button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
            <ToastContainer />
        </div>
    )
}
export default ChangeRequestConfirmation