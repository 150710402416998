import { Dialog, Transition } from '@headlessui/react';
import { Button, Input, Select, Option } from '@material-tailwind/react';
import React, { useEffect, useState, Fragment, } from 'react';
import { AtSymbolIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { H3, P, Link } from "../../../components/typography/typography";
import { useNavigate } from "react-router-dom";
import statesService from '../../../Services/states.service';
import insuranceServices from '../../../Services/insurance.services';
import credentialService from '../../../Services/credential.services';
import Multiselect from 'multiselect-react-dropdown';
import { any } from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const formValues = {
  stateID: '',
}

const addInsuranceRequestSchema = Yup.object({
  stateID: Yup.string().required('Please select a state'),
  insuranceId: Yup.array().required('Please select a insurance')
})

const CreateInsuranceRequestInitial = (props: any) => {

  const [state, newState] = useState([{
    id: '',
    name: '',
    abbreviation: ''
  }]);
  const [selectedState, setSelectedState] = useState({
    id: '',
    name: '',
    abbreviation: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [insuranceRequest, setInsuranceRequest] = useState();
  const [selectedInsurance, setSelectedInsurance] = useState();
  const navigate = useNavigate();



  useEffect(() => {
    const getStateList = async () => {
      try {
        const result = await statesService.GetStates()
        newState(result)
      } catch (error) {
        console.log(error)
        throw error
      }
    }
    getStateList()
  }, [])


  const { values, errors, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: formValues,
    // validationSchema: addInsuranceRequestSchema,
    onSubmit: (values) => {
      createInsuranceRequest(values)
      resetForm()
      console.log("hello")
    },
  })
  function closeModal() {
    props.stateManager();
  }
  const stateChangeHandle = (stateId: any) => {
    var objSelectedStt = state.filter((stt) => stt.id === stateId)
    objSelectedStt.map((stt) => {
      setSelectedState(stt)
    })

    console.log(objSelectedStt)
    //setFieldValue('stateID', ''+stateId)
  }

  const insuranceSelectHandle = (e: any) => {
    setSelectedInsurance(e);
  }

  const insuranceRemoveHandle = (e: any) => {
    setSelectedInsurance(e);
  }

  const createInsuranceRequest = async (values: any) => {

    try {
      const resultState = await insuranceServices.PostInsuranceRequestState(selectedState)
      //setInsuranceRequest(resultState)
      localStorage.setItem('insuranceRequesteState', JSON.stringify(resultState))
      let url = await credentialService.GetNextPage();
      navigate("/"+url, { replace: true });
    } catch (error) {
      console.log(error)
      throw error
    }
  }



  const { heading, description, buttonName } = props
  return (
    <div>

          <div className="inset-0 p-8">
            <div className="flex min-h-full p-4 justify-center p-4 text-center">
                <form className="mt-6" onSubmit={handleSubmit}>
                      <h1 className="font-medium leading-tight text-2xl mt-0 mb-2 text-black-600">{heading}</h1>
                      <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 leading-relaxed">
                          {description}
                        </p>
                        <div className="mb-4">
                          <div className="dropdown">
                            <Select
                              label="State"
                              value={values.stateID}
                              onChange={stateChangeHandle}
                            >
                              {state.map((state) => (
                                <Option value={state.id}>{state.name}</Option>
                              ))}
                            </Select>
                            {errors?.stateID && <P className="text-red-600">{errors.stateID}</P>}
                          </div>
                        </div>

                      </div>
                      {/*footer*/}

                    <div className="flex justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <div className="modal-footer">
                        <Button
                          className="flex items-center"
                          type='submit'

                        >
                          {buttonName}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            />
                          </svg>
                        </Button>

                      </div>
                    </div>
                </form>
            </div>
          </div>
    </div>
  )
}
export default CreateInsuranceRequestInitial