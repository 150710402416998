import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Loader } from "../../../components/common/loader";
import {
  useGetCompaniesOfStateQuery,
  useGetPlansForInsurancePaymentLazyQuery,
} from "../../../datasources/graphql/client-types";
import InsuranceForm from "./insuranceForm";
import InsuranceNotFound from "./InsuranceNotFound";

export default function InsurancesSelection({
  setPlan,
  setInsurances
}: {
  setPlan: Function;
  setInsurances: Function;
}) {
  const { stateId } = useParams();
  const {
    data: companyData,
    loading,
    error,
  } = useGetCompaniesOfStateQuery({
    variables: {
      stateId: stateId || "",
    },
  });
  const [initialValues, setInitialValues] = useState<{}>();
  const [getPlansForInsurancePayment] =
    useGetPlansForInsurancePaymentLazyQuery();

  useEffect(() => {
    if (companyData) {
      const companies = companyData.getCompaniesOfState;
      let tempInitialValues = {};

      companies?.forEach((company) => {
        tempInitialValues = {
          ...tempInitialValues,
          [company.id]: false,
        };
      });

      setInitialValues(tempInitialValues);
    }

    return () => setInitialValues({})
  }, [companyData]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.log(error);
  }

  async function _handleSubmit(values: any, actions: any) {
    try {
      const insurancesArray = [];

      for (const key in values) {
        if (Object.prototype.hasOwnProperty.call(values, key)) {
          const element = values[key];
          if (element) {
            insurancesArray.push(key);
          }
        }
      }

      const resp = await getPlansForInsurancePayment({
        variables: {
          insurances: insurancesArray,
        },
      });

      const plan = resp.data?.getPlansForInsurancePayment;

      setPlan(plan);
      setInsurances(insurancesArray);

      await Swal.fire(
        "Insurances options Submitted",
        "Your Insurance Options have been submited",
        "success"
      );
    } catch (error) {
      await Swal.fire("Insurance Error", `Error: ${error}`, "error");

      throw error;
    }
    actions.setSubmitting(false);
  }

  return (
    <main className="w-full bg-background flex justify-center items-center py-5 min-h-ScreenWithNavbar">
      <div className="w-full bg-white max-w-5xl rounded-r-3xl py-5 px-8">
        <div className="w-full my-2 flex justify-center items-center">
          <h2 className="block text-textColor-bold max-w-prose w-3/4 font-bold text-4xl text-center">
            Select your insurance companies
          </h2>
        </div>
        {companyData?.getCompaniesOfState?.length! <= 0 ? (
          <InsuranceNotFound />
        ) : initialValues ? (
          <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
            {({ isSubmitting }) => (
              <Form className="mt-6 flex flex-col items-end">
                <InsuranceForm
                  insuranceOptions={
                    companyData?.getCompaniesOfState.map((company) => ({
                      label: company.name,
                      value: company.id,
                    })) || []
                  }
                />

                <div className="flex justify-end w-full">
                  <button
                    className="w-full bg-blue-700 rounded-lg py-3 text-white my-4 max-w-sm"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Continue
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Loader />
        )}
      </div>
    </main>
  );
}
