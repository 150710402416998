import { Button, Card, CardBody, Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import moment from "moment";
import { useEffect, useState } from "react"
import insuranceServices from '../../../Services/insurance.services';
import SelectState from "./SelectState";
import pic from "../../images/dentalImg.png";


const InitialLandingPage = (props:any) => {
    const [isOpen, setIsOpen] = useState(false);
    const stateManager = () => {
        setIsOpen(false)
      }
      return (
    <div>
            <SelectState
                isOpen={true}
                stateManager={stateManager}
                heading={"Welcome to Credental!"}
                description={"Please select the state you would like to credential in."}
                buttonName={"Start Credentialing"}
                requireName={true}
            />
    </div>
      )
}

export default InitialLandingPage