import { PlusCircleIcon, TrashIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateQuestionInput,
  useCreateQuestionnaireMutation,
} from "../../../datasources/graphql/client-types";
import { createInSwal } from "../../../helpers/swalHelper";
import { AddQuestion } from "./addQuestion";

export default function AddQuestionnaire() {
  const { stateId } = useParams();
  const [questions, setQuestions] = useState<CreateQuestionInput[]>([]);
  const [createQuestion, setCreateQuestion] = useState<boolean>();
  const [name, setName] = useState<string>("");
  const [createQuestionnaire] = useCreateQuestionnaireMutation();
  const navigate = useNavigate();

  function handleAddQuestion(question: CreateQuestionInput) {
    setQuestions([...questions, question]);
  }

  function handleFinishQuestionCreation() {
    setCreateQuestion(false);
  }

  function deleteQuestion(questionIndex: number) {
    setQuestions((prev) =>
      prev.filter((question, index) => index !== questionIndex)
    );
  }

  async function handleCreateQuestionnaire() {
    try {
      const createQuestionnaireInput = {
        questions: questions,
        name: name,
        state: stateId || "",
        insurance: "insurance",
      };

      console.log(createQuestionnaireInput);

      await createQuestionnaire({
        variables: {
          createQuestionnaireInput: createQuestionnaireInput,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async function handleSubmit() {
    try {
      await createInSwal("Questionnaire", handleCreateQuestionnaire);
      navigate("/questionnairesAdmin");
    } catch (error) {
      console.log(error);
    }
  }

  function handleNameChange(e: any) {
    setName(e.target.value);
  }

  return (
    <div className="w-full bg-background py-5 px-8 min-h-ScreenWithNavbar flex justify-center items-center">
      <div className="px-4 py-4 bg-onBackground w-full rounded-2xl flex flex-col max-w-screen-sm">
        <p className="mb-4">Add Questionnaire</p>
        <input
          value={name}
          onChange={handleNameChange}
          placeholder="Questionnarie Name"
        />

        <div className="flex flex-col items-stretch w-full">
          {!createQuestion ? (
            <button
              className="flex rounded-xl px-5 py-4 bg-onBackground my-6"
              onClick={() => setCreateQuestion(true)}
            >
              Add question{" "}
              <PlusCircleIcon className="ml-4" color="blue" width={24} />
            </button>
          ) : (
            <AddQuestion
              handleAddQuestion={handleAddQuestion}
              finishAdd={handleFinishQuestionCreation}
            />
          )}

          {questions.map((question, index) => (
            <div
              className="flex w-full bg-background justify-between"
              key={index}
            >
              <p>{question.name}</p> 
              <p>{question.type}</p>
              <p>{question.position}</p>

              <TrashIcon
                width={24}
                color="red"
                onClick={() => deleteQuestion(index)}
              />
            </div>
          ))}
        </div>

        <button onClick={handleSubmit}>Create Questionnaire</button>
      </div>
    </div>
  );
}
