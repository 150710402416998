import { Button } from '@material-tailwind/react';
import React from "react";

export default function PlanCard(props: any) {
  const { price, unitPrice, insuranceCompanies, canPay } = props;

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="container mx-auto px-1 col-span-1 shadow-2xl transform transition duration-500 hover:scale-110" onMouseOver={() => props.choosePricingOption(props.isAnnual)}>
          <div style={{ backgroundColor: 'white' }}>

            {/* Header */}
            <div className="p-5 bg-opacity-75">
              <p className="text-4xl font-medium text-black my-7 text-center">Get Started!</p>
              <h1 className="text-6xl font-medium text-black my-7 text-center">
                ${price}
              </h1>

              {/* Tabular Display for Insurance Details */}
              <div className="mt-6 overflow-x-auto">
                <table className="min-w-full bg-white table-auto rounded-lg shadow-md">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="py-2 px-4 border-t border-gray-300 text-left font-semibold">Insurance Company</th>
                      <th className="py-2 px-4 border-t border-gray-300 text-left font-semibold">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {insuranceCompanies.map((company: any, index: any) => (
                      <tr key={index} className="border-t">
                        <td className="py-2 px-4 text-gray-700">{company}</td>
                        <td className="py-2 px-4 text-gray-900">${unitPrice}</td>
                      </tr>
                    ))}
                    <tr className="font-bold bg-gray-50">
                      <td className="py-2 px-4 text-right font-semibold">Total</td>
                      <td className="py-2 px-4">${price}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ul className="w-full text-lg text-black-200 text-opacity-70">
                        <li className="flex items-center py-2">
                          <div className="flex items-center justify-center w-6 h-6 mr-3 bg-green-800 rounded-full">
                            <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g opacity="1.0">
                                <path
                                  d="M10.4923 1.14517C10.6873 0.951849 10.9506 0.843047 11.2252 0.842289C11.4998 0.841531 11.7637 0.948879 11.9598 1.14112C12.1558 1.33337 12.2684 1.59506 12.2731 1.86963C12.2778 2.1442 12.1742 2.40958 11.9848 2.60839L6.42174 9.56218C6.32613 9.66516 6.21073 9.7478 6.08245 9.80517C5.95417 9.86253 5.81564 9.89344 5.67515 9.89604C5.53465 9.89864 5.39507 9.87288 5.26475 9.82031C5.13444 9.76773 5.01606 9.68942 4.91671 9.59005L1.23078 5.90273C1.1281 5.80705 1.04574 5.69166 0.988612 5.56346C0.931488 5.43525 0.900771 5.29686 0.898295 5.15652C0.895819 5.01619 0.921634 4.87679 0.9742 4.74665C1.02677 4.61651 1.10501 4.49829 1.20425 4.39905C1.3035 4.2998 1.42172 4.22156 1.55186 4.16899C1.682 4.11643 1.82139 4.09061 1.96173 4.09309C2.10206 4.09556 2.24046 4.12628 2.36867 4.1834C2.49687 4.24053 2.61226 4.32289 2.70794 4.42558L5.62602 7.34226L10.4658 1.17583C10.4744 1.16504 10.4837 1.1548 10.4937 1.14517H10.4923Z"
                                  fill="#FFFFFF"
                                ></path>
                              </g>
                            </svg>
                          </div>
                          24/7 Customer support
                        </li>
                        <li className="flex items-center py-2">
                          <div className="flex items-center justify-center w-6 h-6 mr-3 bg-green-800 rounded-full">
                            <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              { <g opacity="1.0">
                                <path
                                  d="M10.4923 1.14517C10.6873 0.951849 10.9506 0.843047 11.2252 0.842289C11.4998 0.841531 11.7637 0.948879 11.9598 1.14112C12.1558 1.33337 12.2684 1.59506 12.2731 1.86963C12.2778 2.1442 12.1742 2.40958 11.9848 2.60839L6.42174 9.56218C6.32613 9.66516 6.21073 9.7478 6.08245 9.80517C5.95417 9.86253 5.81564 9.89344 5.67515 9.89604C5.53465 9.89864 5.39507 9.87288 5.26475 9.82031C5.13444 9.76773 5.01606 9.68942 4.91671 9.59005L1.23078 5.90273C1.1281 5.80705 1.04574 5.69166 0.988612 5.56346C0.931488 5.43525 0.900771 5.29686 0.898295 5.15652C0.895819 5.01619 0.921634 4.87679 0.9742 4.74665C1.02677 4.61651 1.10501 4.49829 1.20425 4.39905C1.3035 4.2998 1.42172 4.22156 1.55186 4.16899C1.682 4.11643 1.82139 4.09061 1.96173 4.09309C2.10206 4.09556 2.24046 4.12628 2.36867 4.1834C2.49687 4.24053 2.61226 4.32289 2.70794 4.42558L5.62602 7.34226L10.4658 1.17583C10.4744 1.16504 10.4837 1.1548 10.4937 1.14517H10.4923Z"
                                  fill="#FFFFFF"
                                ></path>
                              </g> }
                            </svg>
                          </div>
                          Legal documents included
                        </li>
                      </ul>

                      <p className="text-black-200 text-opacity-60">
                        Get the essentials to start with the magic of automated credentialing
                      </p>  
              {/* Button to Pay */}
              <div className="mt-5 relative group">
              <Button
                className={`w-full py-3 rounded-lg ${
                  canPay
                    ? "bg-indigo-500 hover:bg-indigo-600 text-white ipayfield"
                    : "bg-blue-grey-50 text-gray-500 cursor-not-allowed"
                }`}
                disabled={!canPay}
                data-ipayname="submit"
              >
                Pay Now!
              </Button>

                {/* Tooltip on hover when canPay is false */}
                {!canPay && (
                  <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 p-2 bg-black text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity">
                    Please ensure that the questionnaire has been fully filled out.
                  </div>
                )}
              </div>

              <p className="mt-3 text-center text-indigo-600 text-opacity-70">
              You'll be charged a one time fee of ${price}
              </p>
            </div>

          </div>
        </div>
    </div>
  
  );
}