import TokenService from "./token.service";
import axios from "axios";

class FileService {
 
API_URL = process.env.REACT_APP_API_URL;  

getFile = async(vaultId: number) => {
  axios
      .get(this.API_URL+"/Credential/download/"+vaultId, {
        responseType:"arraybuffer",
        headers: {
          "Content-Type": "application/json",
          "Authorization":"Bearer "+TokenService.getLocalAccessToken()
        },
      })
      .then((response) => {
        // handle the response
        const element = document.createElement("a");
  
        let blob = new Blob([response.data], {type: response.headers["content-type"]});
        element.href = URL.createObjectURL(blob);
        element.target="_new";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click(); 
            })
      .catch((error) => {
        // handle errors
        console.log(error);
      });
}

}

export default new FileService()