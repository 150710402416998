import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button } from '@material-tailwind/react'
import insuranceServices from '../../../Services/insurance.services'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const DeleteInsuranceCompany = (props: any) => {
  var InsuranceCompanyId = ''
  if (props.isOpen) {
    InsuranceCompanyId = props.dataDelete.id
  }

  function closeModal() {
    props.stateManager()
    //resetForm()
  }

  const DeleteSelectedInsuranceCompany = async (InsuranceCompanyId: any) => {
    try {
      var response = await insuranceServices.DeleteInsuranceCompeny(InsuranceCompanyId)
      if (response) {
        props.GetInsuranceCompanies()
        closeModal()
      }
      toast.success('Insurance company deleted successfully', { position: 'top-center' })
    } catch (error) {
      toast.error('Something went wrong', { position: 'top-center' })
      throw error
    }
  }

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  <h1 className="font-medium leading-tight text-2xl mt-0 mb-2 text-black-600">
                    Do you want to delete the insurance company?
                  </h1>
                </Dialog.Title>

                <div className="mt-4 flex justify-end">
                  <Button
                    type="button"
                    //color="gray"
                    onClick={closeModal}
                    className="mr-2"
                  >
                    Cancel
                  </Button>
                  <Button type="button" color="red" onClick={() => DeleteSelectedInsuranceCompany(InsuranceCompanyId)}>
                    Delete
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <ToastContainer />
    </div>
  )
}

export default DeleteInsuranceCompany
