import React, { useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';


export default function Table(props: any) {

  const {rowData, columns} = props;
  const [gridApi, setGridApi] = useState<any>([])

  const fetchData = (params: any) =>{
    setGridApi(params.api)
    const {rowData} = props;
    console.log("in table",rowData);
    rowData.forEach((element: any)   => {
      params.api.applyTransaction({add: element})
    });
   }
   const searchFunction = (e:any) =>{
    gridApi.setQuickFilter(e.target.value);
  }
  const defaultCol = {
    sortable: true,
    editable: false,
    filter: true,
    flex: 1,
    resizable:true,
    floatingfilter: true,
    pagination: true,
  }
 
 
  return (
    <>
    <input type="search" className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" onChange={searchFunction}/>
    <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
      <AgGridReact 
      rowData={rowData} 
      onGridReady={fetchData}
      defaultColDef={defaultCol}
      columnDefs={columns}
      pagination={true}      
      onCellClicked={(e) => props.getOnclickCellData(e)}
      paginationPageSize={10}>
      </AgGridReact>
    </div>
    </>
  )
}

