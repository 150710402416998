import { ErrorMessage, FastField, FieldAttributes } from "formik";
import CustomErrorMessage from "./customFIeldErrorMessage";

export const StyledBoolQuestion = (props: FieldAttributes<any>) => {
  const { label, isRequired, ...restOfProps } = props;

  return (
    <div className="w-full my-2 flex flex-row flex-nowrap justify-around items-center">
      <div className="w-3/4">
        <span className="block text-gray-700 max-w-prose">{props.label} {isRequired && <span className="text-red-500">*Required</span>}</span>
      </div>
      <div className="w-1/4 flex justify-between">
        <div className="flex items-center w-1/2 justify-center px-4 py-3 ">
          <label className="flex items-center cursor-pointer">
            <FastField
              {...restOfProps}
              type="radio"
              value="yes"
              className="form-check-input appearance-none h-8 w-8 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
            />
          </label>
        </div>
        <div className="flex items-center w-1/2 justify-center px-4 py-3 ">
          <label className="flex items-center cursor-pointer">
            <FastField
              {...restOfProps}
              type="radio"
              value="no"
              className="form-check-input appearance-none h-8 w-8 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
            />
          </label>
        </div>
      </div>
      <ErrorMessage name={restOfProps.name}>
        {(msg) => <CustomErrorMessage message={msg} />}
      </ErrorMessage>
    </div>
  );
};
