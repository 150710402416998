import ContentHeader from "../../../components/contentHeader/contentHeader"


const RequestsAdmin = () => {
  return (
    <div>
      <ContentHeader />
    </div>
  )
}

export default RequestsAdmin