import { Link } from "react-router-dom";

export default function InsuranceNotFound() {
  return (
    <div className="w-full flex flex-col justify-center items-center h-40">
      <div>Oooops.... Insurance companies not found for this state</div>
      <Link to="/state" className="text-primary hover:underline cursor-pointer">Please contact support or try with another state</Link>
    </div>
  );
}
