import apiClient from './api'
class CredentialService {
  async GetSelectedState() {
    try {
      const response = await apiClient.get(`/Credential/State`)
      return { success: true, data: response.data };
    } catch (err) {
      return { success: false }
      console.log(err)
    }
  }
  async GetPricing() {
    try {
      const response = await apiClient.get(`/Credential/Pricing`)
      return { success: true, data: response.data };
    } catch (err) {
      return { success: false }
      console.log(err)
    }
  }
  async GetNextPage() {
    try {
      const response = await apiClient.get(`/Credential/next-page`);
      console.log(response.data.data);
      switch (response.data.data) {
        case "SelectState":
          return "credentialing";
        case "MailingList":
          return "mailinglist";
        case "CompleteQuestionnaire":
          return "createInsurance";
        case "SelectInsuranceCompanies":
          return "selectinsurance";
        case "CollectPayment":
          return "plans";
        case "ViewDashboard":
          return "dashboard";

      }
      return { success: true, data: response.data.data };
    } catch (err) {
      return { success: false }
      console.log(err)
    }
  }

  async GetChangeRequestsData(id: any) {
    try {
      const response = await apiClient.get(`/Admin/credentialrequest/${id}/change-requests`);
      return response.data;

    } catch (err) {
      console.log(err);
    }
  }

  async PutResolvedChangeRequest(postData: any) {
    try {
      const response = await apiClient.put('/Admin/change-request', postData)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  async GetInsuranceRequests(credentialrequestid: any) {
    try {
      const response = await apiClient.get(`/Admin/credentialrequest/${credentialrequestid}/insurance-requests`);
      return response.data;

    } catch (err) {
      console.log(err);
    }
  }
  async UpdateInsuranceStatus(credentialRequestid: any,insuranceRequestId:any,payload:any) {
    try {
      const response = await apiClient.put(`/admin/credentialrequest/${credentialRequestid}/insurance-request/${insuranceRequestId}/status`,payload);
      return response.data;

    } catch (err) {
      console.log(err);
    }
  }
  async UpdateCredentialStatus(credentialRequestId: any,payload:any) {
    try {
      const response = await apiClient.post(`/Credential/${credentialRequestId}/Status`, payload);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
      
      async DeleteFile(questionId:number)
      {
        try {
          const response = await apiClient.delete(`/Credential/upload/${questionId}`)        
          return { success: true, data: response.data };
        } catch (err) {
          console.log(err)
          return {success: false}
        }
      }
      async CompleteQuestionnaire()
      {
        try {
          const response = await apiClient.post(`/Credential/complete-questionnaire`)        
          return { success: true, data: response.data };
        } catch (err) {
          console.log(err)
          return {success: false}
        }
      }
}


export default new CredentialService()