import React, { useEffect, useState, useRef } from "react";
import PaymentService from '../../Services/payment.services';
import axios from 'axios';
import IntelliPayForm from "../user/insurances/payment/intelliPayForm";
import credentialService from "../../Services/credential.services";

import { useNavigate } from "react-router-dom";
import { sidebarStore } from "../store/SidebarStore";


export default function PaymentPage(props:any)  {
  let localPaymentAmount = 0;
    useEffect(()=>{
        GetIntellipayWidget();
    },[]); 
    const navigate = useNavigate();
    const postPaymentSuccess = async (response:any) => {


        let paymentAmount = response.amount;

        let postPaymentPayload = {
          isAnnualPayment: true,
          totalAnnualPrice: paymentAmount,
          pricePerMonth: paymentAmount,
          shouldCreateFirstPayment: true,
          isFirstPaymentPaid: true
        }
        try {
          const result = await PaymentService.PostPayment(postPaymentPayload);
          console.log(result);
          sidebarStore.setCanCreateNewRequest(true);
          let paymentId = result.paymentId;
          const sheduleResult = await PaymentService.ScheduleNextPayment(paymentId);
          let url = await credentialService.GetNextPage();
          navigate("/"+url, { replace: true });
    
        } catch (error) {
          console.log(error)
          throw error
        }
      }

    const GetIntellipayWidget = async()=>{
        var response= await PaymentService.GetIntellipayWidget()
        if(response.success){
            console.log(response);
            var rg=document.createRange();
            document.documentElement.appendChild(rg.createContextualFragment(response.data));
            // @ts-ignore
            intellipay.initialize();
                // @ts-ignore
            intellipay.runOnApproval(function(response){
                postPaymentSuccess(response);
                });

                // @ts-ignore
            intellipay.runOnNonApproval(function(response){
                console.log("transactions was not approved! --> " +
                JSON.stringify(response));
                });
        }   
    }
 return (
 <div>
 <input className="ipayfield" data-ipayname="amount" type="hidden" value={props.paymentAmount} />
 <input className="ipayfield" data-ipayname="account" type="hidden" value={props.userId} />
 <input className="ipayfield" data-ipayname="email" type="hidden" value={props.userEmail} />
 </div>
 );
}



