import { Form, Formik, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { Loader } from '../../../components/common/loader'
import QuestionnaireContainer from '../../../components/questionnary/questionnaireContainer'
import {
  GetPartialSubmittedQuestionnairesOfUserDocument,
  QuestionType,
  useGetQuestionnaireByStateQuery,
  useSubmitQuestionnaireMutation,
} from '../../../datasources/graphql/client-types'
import useAuth from '../../../hooks/useAuth'
import { QuestionParams } from '../../../models/questionnaire'
import DynamicForm from './dynamicForm'
import GenericForm from './genericForm'
import valuesToQuestionnaireValues from './helper/valuesToQuestionnaireValues'
import { Button } from '@material-tailwind/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const getYupFields = (question: QuestionParams) => {
  const _yup = yup
  if (question.isRequired) {
    if (question.type === QuestionType.String) return _yup.string().required('This field is required')
    if (question.type === QuestionType.Date) return _yup.date().required('This field is required')
  } else {
    if (question.type === QuestionType.String) return _yup.string()
    if (question.type === QuestionType.Date) return _yup.date()
  }
  return _yup.string()
}

const generateYupObject = (questions: QuestionParams[]) => {
  const yupObject: any = {}
  questions.map((question: QuestionParams) => (yupObject[question.id] = getYupFields(question)))
  return yupObject
}

const questionsData = [
  {
    __typename: 'Question',
    id: '62708b318eddac56619efff8',
    qId: '62708b318eddac56619efff6',
    type: 'string',
    name: 'What is your specialty?',
    extras: 'extras',
    isRequired: true,
    position: 1,
    rowType: 'invalid',
    requireStorage: 'false',
    dropDownAnswers: [],
  },
  {
    __typename: 'Question',
    id: '62708b318eddac56619efff9',
    qId: '62708b318eddac56619efff6',
    type: 'string',
    name: 'What is your address?',
    extras: 'extras',
    isRequired: true,
    position: 2,
    rowType: 'invalid',
    requireStorage: 'false',
    dropDownAnswers: [],
  },
]

const generateInitialValues = (questions: QuestionParams[]) => {
  const initialValues: any = {}
  questions.map((question: QuestionParams) => (initialValues[question.id] = ''))
  return initialValues
}

export default function MainForm() {
  const { stateId } = useParams()
  const navigate = useNavigate()
  const { refreshUser } = useAuth()

  const {
    data: QuestionnaireData,
    loading,
    error,
  } = useGetQuestionnaireByStateQuery({
    variables: {
      stateId: stateId || '',
    },
  })
  const [submitQuestionnaire] = useSubmitQuestionnaireMutation({
    refetchQueries: [GetPartialSubmittedQuestionnairesOfUserDocument, 'getPartialSubmittedQuestionnairesOfUser'],
  })
  const [initialValues, setInitialValues] = useState<{}>()
  const [questionnaireProgress, setQuestionnaireProgress] = useState<number>(0)

  let questions

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<any>({
    resolver: yupResolver(yup.object().shape(generateYupObject(questionsData as QuestionParams[]))),
    mode: 'onSubmit',
  })

  const watchFields = watch()

  useEffect(() => {
    if (QuestionnaireData) {
      questions = QuestionnaireData.getQuestionnairesByState.questions
      let tempInitialValues = {}

      setInitialValues(tempInitialValues)
      setQuestionnaireProgress(50)
    }
  }, [QuestionnaireData])

  if (loading) {
    return <div>Loading</div>
  }

  if (error) {
    console.log(error)
  }

  console.log('errors', errors)

  async function onSubmit() {
    console.log('watchFields', watchFields)
    console.log('errors', errors)

    // try {
    //   const questionnaireResponse = valuesToQuestionnaireValues(values, QuestionnaireData?.getQuestionnairesByState!)

    //   await submitQuestionnaire({
    //     variables: {
    //       submitQuesstionnaire: questionnaireResponse,
    //     },
    //   })

    //   await Swal.fire('Questionnaire Submitted', 'Your Questionnaire has been submited', 'success')

    //   await refreshUser()

    //   navigate(`/insurances/${stateId}`)
    // } catch (error) {
    //   console.log(error)
    //   Swal.fire('Questionnaire Error', `Error: ${error}`, 'error')
    //   throw error
    // }
    // actions.setSubmitting(false)
  }

  return (
    <QuestionnaireContainer progress={questionnaireProgress}>
      {initialValues ? (
        <form className="mt-6 flex flex-col items-end" onSubmit={handleSubmit(onSubmit)}>
          <DynamicForm questions={QuestionnaireData?.getQuestionnairesByState.questions || []} register={register} errors={errors} />

          <div className="flex justify-end w-full">
            <Button
              className="w-full bg-blue-700 rounded-lg py-3 text-white my-4 max-w-sm"
              type="submit"
            >
              Submit Form
            </Button>
          </div>
        </form>
      ) : (
        <Loader />
      )}
    </QuestionnaireContainer>
  )
}
