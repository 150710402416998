import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Plan,
  useGetPartialSubmittedQuestionnairesOfUserLazyQuery,
  useMakeInsurancesPaymentMutation,
} from "../../../../datasources/graphql/client-types";
import useAuth from "../../../../hooks/useAuth";
import InsuranceNotFound from "../InsuranceNotFound";
import IntelliPayForm from "./intelliPayForm";
import PaymentPlanCard from "./paymentPlanCard";

export default function InsurancesPayment({
  insurancesPlan,
  insurances,
}: {
  insurancesPlan: Plan;
  insurances: string[];
}) {
  const [makePayment] = useMakeInsurancesPaymentMutation();
  const navigate = useNavigate();
  const [questionnairesSubmitted] =
    useGetPartialSubmittedQuestionnairesOfUserLazyQuery();
  const { user, refreshUser } = useAuth();
  const [frequency, setFrequency] = useState("");

  async function handleSubmit() {
    try {
      const questionnairesSubmited = await questionnairesSubmitted({
        variables: {
          userId: user.id,
        },
      });

      const questionnaireSubmittedId =
        questionnairesSubmited.data?.getSubmittedQuestionnairesOfUser[0].id ||
        "";
      console.log(
        insurances,
        frequency === "anually",
        questionnaireSubmittedId
      );

      const resp = await makePayment({
        variables: {
          createPayment: {
            insurances,
            annual: frequency === "anually",
            questionnaireSubmittedId,
          },
        },
      });

      await Swal.fire(
        "Payment Submitted",
        `Your Payment have been submited: ${resp.data?.createPayment.status}`,
        "success"
      );
      await refreshUser();

      navigate("/credentialing");
    } catch (error) {
      await Swal.fire("Payment Error", `Error: ${error}`, "error");

      throw error;
    }
  }

  function valueToPrice(paymentFrequency: string) {
    return paymentFrequency === "annually"
      ? insurancesPlan.annualPrice
      : insurancesPlan.monthPrice;
  }

  return (
    <main className="w-full bg-background flex justify-center items-center min-h-ScreenWithNavbar">
      <div className="w-full max-w-5xl px-8">
        <div className="w-full my-2 flex justify-center items-center mb-8">
          <h2 className="block text-textColor-bold max-w-prose w-3/4 font-bold text-4xl text-center">
            Make your payment for {insurancesPlan.info}
          </h2>
        </div>
        {!insurancesPlan ? (
          <InsuranceNotFound />
        ) : (
          <div className="flex ">
            <PaymentPlanCard plan={insurancesPlan} setValue={setFrequency} />
            <IntelliPayForm
              value={`${valueToPrice(frequency)}`}
              handleSubmit={handleSubmit}
            />
          </div>
        )}
      </div>
    </main>
  );
}
