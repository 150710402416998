import { IdentificationIcon, CreditCardIcon, CogIcon, LogoutIcon, DocumentTextIcon, InboxInIcon, UsersIcon, ChartPieIcon,OfficeBuildingIcon,QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { AuthLink } from "./interfaces";

export const userNavigation: AuthLink[] = [
 
  { name: "Dashboard", href: "/dashboard", icon: <IdentificationIcon />, current: true },
  {
    name: "New Credentialing Request",
      href: "/credentialing",
      icon: <DocumentTextIcon />,
      current: false,
     
    },
    {
    name: "Change Insurance Companies",
    href: "/selectinsurance",
    icon: <DocumentTextIcon />,
    current: false,
    },
    {
      name: "Questionnaire",
      href: "/createInsurance",
      icon: <QuestionMarkCircleIcon />,
      current: false,
      },
  { name: "Payment", href: "/plans", icon: <CreditCardIcon />, current: false },
  { name: "Log out", href: "#", icon: <LogoutIcon />, current: false },
];

export const adminNavigation: AuthLink[] = [
  { name: "Questionnaires", href: "/questionnairesAdmin", icon: <QuestionMarkCircleIcon />, current: true },
  // { name: "Credential Requests", href: "/insuranceRequestsDentist", icon: <InboxInIcon />, current: false },
  { name: "Credential Requests", href: "/insuranceRequests", icon: <DocumentTextIcon />, current: false },
  { name: "Insurance Companies", href: "/insuranceCompany", icon: <OfficeBuildingIcon />, current: false },
  { name: "Log out", href: "#", icon: <LogoutIcon />, current: false },

 // { name: " Dentist Insurance Request", href: "/insuranceRequestsDentist", icon: <DocumentTextIcon />, current: false },
  // { name: "Insurance Changed Request", href: "/insuranceChangeRequest", icon: <DocumentTextIcon />, current: false },
  // {
  //   name: "Users and access",
  //   href: "/usersAndAccess",
  //   icon: <ChartPieIcon />, current: false,
  // },
  // // { name: "Plans", href: "/pricing", icon: <LogoutIcon />, current: false },
  // { name: "Analitycs", href: "#", icon: <LogoutIcon />, current: false },
];

export const userMenu = [
  { name: "Your Profile", href: "/dataForm" },
  { name: "Settings", href: "/settings" },
];