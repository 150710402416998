import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AnswerSubmitted = {
  question: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export type AnswerUpdateInput = {
  answerId: Scalars['String'];
  newValue: Scalars['String'];
};

export type AuthToken = {
  __typename?: 'AuthToken';
  access_token: Scalars['String'];
};

export type ChangeRequest = {
  __typename?: 'ChangeRequest';
  id: Scalars['String'];
  idQuestion: Scalars['String'];
  qSubmittedId: Scalars['String'];
  reason: Scalars['String'];
  status: ChangeRequestStatus;
};

export type ChangeRequestInput = {
  answerId: Scalars['String'];
  qSubmittedId: Scalars['String'];
  reason: Scalars['String'];
};

export enum ChangeRequestStatus {
  Approved = 'approved',
  Pending = 'pending'
}

export type Company = {
  __typename?: 'Company';
  id: Scalars['String'];
  name: Scalars['String'];
  pdfMapping: Scalars['String'];
  states: Array<State>;
};

export type CreateCompanyInput = {
  name: Scalars['String'];
  pdfMapping: Scalars['String'];
  states: Array<Scalars['String']>;
};

export type CreateDentistInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  pass: Scalars['String'];
};

export type CreatePaymentInput = {
  annual: Scalars['Boolean'];
  insurances: Array<Scalars['String']>;
  questionnaireSubmittedId: Scalars['String'];
};

export type CreatePlanInput = {
  annualPrice: Scalars['Float'];
  info: Scalars['String'];
  maxInsurances: Scalars['Float'];
  minInsurances: Scalars['Float'];
  monthPrice: Scalars['Float'];
};

export type CreateQuestionInput = {
  dropDownAnswers?: InputMaybe<Array<Scalars['String']>>;
  extras: Scalars['String'];
  friendlyId: Scalars['String'];
  isRequired: Scalars['Boolean'];
  name: Scalars['String'];
  page: Scalars['Float'];
  position: Scalars['Float'];
  requireStorage: Scalars['String'];
  rowType: Scalars['String'];
  type: QuestionType;
};

export type CreateQuestionnaireInput = {
  insurance: Scalars['String'];
  name: Scalars['String'];
  questions: Array<CreateQuestionInput>;
  state: Scalars['String'];
};

export type CreateStateInput = {
  name: Scalars['String'];
  status: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  pass: Scalars['String'];
  role: Array<Roles>;
};

export type LogUserInput = {
  email: Scalars['String'];
  pass: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptChangeRequest: ChangeRequest;
  acceptQuestionnaireSubmitted: Scalars['String'];
  addDentist: User;
  addQuestionToQuestionnaire: Question;
  approvePDFGenerated: Scalars['String'];
  createCompany: Company;
  createPayment: Payment;
  createPlan: Plan;
  createQuestionnaire: Questionnaire;
  createState: State;
  createUser: User;
  deleteCompany: Scalars['Boolean'];
  deletePlan: Scalars['String'];
  deleteQuestion: Scalars['Boolean'];
  deleteQuestionnaire: Scalars['Boolean'];
  deleteState: Scalars['Boolean'];
  editAnswerOfQuestionnaire: QuestionnaireAnswer;
  editDentist: User;
  flagQuestionnaireSubmittedToPDF: Scalars['String'];
  makePayment: TrackingPayment;
  removeDentist: Scalars['Boolean'];
  requestChangeOnSubmittedQuestionnaire: ChangeRequest;
  signUp: User;
  submitQuesstionnaire: QuestionnaireSubmmited;
  updateCompany: Company;
  updatePlan: Plan;
  updateQuestion: Question;
  updateQuestionnaire: Questionnaire;
  updateState: State;
};


export type MutationAcceptChangeRequestArgs = {
  requestId: Scalars['String'];
};


export type MutationAcceptQuestionnaireSubmittedArgs = {
  qId: Scalars['String'];
};


export type MutationAddDentistArgs = {
  input: CreateDentistInput;
};


export type MutationAddQuestionToQuestionnaireArgs = {
  question: CreateQuestionInput;
  questionnaireId: Scalars['String'];
};


export type MutationApprovePdfGeneratedArgs = {
  qId: Scalars['String'];
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};


export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};


export type MutationCreateQuestionnaireArgs = {
  input: CreateQuestionnaireInput;
};


export type MutationCreateStateArgs = {
  input: CreateStateInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['String'];
};


export type MutationDeletePlanArgs = {
  id: Scalars['String'];
};


export type MutationDeleteQuestionArgs = {
  questionId: Scalars['String'];
};


export type MutationDeleteQuestionnaireArgs = {
  id: Scalars['String'];
};


export type MutationDeleteStateArgs = {
  id: Scalars['String'];
};


export type MutationEditAnswerOfQuestionnaireArgs = {
  input: AnswerUpdateInput;
};


export type MutationEditDentistArgs = {
  input: UpdateDentistInput;
};


export type MutationFlagQuestionnaireSubmittedToPdfArgs = {
  qId: Scalars['String'];
};


export type MutationMakePaymentArgs = {
  trakingPaymentId: Scalars['String'];
};


export type MutationRemoveDentistArgs = {
  input: Scalars['String'];
};


export type MutationRequestChangeOnSubmittedQuestionnaireArgs = {
  input: ChangeRequestInput;
};


export type MutationSignUpArgs = {
  input: CreateUserInput;
};


export type MutationSubmitQuesstionnaireArgs = {
  input: QuestionnaireSubmittion;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['String'];
  input: UpdateCompanyInput;
};


export type MutationUpdatePlanArgs = {
  id: Scalars['String'];
  input: CreatePlanInput;
};


export type MutationUpdateQuestionArgs = {
  input: CreateQuestionInput;
  questionId: Scalars['String'];
};


export type MutationUpdateQuestionnaireArgs = {
  id: Scalars['String'];
  input: CreateQuestionnaireInput;
};


export type MutationUpdateStateArgs = {
  id: Scalars['String'];
  input: UpdateStateInput;
};

export type Payment = {
  __typename?: 'Payment';
  annual: Scalars['Boolean'];
  id: Scalars['String'];
  insurances: Array<Company>;
  pricePerMonth: Scalars['Float'];
  questionnaireSubmitted: Scalars['String'];
  status: PaymentStatus;
  totalAnnualPrice: Scalars['Float'];
  userId: Scalars['String'];
};

export enum PaymentStatus {
  Accepted = 'accepted',
  Pending = 'pending'
}

export type Plan = {
  __typename?: 'Plan';
  annualPrice: Scalars['Float'];
  id: Scalars['String'];
  info: Scalars['String'];
  maxInsurances: Scalars['Float'];
  minInsurances: Scalars['Float'];
  monthPrice: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  getCompanies: Array<Company>;
  getCompaniesOfState: Array<Company>;
  getCompanyById: Company;
  getCurrentUser: User;
  getDentists: Array<User>;
  getFriendlyKeyParAnswersByID: Scalars['String'];
  getPaymentOfSubmittedQuestionnaire: Array<Payment>;
  getPaymentTerminal: Scalars['String'];
  getPaymentsForToday: Array<TrackingPayment>;
  getPlans: Array<Plan>;
  getPlansForInsurancePayment: Plan;
  getQuestionnaireById: Questionnaire;
  getQuestionnaires: Array<Questionnaire>;
  getQuestionnairesByState: Questionnaire;
  getQuestionsFromQuestionnaire: Array<Question>;
  getStates: Array<State>;
  getSubmittedQuestionnaireById: QuestionnaireSubmmited;
  getSubmittedQuestionnaires: Array<QuestionnaireSubmmited>;
  getSubmittedQuestionnairesByState: Array<QuestionnaireSubmmited>;
  getSubmittedQuestionnairesByStatus: Array<QuestionnaireSubmmited>;
  getSubmittedQuestionnairesOfUser: Array<QuestionnaireSubmmited>;
  getTrackingPaymentsForPayment: Array<TrackingPayment>;
  getUser: User;
  getUsers: Array<User>;
  login: AuthToken;
};


export type QueryGetCompaniesOfStateArgs = {
  stateId: Scalars['String'];
};


export type QueryGetCompanyByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetFriendlyKeyParAnswersByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetPaymentOfSubmittedQuestionnaireArgs = {
  questionnaireSubmittedId: Scalars['String'];
};


export type QueryGetPlansForInsurancePaymentArgs = {
  insurances: Array<Scalars['String']>;
};


export type QueryGetQuestionnaireByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetQuestionnairesByStateArgs = {
  stateId: Scalars['String'];
};


export type QueryGetQuestionsFromQuestionnaireArgs = {
  qId: Scalars['String'];
};


export type QueryGetSubmittedQuestionnaireByIdArgs = {
  questionnaireSubmittedId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetSubmittedQuestionnairesByStateArgs = {
  stateId: Scalars['String'];
};


export type QueryGetSubmittedQuestionnairesByStatusArgs = {
  status: Scalars['String'];
};


export type QueryGetSubmittedQuestionnairesOfUserArgs = {
  userId: Scalars['String'];
};


export type QueryGetTrackingPaymentsForPaymentArgs = {
  paymentId: Scalars['String'];
};


export type QueryGetUserArgs = {
  email: Scalars['String'];
};


export type QueryLoginArgs = {
  input: LogUserInput;
};

export type Question = {
  __typename?: 'Question';
  dropDownAnswers?: Maybe<Array<Scalars['String']>>;
  extras: Scalars['String'];
  friendlyId: Scalars['String'];
  id: Scalars['String'];
  isRequired: Scalars['Boolean'];
  name: Scalars['String'];
  page: Scalars['Float'];
  position: Scalars['Float'];
  qId: Scalars['String'];
  requireStorage: Scalars['String'];
  rowType: Scalars['String'];
  type: QuestionType;
};

export enum QuestionType {
  Date = 'date',
  DualBoolean = 'dualBoolean',
  Select = 'select',
  String = 'string',
  Textarea = 'textarea'
}

export type Questionnaire = {
  __typename?: 'Questionnaire';
  id?: Maybe<Scalars['String']>;
  insurance: Scalars['String'];
  name: Scalars['String'];
  questions: Array<Question>;
  state: Scalars['String'];
};

export type QuestionnaireAnswer = {
  __typename?: 'QuestionnaireAnswer';
  id: Scalars['String'];
  qSubmittedId: Scalars['String'];
  question: Question;
  type: Scalars['String'];
  value: Scalars['String'];
};

export enum QuestionnaireSubmittedStatus {
  Approved = 'approved',
  ApprovedPdf = 'approvedPDF',
  Credentialed = 'credentialed',
  CredentialedPartial = 'credentialedPartial',
  Pending = 'pending',
  PendingPayment = 'pendingPayment',
  ReadyForPdf = 'readyForPDF',
  Rejected = 'rejected',
  RequestChange = 'requestChange'
}

export type QuestionnaireSubmittion = {
  answers: Array<AnswerSubmitted>;
  qId: Scalars['String'];
};

export type QuestionnaireSubmmited = {
  __typename?: 'QuestionnaireSubmmited';
  answers: Array<QuestionnaireAnswer>;
  completed: Scalars['Boolean'];
  id: Scalars['String'];
  qId: Scalars['String'];
  requestChanges: Array<ChangeRequest>;
  state: State;
  status: QuestionnaireSubmittedStatus;
  timestamp: Scalars['Float'];
  user: User;
};

export enum Roles {
  Admin = 'admin',
  PracticeAdmin = 'practiceAdmin',
  User = 'user'
}

export type State = {
  __typename?: 'State';
  id: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  changeRequestCreated: Scalars['String'];
  dentistAdded: User;
  questionnaireAccepted: Scalars['String'];
  questionnaireCreated: Scalars['String'];
  questionnaireDeleted: Scalars['String'];
  questionnaireSubmitted: QuestionnaireSubmmited;
  questionnaireUpdated: Scalars['String'];
  stateCreated: Scalars['String'];
  stateDeleted: Scalars['String'];
  stateUpdated: Scalars['String'];
  userAuthenticated: Scalars['String'];
  userDeleted: Scalars['String'];
  userUpdated: Scalars['String'];
};

export type TrackingPayment = {
  __typename?: 'TrackingPayment';
  id: Scalars['String'];
  payment: Payment;
  status: TrackingPaymentStatus;
  timeForPay: Scalars['Float'];
  userId: Scalars['String'];
};

export enum TrackingPaymentStatus {
  Paid = 'paid',
  Pending = 'pending'
}

export type UpdateCompanyInput = {
  name?: InputMaybe<Scalars['String']>;
  pdfMapping?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateDentistInput = {
  currentEmail: Scalars['String'];
  newEmail?: InputMaybe<Scalars['String']>;
  newName?: InputMaybe<Scalars['String']>;
};

export type UpdateStateInput = {
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  questionnaireSubmitted: Scalars['Boolean'];
  role: Array<Roles>;
};

export type CreateAccountMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', signUp: { __typename?: 'User', name: string, email: string, role: Array<Roles> } };

export type LogInQueryVariables = Exact<{
  loginInfo: LogUserInput;
}>;


export type LogInQuery = { __typename?: 'Query', login: { __typename?: 'AuthToken', access_token: string } };

export type CreateUserMutationVariables = Exact<{
  userInput: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', name: string, id?: string | null, email: string, role: Array<Roles>, questionnaireSubmitted: boolean } };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', getCurrentUser: { __typename?: 'User', email: string, name: string, id?: string | null, role: Array<Roles>, questionnaireSubmitted: boolean } };

export type GetUserQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'User', name: string, role: Array<Roles>, id?: string | null, questionnaireSubmitted: boolean } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', getDentists: Array<{ __typename?: 'User', id?: string | null, name: string, email: string, role: Array<Roles>, questionnaireSubmitted: boolean }> };

export type CreateDentistMutationVariables = Exact<{
  createDentistInput: CreateDentistInput;
}>;


export type CreateDentistMutation = { __typename?: 'Mutation', addDentist: { __typename?: 'User', name: string, email: string, id?: string | null } };

export type EditDentistMutationVariables = Exact<{
  updateDentistInput: UpdateDentistInput;
}>;


export type EditDentistMutation = { __typename?: 'Mutation', editDentist: { __typename?: 'User', name: string, email: string, id?: string | null, role: Array<Roles>, questionnaireSubmitted: boolean } };

export type DeleteDentistMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type DeleteDentistMutation = { __typename?: 'Mutation', removeDentist: boolean };

export type FindDentistsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindDentistsQuery = { __typename?: 'Query', getDentists: Array<{ __typename?: 'User', name: string, email: string, role: Array<Roles>, id?: string | null }> };

export type CreateQuestionnaireMutationVariables = Exact<{
  createQuestionnaireInput: CreateQuestionnaireInput;
}>;


export type CreateQuestionnaireMutation = { __typename?: 'Mutation', createQuestionnaire: { __typename?: 'Questionnaire', name: string, id?: string | null, insurance: string, state: string } };

export type GetQuestionnaireByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetQuestionnaireByIdQuery = { __typename?: 'Query', getQuestionnaireById: { __typename?: 'Questionnaire', name: string, insurance: string, state: string, questions: Array<{ __typename?: 'Question', id: string, qId: string, type: QuestionType, name: string, extras: string, isRequired: boolean, position: number, rowType: string, requireStorage: string, dropDownAnswers?: Array<string> | null }> } };

export type GetQuestionnaireByStateQueryVariables = Exact<{
  stateId: Scalars['String'];
}>;


export type GetQuestionnaireByStateQuery = { __typename?: 'Query', getQuestionnairesByState: { __typename?: 'Questionnaire', name: string, insurance: string, state: string, id?: string | null, questions: Array<{ __typename?: 'Question', id: string, qId: string, type: QuestionType, name: string, extras: string, isRequired: boolean, position: number, page: number, rowType: string, requireStorage: string, dropDownAnswers?: Array<string> | null }> } };

export type GetQuestionnairesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuestionnairesQuery = { __typename?: 'Query', getQuestionnaires: Array<{ __typename?: 'Questionnaire', id?: string | null, name: string, insurance: string, questions: Array<{ __typename?: 'Question', id: string, qId: string, type: QuestionType, name: string, extras: string, isRequired: boolean, position: number, rowType: string, dropDownAnswers?: Array<string> | null }> }> };

export type GetPartialQuestionnairesByStateQueryVariables = Exact<{
  stateId: Scalars['String'];
}>;


export type GetPartialQuestionnairesByStateQuery = { __typename?: 'Query', getQuestionnairesByState: { __typename?: 'Questionnaire', id?: string | null, name: string } };

export type GetPartialSubmittedQuestionnairesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPartialSubmittedQuestionnairesQuery = { __typename?: 'Query', getSubmittedQuestionnaires: Array<{ __typename?: 'QuestionnaireSubmmited', id: string, qId: string, status: QuestionnaireSubmittedStatus, timestamp: number, state: { __typename?: 'State', name: string, id: string } }> };

export type GetSubmittedQuestionnairesOfUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetSubmittedQuestionnairesOfUserQuery = { __typename?: 'Query', getSubmittedQuestionnairesOfUser: Array<{ __typename?: 'QuestionnaireSubmmited', id: string, qId: string, status: QuestionnaireSubmittedStatus, timestamp: number, answers: Array<{ __typename?: 'QuestionnaireAnswer', id: string, value: string, type: string, qSubmittedId: string, question: { __typename?: 'Question', id: string, qId: string, type: QuestionType, name: string, extras: string, isRequired: boolean, position: number, rowType: string, dropDownAnswers?: Array<string> | null } }> }> };

export type GetPartialSubmittedQuestionnairesOfUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetPartialSubmittedQuestionnairesOfUserQuery = { __typename?: 'Query', getSubmittedQuestionnairesOfUser: Array<{ __typename?: 'QuestionnaireSubmmited', id: string, qId: string, completed: boolean, status: QuestionnaireSubmittedStatus, timestamp: number, state: { __typename?: 'State', name: string, id: string } }> };

export type GetPartialSubmittedQuestionnairesOfStateQueryVariables = Exact<{
  stateId: Scalars['String'];
}>;


export type GetPartialSubmittedQuestionnairesOfStateQuery = { __typename?: 'Query', getSubmittedQuestionnairesByState: Array<{ __typename?: 'QuestionnaireSubmmited', id: string, qId: string, status: QuestionnaireSubmittedStatus, timestamp: number, user: { __typename?: 'User', name: string } }> };

export type GetUserSubmittedQuestionnaireByIdQueryVariables = Exact<{
  userId: Scalars['String'];
  questionnaireSubmittedId: Scalars['String'];
}>;


export type GetUserSubmittedQuestionnaireByIdQuery = { __typename?: 'Query', getSubmittedQuestionnaireById: { __typename?: 'QuestionnaireSubmmited', id: string, qId: string, status: QuestionnaireSubmittedStatus, timestamp: number, answers: Array<{ __typename?: 'QuestionnaireAnswer', id: string, value: string, type: string, qSubmittedId: string, question: { __typename?: 'Question', id: string, qId: string, type: QuestionType, name: string, extras: string, isRequired: boolean, position: number, rowType: string, dropDownAnswers?: Array<string> | null } }>, requestChanges: Array<{ __typename?: 'ChangeRequest', id: string, idQuestion: string, qSubmittedId: string, status: ChangeRequestStatus, reason: string }> } };

export type GetSubmittedQuestionnaireByIdQueryVariables = Exact<{
  userId: Scalars['String'];
  questionnaireSubmittedId: Scalars['String'];
}>;


export type GetSubmittedQuestionnaireByIdQuery = { __typename?: 'Query', getSubmittedQuestionnaireById: { __typename?: 'QuestionnaireSubmmited', id: string, qId: string, status: QuestionnaireSubmittedStatus, timestamp: number, user: { __typename?: 'User', name: string, id?: string | null, email: string }, answers: Array<{ __typename?: 'QuestionnaireAnswer', id: string, value: string, type: string, qSubmittedId: string, question: { __typename?: 'Question', id: string, qId: string, type: QuestionType, name: string, extras: string, isRequired: boolean, position: number, rowType: string, requireStorage: string, dropDownAnswers?: Array<string> | null } }>, requestChanges: Array<{ __typename?: 'ChangeRequest', id: string, idQuestion: string, qSubmittedId: string, status: ChangeRequestStatus, reason: string }> } };

export type GetSubmittedQuestionnairesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubmittedQuestionnairesQuery = { __typename?: 'Query', getSubmittedQuestionnaires: Array<{ __typename?: 'QuestionnaireSubmmited', id: string, qId: string, status: QuestionnaireSubmittedStatus, timestamp: number, answers: Array<{ __typename?: 'QuestionnaireAnswer', id: string, value: string, type: string, qSubmittedId: string, question: { __typename?: 'Question', id: string, name: string, type: QuestionType } }>, requestChanges: Array<{ __typename?: 'ChangeRequest', id: string, idQuestion: string, qSubmittedId: string, status: ChangeRequestStatus, reason: string }> }> };

export type CreateChangeRequestMutationVariables = Exact<{
  ChangeRequestInput: ChangeRequestInput;
}>;


export type CreateChangeRequestMutation = { __typename?: 'Mutation', requestChangeOnSubmittedQuestionnaire: { __typename?: 'ChangeRequest', id: string } };

export type UpdateQuestionnaireMutationVariables = Exact<{
  id: Scalars['String'];
  updateQuestionnaireInput: CreateQuestionnaireInput;
}>;


export type UpdateQuestionnaireMutation = { __typename?: 'Mutation', updateQuestionnaire: { __typename?: 'Questionnaire', name: string, id?: string | null, insurance: string, state: string } };

export type UpdateAnswerOfQuestionnaireMutationVariables = Exact<{
  answerUpdateInput: AnswerUpdateInput;
}>;


export type UpdateAnswerOfQuestionnaireMutation = { __typename?: 'Mutation', editAnswerOfQuestionnaire: { __typename?: 'QuestionnaireAnswer', id: string, value: string, type: string, question: { __typename?: 'Question', id: string, name: string } } };

export type AcceptChangeRequestMutationVariables = Exact<{
  requestId: Scalars['String'];
}>;


export type AcceptChangeRequestMutation = { __typename?: 'Mutation', acceptChangeRequest: { __typename?: 'ChangeRequest', id: string, status: ChangeRequestStatus } };

export type AcceptQuestionnaireSubmittedMutationVariables = Exact<{
  qId: Scalars['String'];
}>;


export type AcceptQuestionnaireSubmittedMutation = { __typename?: 'Mutation', acceptQuestionnaireSubmitted: string };

export type UpdateQuestionMutationVariables = Exact<{
  input: CreateQuestionInput;
  questionId: Scalars['String'];
}>;


export type UpdateQuestionMutation = { __typename?: 'Mutation', updateQuestion: { __typename?: 'Question', id: string, qId: string, type: QuestionType, name: string, extras: string, isRequired: boolean, position: number, rowType: string, requireStorage: string, dropDownAnswers?: Array<string> | null } };

export type DeleteQuestionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteQuestionMutation = { __typename?: 'Mutation', deleteQuestion: boolean };

export type DeleteQuestionnaireMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteQuestionnaireMutation = { __typename?: 'Mutation', deleteQuestionnaire: boolean };

export type SubmitQuestionnaireMutationVariables = Exact<{
  submitQuesstionnaire: QuestionnaireSubmittion;
}>;


export type SubmitQuestionnaireMutation = { __typename?: 'Mutation', submitQuesstionnaire: { __typename?: 'QuestionnaireSubmmited', completed: boolean, id: string, status: QuestionnaireSubmittedStatus, answers: Array<{ __typename?: 'QuestionnaireAnswer', id: string, value: string }> } };

export type CreateStateMutationVariables = Exact<{
  createStateInput: CreateStateInput;
}>;


export type CreateStateMutation = { __typename?: 'Mutation', createState: { __typename?: 'State', name: string, id: string, status: string } };

export type GetPaymentTerminalQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentTerminalQuery = { __typename?: 'Query', getPaymentTerminal: string };

export type GetStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStatesQuery = { __typename?: 'Query', getStates: Array<{ __typename?: 'State', name: string, id: string, status: string }> };

export type UpdateStateMutationVariables = Exact<{
  id: Scalars['String'];
  updateStateInput: UpdateStateInput;
}>;


export type UpdateStateMutation = { __typename?: 'Mutation', updateState: { __typename?: 'State', status: string, id: string, name: string } };

export type DeleteStateMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteStateMutation = { __typename?: 'Mutation', deleteState: boolean };

export type CreateCompanyMutationVariables = Exact<{
  createCompanyInput: CreateCompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'Company', name: string, id: string, pdfMapping: string, states: Array<{ __typename?: 'State', name: string }> } };

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompaniesQuery = { __typename?: 'Query', getCompanies: Array<{ __typename?: 'Company', name: string, id: string }> };

export type GetCompanyQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCompanyQuery = { __typename?: 'Query', getCompanyById: { __typename?: 'Company', name: string, id: string, states: Array<{ __typename?: 'State', name: string, status: string, id: string }> } };

export type GetCompaniesOfStateQueryVariables = Exact<{
  stateId: Scalars['String'];
}>;


export type GetCompaniesOfStateQuery = { __typename?: 'Query', getCompaniesOfState: Array<{ __typename?: 'Company', name: string, id: string }> };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['String'];
  updateCompanyInput: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename?: 'Company', id: string, name: string, pdfMapping: string, states: Array<{ __typename?: 'State', name: string }> } };

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCompanyMutation = { __typename?: 'Mutation', deleteCompany: boolean };

export type GetPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlansQuery = { __typename?: 'Query', getPlans: Array<{ __typename?: 'Plan', id: string, annualPrice: number, monthPrice: number, info: string, minInsurances: number, maxInsurances: number }> };

export type MakeInsurancesPaymentMutationVariables = Exact<{
  createPayment: CreatePaymentInput;
}>;


export type MakeInsurancesPaymentMutation = { __typename?: 'Mutation', createPayment: { __typename?: 'Payment', id: string, status: PaymentStatus } };

export type GetPlansForInsurancePaymentQueryVariables = Exact<{
  insurances: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetPlansForInsurancePaymentQuery = { __typename?: 'Query', getPlansForInsurancePayment: { __typename?: 'Plan', id: string, annualPrice: number, monthPrice: number, info: string, minInsurances: number, maxInsurances: number } };


export const CreateAccountDocument = gql`
    mutation createAccount($input: CreateUserInput!) {
  signUp(input: $input) {
    name
    email
    role
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const LogInDocument = gql`
    query logIn($loginInfo: LogUserInput!) {
  login(input: $loginInfo) {
    access_token
  }
}
    `;

/**
 * __useLogInQuery__
 *
 * To run a query within a React component, call `useLogInQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogInQuery({
 *   variables: {
 *      loginInfo: // value for 'loginInfo'
 *   },
 * });
 */
export function useLogInQuery(baseOptions: Apollo.QueryHookOptions<LogInQuery, LogInQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LogInQuery, LogInQueryVariables>(LogInDocument, options);
      }
export function useLogInLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogInQuery, LogInQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LogInQuery, LogInQueryVariables>(LogInDocument, options);
        }
export type LogInQueryHookResult = ReturnType<typeof useLogInQuery>;
export type LogInLazyQueryHookResult = ReturnType<typeof useLogInLazyQuery>;
export type LogInQueryResult = Apollo.QueryResult<LogInQuery, LogInQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($userInput: CreateUserInput!) {
  createUser(input: $userInput) {
    name
    id
    email
    role
    questionnaireSubmitted
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  getCurrentUser {
    email
    name
    id
    role
    questionnaireSubmitted
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetUserDocument = gql`
    query getUser($email: String!) {
  getUser(email: $email) {
    name
    role
    id
    questionnaireSubmitted
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers {
  getDentists {
    id
    name
    email
    role
    questionnaireSubmitted
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const CreateDentistDocument = gql`
    mutation createDentist($createDentistInput: CreateDentistInput!) {
  addDentist(input: $createDentistInput) {
    name
    email
    id
  }
}
    `;
export type CreateDentistMutationFn = Apollo.MutationFunction<CreateDentistMutation, CreateDentistMutationVariables>;

/**
 * __useCreateDentistMutation__
 *
 * To run a mutation, you first call `useCreateDentistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDentistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDentistMutation, { data, loading, error }] = useCreateDentistMutation({
 *   variables: {
 *      createDentistInput: // value for 'createDentistInput'
 *   },
 * });
 */
export function useCreateDentistMutation(baseOptions?: Apollo.MutationHookOptions<CreateDentistMutation, CreateDentistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDentistMutation, CreateDentistMutationVariables>(CreateDentistDocument, options);
      }
export type CreateDentistMutationHookResult = ReturnType<typeof useCreateDentistMutation>;
export type CreateDentistMutationResult = Apollo.MutationResult<CreateDentistMutation>;
export type CreateDentistMutationOptions = Apollo.BaseMutationOptions<CreateDentistMutation, CreateDentistMutationVariables>;
export const EditDentistDocument = gql`
    mutation editDentist($updateDentistInput: UpdateDentistInput!) {
  editDentist(input: $updateDentistInput) {
    name
    email
    id
    role
    questionnaireSubmitted
  }
}
    `;
export type EditDentistMutationFn = Apollo.MutationFunction<EditDentistMutation, EditDentistMutationVariables>;

/**
 * __useEditDentistMutation__
 *
 * To run a mutation, you first call `useEditDentistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDentistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDentistMutation, { data, loading, error }] = useEditDentistMutation({
 *   variables: {
 *      updateDentistInput: // value for 'updateDentistInput'
 *   },
 * });
 */
export function useEditDentistMutation(baseOptions?: Apollo.MutationHookOptions<EditDentistMutation, EditDentistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDentistMutation, EditDentistMutationVariables>(EditDentistDocument, options);
      }
export type EditDentistMutationHookResult = ReturnType<typeof useEditDentistMutation>;
export type EditDentistMutationResult = Apollo.MutationResult<EditDentistMutation>;
export type EditDentistMutationOptions = Apollo.BaseMutationOptions<EditDentistMutation, EditDentistMutationVariables>;
export const DeleteDentistDocument = gql`
    mutation deleteDentist($email: String!) {
  removeDentist(input: $email)
}
    `;
export type DeleteDentistMutationFn = Apollo.MutationFunction<DeleteDentistMutation, DeleteDentistMutationVariables>;

/**
 * __useDeleteDentistMutation__
 *
 * To run a mutation, you first call `useDeleteDentistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDentistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDentistMutation, { data, loading, error }] = useDeleteDentistMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDeleteDentistMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDentistMutation, DeleteDentistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDentistMutation, DeleteDentistMutationVariables>(DeleteDentistDocument, options);
      }
export type DeleteDentistMutationHookResult = ReturnType<typeof useDeleteDentistMutation>;
export type DeleteDentistMutationResult = Apollo.MutationResult<DeleteDentistMutation>;
export type DeleteDentistMutationOptions = Apollo.BaseMutationOptions<DeleteDentistMutation, DeleteDentistMutationVariables>;
export const FindDentistsDocument = gql`
    query findDentists {
  getDentists {
    name
    email
    role
    id
  }
}
    `;

/**
 * __useFindDentistsQuery__
 *
 * To run a query within a React component, call `useFindDentistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDentistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDentistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindDentistsQuery(baseOptions?: Apollo.QueryHookOptions<FindDentistsQuery, FindDentistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindDentistsQuery, FindDentistsQueryVariables>(FindDentistsDocument, options);
      }
export function useFindDentistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindDentistsQuery, FindDentistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindDentistsQuery, FindDentistsQueryVariables>(FindDentistsDocument, options);
        }
export type FindDentistsQueryHookResult = ReturnType<typeof useFindDentistsQuery>;
export type FindDentistsLazyQueryHookResult = ReturnType<typeof useFindDentistsLazyQuery>;
export type FindDentistsQueryResult = Apollo.QueryResult<FindDentistsQuery, FindDentistsQueryVariables>;
export const CreateQuestionnaireDocument = gql`
    mutation createQuestionnaire($createQuestionnaireInput: CreateQuestionnaireInput!) {
  createQuestionnaire(input: $createQuestionnaireInput) {
    name
    id
    insurance
    state
  }
}
    `;
export type CreateQuestionnaireMutationFn = Apollo.MutationFunction<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>;

/**
 * __useCreateQuestionnaireMutation__
 *
 * To run a mutation, you first call `useCreateQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionnaireMutation, { data, loading, error }] = useCreateQuestionnaireMutation({
 *   variables: {
 *      createQuestionnaireInput: // value for 'createQuestionnaireInput'
 *   },
 * });
 */
export function useCreateQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>(CreateQuestionnaireDocument, options);
      }
export type CreateQuestionnaireMutationHookResult = ReturnType<typeof useCreateQuestionnaireMutation>;
export type CreateQuestionnaireMutationResult = Apollo.MutationResult<CreateQuestionnaireMutation>;
export type CreateQuestionnaireMutationOptions = Apollo.BaseMutationOptions<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>;
export const GetQuestionnaireByIdDocument = gql`
    query getQuestionnaireById($id: String!) {
  getQuestionnaireById(id: $id) {
    name
    insurance
    state
    questions {
      id
      qId
      type
      name
      extras
      isRequired
      position
      rowType
      requireStorage
      dropDownAnswers
    }
  }
}
    `;

/**
 * __useGetQuestionnaireByIdQuery__
 *
 * To run a query within a React component, call `useGetQuestionnaireByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnaireByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnaireByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionnaireByIdQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionnaireByIdQuery, GetQuestionnaireByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionnaireByIdQuery, GetQuestionnaireByIdQueryVariables>(GetQuestionnaireByIdDocument, options);
      }
export function useGetQuestionnaireByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionnaireByIdQuery, GetQuestionnaireByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionnaireByIdQuery, GetQuestionnaireByIdQueryVariables>(GetQuestionnaireByIdDocument, options);
        }
export type GetQuestionnaireByIdQueryHookResult = ReturnType<typeof useGetQuestionnaireByIdQuery>;
export type GetQuestionnaireByIdLazyQueryHookResult = ReturnType<typeof useGetQuestionnaireByIdLazyQuery>;
export type GetQuestionnaireByIdQueryResult = Apollo.QueryResult<GetQuestionnaireByIdQuery, GetQuestionnaireByIdQueryVariables>;
export const GetQuestionnaireByStateDocument = gql`
    query getQuestionnaireByState($stateId: String!) {
  getQuestionnairesByState(stateId: $stateId) {
    name
    insurance
    state
    id
    questions {
      id
      qId
      type
      name
      extras
      isRequired
      position
      rowType
      requireStorage
      dropDownAnswers
    }
  }
}
    `;

/**
 * __useGetQuestionnaireByStateQuery__
 *
 * To run a query within a React component, call `useGetQuestionnaireByStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnaireByStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnaireByStateQuery({
 *   variables: {
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useGetQuestionnaireByStateQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionnaireByStateQuery, GetQuestionnaireByStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionnaireByStateQuery, GetQuestionnaireByStateQueryVariables>(GetQuestionnaireByStateDocument, options);
      }
export function useGetQuestionnaireByStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionnaireByStateQuery, GetQuestionnaireByStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionnaireByStateQuery, GetQuestionnaireByStateQueryVariables>(GetQuestionnaireByStateDocument, options);
        }
export type GetQuestionnaireByStateQueryHookResult = ReturnType<typeof useGetQuestionnaireByStateQuery>;
export type GetQuestionnaireByStateLazyQueryHookResult = ReturnType<typeof useGetQuestionnaireByStateLazyQuery>;
export type GetQuestionnaireByStateQueryResult = Apollo.QueryResult<GetQuestionnaireByStateQuery, GetQuestionnaireByStateQueryVariables>;
export const GetQuestionnairesDocument = gql`
    query getQuestionnaires {
  getQuestionnaires {
    id
    name
    insurance
    questions {
      id
      qId
      type
      name
      extras
      isRequired
      position
      page
      rowType
      dropDownAnswers
    }
  }
}
    `;

/**
 * __useGetQuestionnairesQuery__
 *
 * To run a query within a React component, call `useGetQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnairesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuestionnairesQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>(GetQuestionnairesDocument, options);
      }
export function useGetQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>(GetQuestionnairesDocument, options);
        }
export type GetQuestionnairesQueryHookResult = ReturnType<typeof useGetQuestionnairesQuery>;
export type GetQuestionnairesLazyQueryHookResult = ReturnType<typeof useGetQuestionnairesLazyQuery>;
export type GetQuestionnairesQueryResult = Apollo.QueryResult<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>;
export const GetPartialQuestionnairesByStateDocument = gql`
    query getPartialQuestionnairesByState($stateId: String!) {
  getQuestionnairesByState(stateId: $stateId) {
    id
    name
  }
}
    `;

/**
 * __useGetPartialQuestionnairesByStateQuery__
 *
 * To run a query within a React component, call `useGetPartialQuestionnairesByStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartialQuestionnairesByStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartialQuestionnairesByStateQuery({
 *   variables: {
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useGetPartialQuestionnairesByStateQuery(baseOptions: Apollo.QueryHookOptions<GetPartialQuestionnairesByStateQuery, GetPartialQuestionnairesByStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartialQuestionnairesByStateQuery, GetPartialQuestionnairesByStateQueryVariables>(GetPartialQuestionnairesByStateDocument, options);
      }
export function useGetPartialQuestionnairesByStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartialQuestionnairesByStateQuery, GetPartialQuestionnairesByStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartialQuestionnairesByStateQuery, GetPartialQuestionnairesByStateQueryVariables>(GetPartialQuestionnairesByStateDocument, options);
        }
export type GetPartialQuestionnairesByStateQueryHookResult = ReturnType<typeof useGetPartialQuestionnairesByStateQuery>;
export type GetPartialQuestionnairesByStateLazyQueryHookResult = ReturnType<typeof useGetPartialQuestionnairesByStateLazyQuery>;
export type GetPartialQuestionnairesByStateQueryResult = Apollo.QueryResult<GetPartialQuestionnairesByStateQuery, GetPartialQuestionnairesByStateQueryVariables>;
export const GetPartialSubmittedQuestionnairesDocument = gql`
    query getPartialSubmittedQuestionnaires {
  getSubmittedQuestionnaires {
    id
    qId
    state {
      name
      id
    }
    status
    timestamp
  }
}
    `;

/**
 * __useGetPartialSubmittedQuestionnairesQuery__
 *
 * To run a query within a React component, call `useGetPartialSubmittedQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartialSubmittedQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartialSubmittedQuestionnairesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartialSubmittedQuestionnairesQuery(baseOptions?: Apollo.QueryHookOptions<GetPartialSubmittedQuestionnairesQuery, GetPartialSubmittedQuestionnairesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartialSubmittedQuestionnairesQuery, GetPartialSubmittedQuestionnairesQueryVariables>(GetPartialSubmittedQuestionnairesDocument, options);
      }
export function useGetPartialSubmittedQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartialSubmittedQuestionnairesQuery, GetPartialSubmittedQuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartialSubmittedQuestionnairesQuery, GetPartialSubmittedQuestionnairesQueryVariables>(GetPartialSubmittedQuestionnairesDocument, options);
        }
export type GetPartialSubmittedQuestionnairesQueryHookResult = ReturnType<typeof useGetPartialSubmittedQuestionnairesQuery>;
export type GetPartialSubmittedQuestionnairesLazyQueryHookResult = ReturnType<typeof useGetPartialSubmittedQuestionnairesLazyQuery>;
export type GetPartialSubmittedQuestionnairesQueryResult = Apollo.QueryResult<GetPartialSubmittedQuestionnairesQuery, GetPartialSubmittedQuestionnairesQueryVariables>;
export const GetSubmittedQuestionnairesOfUserDocument = gql`
    query getSubmittedQuestionnairesOfUser($userId: String!) {
  getSubmittedQuestionnairesOfUser(userId: $userId) {
    id
    qId
    status
    timestamp
    answers {
      id
      value
      question {
        id
        qId
        type
        name
        extras
        isRequired
        position
        rowType
        dropDownAnswers
      }
      type
      qSubmittedId
    }
  }
}
    `;

/**
 * __useGetSubmittedQuestionnairesOfUserQuery__
 *
 * To run a query within a React component, call `useGetSubmittedQuestionnairesOfUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmittedQuestionnairesOfUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmittedQuestionnairesOfUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetSubmittedQuestionnairesOfUserQuery(baseOptions: Apollo.QueryHookOptions<GetSubmittedQuestionnairesOfUserQuery, GetSubmittedQuestionnairesOfUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubmittedQuestionnairesOfUserQuery, GetSubmittedQuestionnairesOfUserQueryVariables>(GetSubmittedQuestionnairesOfUserDocument, options);
      }
export function useGetSubmittedQuestionnairesOfUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubmittedQuestionnairesOfUserQuery, GetSubmittedQuestionnairesOfUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubmittedQuestionnairesOfUserQuery, GetSubmittedQuestionnairesOfUserQueryVariables>(GetSubmittedQuestionnairesOfUserDocument, options);
        }
export type GetSubmittedQuestionnairesOfUserQueryHookResult = ReturnType<typeof useGetSubmittedQuestionnairesOfUserQuery>;
export type GetSubmittedQuestionnairesOfUserLazyQueryHookResult = ReturnType<typeof useGetSubmittedQuestionnairesOfUserLazyQuery>;
export type GetSubmittedQuestionnairesOfUserQueryResult = Apollo.QueryResult<GetSubmittedQuestionnairesOfUserQuery, GetSubmittedQuestionnairesOfUserQueryVariables>;
export const GetPartialSubmittedQuestionnairesOfUserDocument = gql`
    query getPartialSubmittedQuestionnairesOfUser($userId: String!) {
  getSubmittedQuestionnairesOfUser(userId: $userId) {
    id
    qId
    state {
      name
      id
    }
    completed
    status
    timestamp
  }
}
    `;

/**
 * __useGetPartialSubmittedQuestionnairesOfUserQuery__
 *
 * To run a query within a React component, call `useGetPartialSubmittedQuestionnairesOfUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartialSubmittedQuestionnairesOfUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartialSubmittedQuestionnairesOfUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPartialSubmittedQuestionnairesOfUserQuery(baseOptions: Apollo.QueryHookOptions<GetPartialSubmittedQuestionnairesOfUserQuery, GetPartialSubmittedQuestionnairesOfUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartialSubmittedQuestionnairesOfUserQuery, GetPartialSubmittedQuestionnairesOfUserQueryVariables>(GetPartialSubmittedQuestionnairesOfUserDocument, options);
      }
export function useGetPartialSubmittedQuestionnairesOfUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartialSubmittedQuestionnairesOfUserQuery, GetPartialSubmittedQuestionnairesOfUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartialSubmittedQuestionnairesOfUserQuery, GetPartialSubmittedQuestionnairesOfUserQueryVariables>(GetPartialSubmittedQuestionnairesOfUserDocument, options);
        }
export type GetPartialSubmittedQuestionnairesOfUserQueryHookResult = ReturnType<typeof useGetPartialSubmittedQuestionnairesOfUserQuery>;
export type GetPartialSubmittedQuestionnairesOfUserLazyQueryHookResult = ReturnType<typeof useGetPartialSubmittedQuestionnairesOfUserLazyQuery>;
export type GetPartialSubmittedQuestionnairesOfUserQueryResult = Apollo.QueryResult<GetPartialSubmittedQuestionnairesOfUserQuery, GetPartialSubmittedQuestionnairesOfUserQueryVariables>;
export const GetPartialSubmittedQuestionnairesOfStateDocument = gql`
    query getPartialSubmittedQuestionnairesOfState($stateId: String!) {
  getSubmittedQuestionnairesByState(stateId: $stateId) {
    id
    qId
    status
    timestamp
    user {
      name
    }
  }
}
    `;

/**
 * __useGetPartialSubmittedQuestionnairesOfStateQuery__
 *
 * To run a query within a React component, call `useGetPartialSubmittedQuestionnairesOfStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartialSubmittedQuestionnairesOfStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartialSubmittedQuestionnairesOfStateQuery({
 *   variables: {
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useGetPartialSubmittedQuestionnairesOfStateQuery(baseOptions: Apollo.QueryHookOptions<GetPartialSubmittedQuestionnairesOfStateQuery, GetPartialSubmittedQuestionnairesOfStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartialSubmittedQuestionnairesOfStateQuery, GetPartialSubmittedQuestionnairesOfStateQueryVariables>(GetPartialSubmittedQuestionnairesOfStateDocument, options);
      }
export function useGetPartialSubmittedQuestionnairesOfStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartialSubmittedQuestionnairesOfStateQuery, GetPartialSubmittedQuestionnairesOfStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartialSubmittedQuestionnairesOfStateQuery, GetPartialSubmittedQuestionnairesOfStateQueryVariables>(GetPartialSubmittedQuestionnairesOfStateDocument, options);
        }
export type GetPartialSubmittedQuestionnairesOfStateQueryHookResult = ReturnType<typeof useGetPartialSubmittedQuestionnairesOfStateQuery>;
export type GetPartialSubmittedQuestionnairesOfStateLazyQueryHookResult = ReturnType<typeof useGetPartialSubmittedQuestionnairesOfStateLazyQuery>;
export type GetPartialSubmittedQuestionnairesOfStateQueryResult = Apollo.QueryResult<GetPartialSubmittedQuestionnairesOfStateQuery, GetPartialSubmittedQuestionnairesOfStateQueryVariables>;
export const GetUserSubmittedQuestionnaireByIdDocument = gql`
    query getUserSubmittedQuestionnaireById($userId: String!, $questionnaireSubmittedId: String!) {
  getSubmittedQuestionnaireById(
    userId: $userId
    questionnaireSubmittedId: $questionnaireSubmittedId
  ) {
    id
    qId
    status
    timestamp
    answers {
      id
      value
      question {
        id
        qId
        type
        name
        extras
        isRequired
        position
        rowType
        dropDownAnswers
      }
      type
      qSubmittedId
    }
    requestChanges {
      id
      idQuestion
      qSubmittedId
      status
      reason
    }
  }
}
    `;

/**
 * __useGetUserSubmittedQuestionnaireByIdQuery__
 *
 * To run a query within a React component, call `useGetUserSubmittedQuestionnaireByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubmittedQuestionnaireByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubmittedQuestionnaireByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      questionnaireSubmittedId: // value for 'questionnaireSubmittedId'
 *   },
 * });
 */
export function useGetUserSubmittedQuestionnaireByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserSubmittedQuestionnaireByIdQuery, GetUserSubmittedQuestionnaireByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSubmittedQuestionnaireByIdQuery, GetUserSubmittedQuestionnaireByIdQueryVariables>(GetUserSubmittedQuestionnaireByIdDocument, options);
      }
export function useGetUserSubmittedQuestionnaireByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSubmittedQuestionnaireByIdQuery, GetUserSubmittedQuestionnaireByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSubmittedQuestionnaireByIdQuery, GetUserSubmittedQuestionnaireByIdQueryVariables>(GetUserSubmittedQuestionnaireByIdDocument, options);
        }
export type GetUserSubmittedQuestionnaireByIdQueryHookResult = ReturnType<typeof useGetUserSubmittedQuestionnaireByIdQuery>;
export type GetUserSubmittedQuestionnaireByIdLazyQueryHookResult = ReturnType<typeof useGetUserSubmittedQuestionnaireByIdLazyQuery>;
export type GetUserSubmittedQuestionnaireByIdQueryResult = Apollo.QueryResult<GetUserSubmittedQuestionnaireByIdQuery, GetUserSubmittedQuestionnaireByIdQueryVariables>;
export const GetSubmittedQuestionnaireByIdDocument = gql`
    query getSubmittedQuestionnaireById($userId: String!, $questionnaireSubmittedId: String!) {
  getSubmittedQuestionnaireById(
    userId: $userId
    questionnaireSubmittedId: $questionnaireSubmittedId
  ) {
    id
    qId
    status
    timestamp
    user {
      name
      id
      email
    }
    answers {
      id
      value
      question {
        id
        qId
        type
        name
        extras
        isRequired
        position
        rowType
        requireStorage
        dropDownAnswers
      }
      type
      qSubmittedId
    }
    requestChanges {
      id
      idQuestion
      qSubmittedId
      status
      reason
    }
  }
}
    `;

/**
 * __useGetSubmittedQuestionnaireByIdQuery__
 *
 * To run a query within a React component, call `useGetSubmittedQuestionnaireByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmittedQuestionnaireByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmittedQuestionnaireByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      questionnaireSubmittedId: // value for 'questionnaireSubmittedId'
 *   },
 * });
 */
export function useGetSubmittedQuestionnaireByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSubmittedQuestionnaireByIdQuery, GetSubmittedQuestionnaireByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubmittedQuestionnaireByIdQuery, GetSubmittedQuestionnaireByIdQueryVariables>(GetSubmittedQuestionnaireByIdDocument, options);
      }
export function useGetSubmittedQuestionnaireByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubmittedQuestionnaireByIdQuery, GetSubmittedQuestionnaireByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubmittedQuestionnaireByIdQuery, GetSubmittedQuestionnaireByIdQueryVariables>(GetSubmittedQuestionnaireByIdDocument, options);
        }
export type GetSubmittedQuestionnaireByIdQueryHookResult = ReturnType<typeof useGetSubmittedQuestionnaireByIdQuery>;
export type GetSubmittedQuestionnaireByIdLazyQueryHookResult = ReturnType<typeof useGetSubmittedQuestionnaireByIdLazyQuery>;
export type GetSubmittedQuestionnaireByIdQueryResult = Apollo.QueryResult<GetSubmittedQuestionnaireByIdQuery, GetSubmittedQuestionnaireByIdQueryVariables>;
export const GetSubmittedQuestionnairesDocument = gql`
    query getSubmittedQuestionnaires {
  getSubmittedQuestionnaires {
    id
    qId
    status
    timestamp
    answers {
      id
      value
      question {
        id
        name
        type
      }
      type
      qSubmittedId
    }
    requestChanges {
      id
      idQuestion
      qSubmittedId
      status
      reason
    }
  }
}
    `;

/**
 * __useGetSubmittedQuestionnairesQuery__
 *
 * To run a query within a React component, call `useGetSubmittedQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmittedQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmittedQuestionnairesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubmittedQuestionnairesQuery(baseOptions?: Apollo.QueryHookOptions<GetSubmittedQuestionnairesQuery, GetSubmittedQuestionnairesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubmittedQuestionnairesQuery, GetSubmittedQuestionnairesQueryVariables>(GetSubmittedQuestionnairesDocument, options);
      }
export function useGetSubmittedQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubmittedQuestionnairesQuery, GetSubmittedQuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubmittedQuestionnairesQuery, GetSubmittedQuestionnairesQueryVariables>(GetSubmittedQuestionnairesDocument, options);
        }
export type GetSubmittedQuestionnairesQueryHookResult = ReturnType<typeof useGetSubmittedQuestionnairesQuery>;
export type GetSubmittedQuestionnairesLazyQueryHookResult = ReturnType<typeof useGetSubmittedQuestionnairesLazyQuery>;
export type GetSubmittedQuestionnairesQueryResult = Apollo.QueryResult<GetSubmittedQuestionnairesQuery, GetSubmittedQuestionnairesQueryVariables>;
export const CreateChangeRequestDocument = gql`
    mutation createChangeRequest($ChangeRequestInput: ChangeRequestInput!) {
  requestChangeOnSubmittedQuestionnaire(input: $ChangeRequestInput) {
    id
  }
}
    `;
export type CreateChangeRequestMutationFn = Apollo.MutationFunction<CreateChangeRequestMutation, CreateChangeRequestMutationVariables>;

/**
 * __useCreateChangeRequestMutation__
 *
 * To run a mutation, you first call `useCreateChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChangeRequestMutation, { data, loading, error }] = useCreateChangeRequestMutation({
 *   variables: {
 *      ChangeRequestInput: // value for 'ChangeRequestInput'
 *   },
 * });
 */
export function useCreateChangeRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateChangeRequestMutation, CreateChangeRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChangeRequestMutation, CreateChangeRequestMutationVariables>(CreateChangeRequestDocument, options);
      }
export type CreateChangeRequestMutationHookResult = ReturnType<typeof useCreateChangeRequestMutation>;
export type CreateChangeRequestMutationResult = Apollo.MutationResult<CreateChangeRequestMutation>;
export type CreateChangeRequestMutationOptions = Apollo.BaseMutationOptions<CreateChangeRequestMutation, CreateChangeRequestMutationVariables>;
export const UpdateQuestionnaireDocument = gql`
    mutation updateQuestionnaire($id: String!, $updateQuestionnaireInput: CreateQuestionnaireInput!) {
  updateQuestionnaire(id: $id, input: $updateQuestionnaireInput) {
    name
    id
    insurance
    state
  }
}
    `;
export type UpdateQuestionnaireMutationFn = Apollo.MutationFunction<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>;

/**
 * __useUpdateQuestionnaireMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionnaireMutation, { data, loading, error }] = useUpdateQuestionnaireMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateQuestionnaireInput: // value for 'updateQuestionnaireInput'
 *   },
 * });
 */
export function useUpdateQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>(UpdateQuestionnaireDocument, options);
      }
export type UpdateQuestionnaireMutationHookResult = ReturnType<typeof useUpdateQuestionnaireMutation>;
export type UpdateQuestionnaireMutationResult = Apollo.MutationResult<UpdateQuestionnaireMutation>;
export type UpdateQuestionnaireMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>;
export const UpdateAnswerOfQuestionnaireDocument = gql`
    mutation updateAnswerOfQuestionnaire($answerUpdateInput: AnswerUpdateInput!) {
  editAnswerOfQuestionnaire(input: $answerUpdateInput) {
    id
    value
    question {
      id
      name
    }
    type
  }
}
    `;
export type UpdateAnswerOfQuestionnaireMutationFn = Apollo.MutationFunction<UpdateAnswerOfQuestionnaireMutation, UpdateAnswerOfQuestionnaireMutationVariables>;

/**
 * __useUpdateAnswerOfQuestionnaireMutation__
 *
 * To run a mutation, you first call `useUpdateAnswerOfQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnswerOfQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnswerOfQuestionnaireMutation, { data, loading, error }] = useUpdateAnswerOfQuestionnaireMutation({
 *   variables: {
 *      answerUpdateInput: // value for 'answerUpdateInput'
 *   },
 * });
 */
export function useUpdateAnswerOfQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnswerOfQuestionnaireMutation, UpdateAnswerOfQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnswerOfQuestionnaireMutation, UpdateAnswerOfQuestionnaireMutationVariables>(UpdateAnswerOfQuestionnaireDocument, options);
      }
export type UpdateAnswerOfQuestionnaireMutationHookResult = ReturnType<typeof useUpdateAnswerOfQuestionnaireMutation>;
export type UpdateAnswerOfQuestionnaireMutationResult = Apollo.MutationResult<UpdateAnswerOfQuestionnaireMutation>;
export type UpdateAnswerOfQuestionnaireMutationOptions = Apollo.BaseMutationOptions<UpdateAnswerOfQuestionnaireMutation, UpdateAnswerOfQuestionnaireMutationVariables>;
export const AcceptChangeRequestDocument = gql`
    mutation acceptChangeRequest($requestId: String!) {
  acceptChangeRequest(requestId: $requestId) {
    id
    status
  }
}
    `;
export type AcceptChangeRequestMutationFn = Apollo.MutationFunction<AcceptChangeRequestMutation, AcceptChangeRequestMutationVariables>;

/**
 * __useAcceptChangeRequestMutation__
 *
 * To run a mutation, you first call `useAcceptChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptChangeRequestMutation, { data, loading, error }] = useAcceptChangeRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useAcceptChangeRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptChangeRequestMutation, AcceptChangeRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptChangeRequestMutation, AcceptChangeRequestMutationVariables>(AcceptChangeRequestDocument, options);
      }
export type AcceptChangeRequestMutationHookResult = ReturnType<typeof useAcceptChangeRequestMutation>;
export type AcceptChangeRequestMutationResult = Apollo.MutationResult<AcceptChangeRequestMutation>;
export type AcceptChangeRequestMutationOptions = Apollo.BaseMutationOptions<AcceptChangeRequestMutation, AcceptChangeRequestMutationVariables>;
export const AcceptQuestionnaireSubmittedDocument = gql`
    mutation acceptQuestionnaireSubmitted($qId: String!) {
  acceptQuestionnaireSubmitted(qId: $qId)
}
    `;
export type AcceptQuestionnaireSubmittedMutationFn = Apollo.MutationFunction<AcceptQuestionnaireSubmittedMutation, AcceptQuestionnaireSubmittedMutationVariables>;

/**
 * __useAcceptQuestionnaireSubmittedMutation__
 *
 * To run a mutation, you first call `useAcceptQuestionnaireSubmittedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptQuestionnaireSubmittedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptQuestionnaireSubmittedMutation, { data, loading, error }] = useAcceptQuestionnaireSubmittedMutation({
 *   variables: {
 *      qId: // value for 'qId'
 *   },
 * });
 */
export function useAcceptQuestionnaireSubmittedMutation(baseOptions?: Apollo.MutationHookOptions<AcceptQuestionnaireSubmittedMutation, AcceptQuestionnaireSubmittedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptQuestionnaireSubmittedMutation, AcceptQuestionnaireSubmittedMutationVariables>(AcceptQuestionnaireSubmittedDocument, options);
      }
export type AcceptQuestionnaireSubmittedMutationHookResult = ReturnType<typeof useAcceptQuestionnaireSubmittedMutation>;
export type AcceptQuestionnaireSubmittedMutationResult = Apollo.MutationResult<AcceptQuestionnaireSubmittedMutation>;
export type AcceptQuestionnaireSubmittedMutationOptions = Apollo.BaseMutationOptions<AcceptQuestionnaireSubmittedMutation, AcceptQuestionnaireSubmittedMutationVariables>;
export const UpdateQuestionDocument = gql`
    mutation updateQuestion($input: CreateQuestionInput!, $questionId: String!) {
  updateQuestion(input: $input, questionId: $questionId) {
    id
    qId
    type
    name
    extras
    isRequired
    position
    rowType
    requireStorage
    dropDownAnswers
  }
}
    `;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<UpdateQuestionMutation, UpdateQuestionMutationVariables>;

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, options);
      }
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export const DeleteQuestionDocument = gql`
    mutation deleteQuestion($id: String!) {
  deleteQuestion(questionId: $id)
}
    `;
export type DeleteQuestionMutationFn = Apollo.MutationFunction<DeleteQuestionMutation, DeleteQuestionMutationVariables>;

/**
 * __useDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionMutation, { data, loading, error }] = useDeleteQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionMutation, DeleteQuestionMutationVariables>(DeleteQuestionDocument, options);
      }
export type DeleteQuestionMutationHookResult = ReturnType<typeof useDeleteQuestionMutation>;
export type DeleteQuestionMutationResult = Apollo.MutationResult<DeleteQuestionMutation>;
export type DeleteQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>;
export const DeleteQuestionnaireDocument = gql`
    mutation deleteQuestionnaire($id: String!) {
  deleteQuestionnaire(id: $id)
}
    `;
export type DeleteQuestionnaireMutationFn = Apollo.MutationFunction<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>;

/**
 * __useDeleteQuestionnaireMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionnaireMutation, { data, loading, error }] = useDeleteQuestionnaireMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>(DeleteQuestionnaireDocument, options);
      }
export type DeleteQuestionnaireMutationHookResult = ReturnType<typeof useDeleteQuestionnaireMutation>;
export type DeleteQuestionnaireMutationResult = Apollo.MutationResult<DeleteQuestionnaireMutation>;
export type DeleteQuestionnaireMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>;
export const SubmitQuestionnaireDocument = gql`
    mutation submitQuestionnaire($submitQuesstionnaire: QuestionnaireSubmittion!) {
  submitQuesstionnaire(input: $submitQuesstionnaire) {
    completed
    id
    status
    answers {
      id
      value
    }
  }
}
    `;
export type SubmitQuestionnaireMutationFn = Apollo.MutationFunction<SubmitQuestionnaireMutation, SubmitQuestionnaireMutationVariables>;

/**
 * __useSubmitQuestionnaireMutation__
 *
 * To run a mutation, you first call `useSubmitQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitQuestionnaireMutation, { data, loading, error }] = useSubmitQuestionnaireMutation({
 *   variables: {
 *      submitQuesstionnaire: // value for 'submitQuesstionnaire'
 *   },
 * });
 */
export function useSubmitQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<SubmitQuestionnaireMutation, SubmitQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitQuestionnaireMutation, SubmitQuestionnaireMutationVariables>(SubmitQuestionnaireDocument, options);
      }
export type SubmitQuestionnaireMutationHookResult = ReturnType<typeof useSubmitQuestionnaireMutation>;
export type SubmitQuestionnaireMutationResult = Apollo.MutationResult<SubmitQuestionnaireMutation>;
export type SubmitQuestionnaireMutationOptions = Apollo.BaseMutationOptions<SubmitQuestionnaireMutation, SubmitQuestionnaireMutationVariables>;
export const CreateStateDocument = gql`
    mutation createState($createStateInput: CreateStateInput!) {
  createState(input: $createStateInput) {
    name
    id
    status
  }
}
    `;
export type CreateStateMutationFn = Apollo.MutationFunction<CreateStateMutation, CreateStateMutationVariables>;

/**
 * __useCreateStateMutation__
 *
 * To run a mutation, you first call `useCreateStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStateMutation, { data, loading, error }] = useCreateStateMutation({
 *   variables: {
 *      createStateInput: // value for 'createStateInput'
 *   },
 * });
 */
export function useCreateStateMutation(baseOptions?: Apollo.MutationHookOptions<CreateStateMutation, CreateStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStateMutation, CreateStateMutationVariables>(CreateStateDocument, options);
      }
export type CreateStateMutationHookResult = ReturnType<typeof useCreateStateMutation>;
export type CreateStateMutationResult = Apollo.MutationResult<CreateStateMutation>;
export type CreateStateMutationOptions = Apollo.BaseMutationOptions<CreateStateMutation, CreateStateMutationVariables>;
export const GetPaymentTerminalDocument = gql`
    query getPaymentTerminal {
  getPaymentTerminal
}
    `;

/**
 * __useGetPaymentTerminalQuery__
 *
 * To run a query within a React component, call `useGetPaymentTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentTerminalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentTerminalQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentTerminalQuery, GetPaymentTerminalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentTerminalQuery, GetPaymentTerminalQueryVariables>(GetPaymentTerminalDocument, options);
      }
export function useGetPaymentTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentTerminalQuery, GetPaymentTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentTerminalQuery, GetPaymentTerminalQueryVariables>(GetPaymentTerminalDocument, options);
        }
export type GetPaymentTerminalQueryHookResult = ReturnType<typeof useGetPaymentTerminalQuery>;
export type GetPaymentTerminalLazyQueryHookResult = ReturnType<typeof useGetPaymentTerminalLazyQuery>;
export type GetPaymentTerminalQueryResult = Apollo.QueryResult<GetPaymentTerminalQuery, GetPaymentTerminalQueryVariables>;
export const GetStatesDocument = gql`
    query getStates {
  getStates {
    name
    id
    status
  }
}
    `;

/**
 * __useGetStatesQuery__
 *
 * To run a query within a React component, call `useGetStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatesQuery(baseOptions?: Apollo.QueryHookOptions<GetStatesQuery, GetStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatesQuery, GetStatesQueryVariables>(GetStatesDocument, options);
      }
export function useGetStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatesQuery, GetStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatesQuery, GetStatesQueryVariables>(GetStatesDocument, options);
        }
export type GetStatesQueryHookResult = ReturnType<typeof useGetStatesQuery>;
export type GetStatesLazyQueryHookResult = ReturnType<typeof useGetStatesLazyQuery>;
export type GetStatesQueryResult = Apollo.QueryResult<GetStatesQuery, GetStatesQueryVariables>;
export const UpdateStateDocument = gql`
    mutation updateState($id: String!, $updateStateInput: UpdateStateInput!) {
  updateState(id: $id, input: $updateStateInput) {
    status
    id
    name
  }
}
    `;
export type UpdateStateMutationFn = Apollo.MutationFunction<UpdateStateMutation, UpdateStateMutationVariables>;

/**
 * __useUpdateStateMutation__
 *
 * To run a mutation, you first call `useUpdateStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStateMutation, { data, loading, error }] = useUpdateStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateStateInput: // value for 'updateStateInput'
 *   },
 * });
 */
export function useUpdateStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStateMutation, UpdateStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStateMutation, UpdateStateMutationVariables>(UpdateStateDocument, options);
      }
export type UpdateStateMutationHookResult = ReturnType<typeof useUpdateStateMutation>;
export type UpdateStateMutationResult = Apollo.MutationResult<UpdateStateMutation>;
export type UpdateStateMutationOptions = Apollo.BaseMutationOptions<UpdateStateMutation, UpdateStateMutationVariables>;
export const DeleteStateDocument = gql`
    mutation deleteState($id: String!) {
  deleteState(id: $id)
}
    `;
export type DeleteStateMutationFn = Apollo.MutationFunction<DeleteStateMutation, DeleteStateMutationVariables>;

/**
 * __useDeleteStateMutation__
 *
 * To run a mutation, you first call `useDeleteStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStateMutation, { data, loading, error }] = useDeleteStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStateMutation, DeleteStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStateMutation, DeleteStateMutationVariables>(DeleteStateDocument, options);
      }
export type DeleteStateMutationHookResult = ReturnType<typeof useDeleteStateMutation>;
export type DeleteStateMutationResult = Apollo.MutationResult<DeleteStateMutation>;
export type DeleteStateMutationOptions = Apollo.BaseMutationOptions<DeleteStateMutation, DeleteStateMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($createCompanyInput: CreateCompanyInput!) {
  createCompany(input: $createCompanyInput) {
    name
    id
    pdfMapping
    states {
      name
    }
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      createCompanyInput: // value for 'createCompanyInput'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const GetCompaniesDocument = gql`
    query getCompanies {
  getCompanies {
    name
    id
  }
}
    `;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
      }
export function useGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const GetCompanyDocument = gql`
    query getCompany($id: String!) {
  getCompanyById(id: $id) {
    name
    id
    states {
      name
      status
      id
    }
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
      }
export function useGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetCompaniesOfStateDocument = gql`
    query getCompaniesOfState($stateId: String!) {
  getCompaniesOfState(stateId: $stateId) {
    name
    id
  }
}
    `;

/**
 * __useGetCompaniesOfStateQuery__
 *
 * To run a query within a React component, call `useGetCompaniesOfStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesOfStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesOfStateQuery({
 *   variables: {
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useGetCompaniesOfStateQuery(baseOptions: Apollo.QueryHookOptions<GetCompaniesOfStateQuery, GetCompaniesOfStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesOfStateQuery, GetCompaniesOfStateQueryVariables>(GetCompaniesOfStateDocument, options);
      }
export function useGetCompaniesOfStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesOfStateQuery, GetCompaniesOfStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesOfStateQuery, GetCompaniesOfStateQueryVariables>(GetCompaniesOfStateDocument, options);
        }
export type GetCompaniesOfStateQueryHookResult = ReturnType<typeof useGetCompaniesOfStateQuery>;
export type GetCompaniesOfStateLazyQueryHookResult = ReturnType<typeof useGetCompaniesOfStateLazyQuery>;
export type GetCompaniesOfStateQueryResult = Apollo.QueryResult<GetCompaniesOfStateQuery, GetCompaniesOfStateQueryVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($id: String!, $updateCompanyInput: UpdateCompanyInput!) {
  updateCompany(id: $id, input: $updateCompanyInput) {
    id
    name
    pdfMapping
    states {
      name
    }
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateCompanyInput: // value for 'updateCompanyInput'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($id: String!) {
  deleteCompany(id: $id)
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const GetPlansDocument = gql`
    query getPlans {
  getPlans {
    id
    annualPrice
    monthPrice
    info
    minInsurances
    maxInsurances
  }
}
    `;

/**
 * __useGetPlansQuery__
 *
 * To run a query within a React component, call `useGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetPlansQuery, GetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, options);
      }
export function useGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlansQuery, GetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, options);
        }
export type GetPlansQueryHookResult = ReturnType<typeof useGetPlansQuery>;
export type GetPlansLazyQueryHookResult = ReturnType<typeof useGetPlansLazyQuery>;
export type GetPlansQueryResult = Apollo.QueryResult<GetPlansQuery, GetPlansQueryVariables>;
export const MakeInsurancesPaymentDocument = gql`
    mutation makeInsurancesPayment($createPayment: CreatePaymentInput!) {
  createPayment(input: $createPayment) {
    id
    status
  }
}
    `;
export type MakeInsurancesPaymentMutationFn = Apollo.MutationFunction<MakeInsurancesPaymentMutation, MakeInsurancesPaymentMutationVariables>;

/**
 * __useMakeInsurancesPaymentMutation__
 *
 * To run a mutation, you first call `useMakeInsurancesPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeInsurancesPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeInsurancesPaymentMutation, { data, loading, error }] = useMakeInsurancesPaymentMutation({
 *   variables: {
 *      createPayment: // value for 'createPayment'
 *   },
 * });
 */
export function useMakeInsurancesPaymentMutation(baseOptions?: Apollo.MutationHookOptions<MakeInsurancesPaymentMutation, MakeInsurancesPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeInsurancesPaymentMutation, MakeInsurancesPaymentMutationVariables>(MakeInsurancesPaymentDocument, options);
      }
export type MakeInsurancesPaymentMutationHookResult = ReturnType<typeof useMakeInsurancesPaymentMutation>;
export type MakeInsurancesPaymentMutationResult = Apollo.MutationResult<MakeInsurancesPaymentMutation>;
export type MakeInsurancesPaymentMutationOptions = Apollo.BaseMutationOptions<MakeInsurancesPaymentMutation, MakeInsurancesPaymentMutationVariables>;
export const GetPlansForInsurancePaymentDocument = gql`
    query getPlansForInsurancePayment($insurances: [String!]!) {
  getPlansForInsurancePayment(insurances: $insurances) {
    id
    annualPrice
    monthPrice
    info
    minInsurances
    maxInsurances
  }
}
    `;

/**
 * __useGetPlansForInsurancePaymentQuery__
 *
 * To run a query within a React component, call `useGetPlansForInsurancePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansForInsurancePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansForInsurancePaymentQuery({
 *   variables: {
 *      insurances: // value for 'insurances'
 *   },
 * });
 */
export function useGetPlansForInsurancePaymentQuery(baseOptions: Apollo.QueryHookOptions<GetPlansForInsurancePaymentQuery, GetPlansForInsurancePaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlansForInsurancePaymentQuery, GetPlansForInsurancePaymentQueryVariables>(GetPlansForInsurancePaymentDocument, options);
      }
export function useGetPlansForInsurancePaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlansForInsurancePaymentQuery, GetPlansForInsurancePaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlansForInsurancePaymentQuery, GetPlansForInsurancePaymentQueryVariables>(GetPlansForInsurancePaymentDocument, options);
        }
export type GetPlansForInsurancePaymentQueryHookResult = ReturnType<typeof useGetPlansForInsurancePaymentQuery>;
export type GetPlansForInsurancePaymentLazyQueryHookResult = ReturnType<typeof useGetPlansForInsurancePaymentLazyQuery>;
export type GetPlansForInsurancePaymentQueryResult = Apollo.QueryResult<GetPlansForInsurancePaymentQuery, GetPlansForInsurancePaymentQueryVariables>;