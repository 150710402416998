import axios, { AxiosRequestConfig } from "axios";
import TokenService from "./token.service";
 
const API_URL = process.env.REACT_APP_API_URL;  
const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  }, 
  withCredentials: true 
});

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers = config.headers ?? {}; 
    if (!config.headers['Authorization'] && config.url!=="/user/login" && !config.url?.startsWith("/user/register")&& !config.url?.startsWith("/user/resend-verification-email")) {
      console.log(TokenService.getLocalAccessToken());
        config.headers['Authorization'] = `Bearer ${TokenService.getLocalAccessToken()}`;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
   // console.log(res); 
    return res;
  },
  async (err) => {
    console.log(err);
    const originalConfig = err.config;
    if (originalConfig.url == "/user/login" && (err.response.status === 401 || err.response.status === 500)) {
      return JSON.parse('{"data": "'+err.response.data.error+'" }');
    }
    else if (originalConfig.url !== "/user/register" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true; 
        console.log("Access Token was expired");
        try { 
          const rs = await instance.get("/User/refresh-token/"+ TokenService.getLocalRefreshToken(),{ withCredentials: true });
          const { accessToken } = rs.data;
          console.log(accessToken);
          TokenService.updateLocalAccessToken(accessToken);
          originalConfig.headers['Authorization'] = `Bearer ${accessToken}`;
          return instance(originalConfig);
        } catch (_error: any) {
          if (_error.response.status === 500 || _error.response.status === 400) {
            window.location.href ='/login';
          }
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;