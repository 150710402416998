import { useParams } from "react-router-dom";
import { useGetQuestionnaireByIdQuery } from "../../../datasources/graphql/client-types";
import { EditableQuestion } from "./editableQuestion";
import { Loader } from "../../../components/common/loader";

export default function EditQuestionnaire({
  questionnaireId,
}: {
  questionnaireId?: string;
}) {
  const { qId } = useParams();
  const questionnaireIdValue = qId || questionnaireId || "";
  // const [questionnaireQuestionsResponses, setQuestionnaireQuestionsResponses] =

  const {
    data: questionnaireData,
    loading,
    error,
  } = useGetQuestionnaireByIdQuery({
    variables: {
      id: questionnaireIdValue,
    },
  });

  if (loading) {
    return <Loader />;
  }

  // if (error) {
  //   Swal.showValidationMessage(`${error}`);
  // }

  console.log(questionnaireData?.getQuestionnaireById);

  return (
    <div className="w-full bg-background py-5 px-8 min-h-ScreenWithNavbar flex justify-center items-center">
      <div className="px-4 py-4 bg-onBackground w-full rounded-2xl flex flex-col max-w-screen-sm">
        <p className="mb-4">Edit Questionnaire</p>

        <div className="flex flex-col items-stretch w-full">
          {questionnaireData?.getQuestionnaireById.questions.map((question) => (
            <EditableQuestion question={question} key={question.id} />
          ))}
        </div>
      </div>
    </div>
  );
}
