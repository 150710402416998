import React from "react";

export const CredentialingSubmitContext = React.createContext<{submitting: boolean, setSubmitting: any}>({
  submitting: false,
  setSubmitting: () => null,
});

export function CredentialingSubmitProvider({ children }: { children: React.ReactNode }) {
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const value = { submitting, setSubmitting };

  return (
    <CredentialingSubmitContext.Provider value={value}>
      {children}
    </CredentialingSubmitContext.Provider>
  );
}
