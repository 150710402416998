import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import useAuth from "../../hooks/useAuth";
import { useState, useEffect } from "react";
import userService from "../../Services/user.service";
import { setCommentRange } from "typescript";



export default function ConfirmRegistration() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [confirmationText, setConfirmationText] = useState('');
    const [showLink, setShowLink] = useState(false);

    useEffect(()=>{
       doConfirmation();
    },[]); 

    const doConfirmation = async()=>{
        let registrationCode = searchParams.get("registrationCode");
        if (registrationCode == null) {
            registrationCode = "";
        }
        console.log(registrationCode);
        let email = searchParams.get("email");
        if (email == null) {
            email = "";
        }
        console.log(email);
        if (await userService.ConfirmRegistration(registrationCode, email)) {
            setShowLink(true);
            setConfirmationText("Your email has been confirmed!");
        }
        else {
            setShowLink(false);
            setConfirmationText("There was a problem confirming your email.  Please try again later.");   
        }
    }

  return (
    <section className="flex flex-col md:flex-row h-screen items-center">
      <div className="bg-white hidden lg:block w-full md:w-1/2 xl:w-1/2 h-screen">
        <img
          src="/assets/images/login.png"
          alt="Login"
          className="w-full h-full object-cover rounded-br-[20%]"
        />
      </div>

      <div
        className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12
          flex items-center justify-center"
      >
        <div className="w-full h-100">
          <h1 className="text-base font-bold text-textColor-normal">
            {confirmationText}
          </h1>
          <h1 className="text-2xl md:text-2xl font-bold leading-tight my-6">
          {showLink && <Link className="text-2xl md:text-2xl font-bold leading-tight my-6 text-blue-600"
            to="/login"
            
          >
            Click here to sign in to Credental
            </Link> }
          </h1>

       </div>
      </div>
    </section>
  );
}
