import { useEffect, useState } from 'react'
import { Tabs, TabsHeader, TabsBody, Tab } from '@material-tailwind/react'
import Table from '../../../../src/components/common/agGrid'
import CreateInsuranceRequest from './CreateInsuranceRequest'
import insuranceServices from '../../../Services/insurance.services'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import UpdateStatusDialog from './UpdateStatusDialog'
import credentialServices from '../../../Services/credential.services'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import constants from '../../../constants/constants'

const InsuranceRequestList = (props: any) => {
  const [isOpen, setIsOpen] = useState(false)

  const [insurnceRequest, setInsuranceRequest] = useState([
    {
      customer: '',
      state: '',
      status: '',
      date: 0,
      credentialRequestId: 0,
    },
  ])
  const visbility = ''
  const navigate = useNavigate()
  const stateManager = () => {
    setIsOpen(false)
    setIsUpdateStatusModalOpen(false)
  }
  const [modal, setModal] = useState(false)
  const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState(false)
  const [data, setData] = useState({ status: '', credentialRequestId: '' })

  useEffect(() => {
    getInsuaranceRequestData('all')
    setModal(true)
  }, [])

  const getInsuaranceRequestData = async (value: any) => {
    try {
      setModal(false)
      const result = await insuranceServices.GetAdminInsuranceRequest()
      if (value == 'all') {
        result?.dashboardItems?.map((item: any) => {
          if (item.date != null) {
            item.date = moment(item.date, 'YYYYMMDD').format('MMM DD,YYYY')
          }
        })
        var resultAll = result.dashboardItems
        setInsuranceRequest(resultAll)
        setModal(true)
      } else if (value == 'pending') {
        var dashBoardData = result.dashboardItems?.filter((items: { status: string }) => items.status == 'Started')
        dashBoardData.map((item: any) => {
          if (item.date != null) {
            item.date = moment(item.date, 'YYYYMMDD').format('MMM DD,YYYY')
          }
        })
        setInsuranceRequest(dashBoardData)
        setModal(true)
      } else if (value == 'paymentApproved') {
        // Need to change filer status later
        var dashBoardData = result.dashboardItems?.filter(
          (items: { status: string }) => items.status == 'FeedbackRequested',
        )
        dashBoardData.map((item: any) => {
          if (item.date != null) {
            item.date = moment(item.date, 'YYYYMMDD').format('MMM DD,YYYY')
          }
        })
        setInsuranceRequest(dashBoardData)
        setModal(true)
      } else if (value == 'changesRequested') {
        var dashBoardData = result.dashboardItems?.filter(
          (items: { status: string }) => items.status == 'FeedbackRequested',
        )
        dashBoardData.map((item: any) => {
          if (item.date != null) {
            item.date = moment(item.date, 'YYYYMMDD').format('MMM DD,YYYY')
          }
        })
        setInsuranceRequest(dashBoardData)
        setModal(true)
      }
    } catch (error) {
      throw error
    }
  }

  const columns = [
    { headerName: 'Customer', field: 'customer' },
    { headerName: 'State', field: 'state' },
    { headerName: 'Status', field: 'status' },
    { headerName: 'Date', field: 'date' },
    {
      headerName: 'Credential Request Id',
      field: 'credentialRequestId',
      cellRenderer: (credentialRequestId: any) => (
        <a href="#" role="button">
          {credentialRequestId.value}
        </a>
      ),
      cellStyle: { color: 'blue', 'text-decoration': 'underline' },
    },
    {
      headerName: 'View Insurance Requests',
      field: 'insuranceRequests',
      // valueFormatter: (params: { value: string; }) => {
      //   return 'View';
      // },
      cellRenderer: (credentialRequestId: any) => (
        <a href="#" role="button">
          View
        </a>
      ),
      cellStyle: { color: 'red', 'text-decoration': 'underline' },
    },
    {
      headerName: 'Edit',
      field: 'edit',
      cellRenderer: (credentialRequestId: any) => (
        <a href="#" role="button">
          Edit Status
        </a>
      ),
      cellStyle: { color: 'red', 'text-decoration': 'underline' },
    },
  ]

  const getSelectedCustomerData = (data: any) => {
    if (data.column.colId == 'credentialRequestId') {
      localStorage.setItem('credentialrequestid', JSON.stringify(data.data))
      navigate('/insuranceChangeRequest')
    } else if (data.column.colId == 'insuranceRequests') {
      localStorage.setItem('credentialrequestid', JSON.stringify(data.data))
      navigate('/viewInsuranceRequestsStatus')
    } else if (data.column.colId == 'edit') {
      setData(data.data)
      setIsUpdateStatusModalOpen(true)
    } else {
      navigate('/createInsurance', { state: { ...data.data, isAdmin: true } })
    }
  }
  const updateCredentialRequestStatus = async (status: any) => {
    const payload = { status: status }
    const result = await credentialServices.UpdateCredentialStatus(data?.credentialRequestId, payload)
    if (result) {
      getInsuaranceRequestData('all')
      toast.success('Status Updated successfully', { position: 'top-center' })
    } else {
      toast.error('Status Updation Failed', { position: 'top-center' })
    }
    stateManager()
  }
  return (
    <div>
      <div className="mx-auto px-4 sm:px-8 flex-grow">
        <div className="py-5">
          <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
            <h1 className="text-2xl leading-tight text-3xl">{props.heading}</h1>
          </div>
          <p>{props.subTitle}</p>
        </div>
      </div>
      {/* <hr /> */}
      <div>
        <Tabs value="html">
          <TabsHeader>
            {props.data?.map(({ label, value }: any) => (
              <Tab
                key={value}
                value={value}
                onClick={() => {
                  getInsuaranceRequestData(value)
                }}
              >
                {`${label}`}
              </Tab>
            ))}
          </TabsHeader>
          {/* {!modal ? */}

          {modal ? (
            <TabsBody>
              <Table rowData={insurnceRequest} columns={columns} getOnclickCellData={getSelectedCustomerData} />
            </TabsBody>
          ) : (
            <p>Loading...</p>
          )}

          {
            <CreateInsuranceRequest
              isOpen={isOpen}
              stateManager={stateManager}
              heading={'Create new Insurance Request'}
              description={
                'You are about to create a new insurance request, after selecting your state we will ask for the neccessory information in a few simple steps.'
              }
              buttonName={'Create Insurance Request'}
              requireName={true}
            />
          }
          {/* } */}
          <UpdateStatusDialog
            isOpen={isUpdateStatusModalOpen}
            stateManager={stateManager}
            data={data}
            //getInsuaranceRequestStatusData={getInsuaranceRequestData}
            currentStatus={data.status}
            submitMethod={updateCredentialRequestStatus}
            statusOptions={constants.credentialRequestStatusOptions}
          />
        </Tabs>
      </div>
      <ToastContainer />
    </div>
  )
}

export default InsuranceRequestList
