// store/sidebarStore.ts
import { makeAutoObservable } from "mobx";

class SidebarStore {
  canCreateNewRequest = false;

  constructor() {
    makeAutoObservable(this);
  }

  setCanCreateNewRequest(value: boolean) {
    this.canCreateNewRequest = value;
  }
}

export const sidebarStore = new SidebarStore();
