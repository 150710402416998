import { useEffect, useState, Fragment } from 'react'
import statesService from '../../../Services/states.service'
import { Dialog, Transition } from '@headlessui/react'
import { Button, Input } from '@material-tailwind/react'
import { useFormik } from 'formik'
import Multiselect from 'multiselect-react-dropdown'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as yup from 'yup'
import insuranceServices from '../../../Services/insurance.services'

interface EditFormValues {
  id: number
  name: string
  states: any[]
  phone: string
  mapFile: string
}

const EditInsuranceCompany = (props: any) => {
  const [state, setState] = useState([{ name: '', abbreviation: '', id: '' }])
  const { id, name, mapFile, phone, states } = props.data

  const initialValues: EditFormValues = {
    id: id || 0,
    name: name || '',
    states: states || [],
    phone: phone || '',
    mapFile: mapFile || '',
  }

  useEffect(() => {
    const getStateList = async () => {
      const result = await statesService.GetStates()
      setState(result)
    }
    getStateList()
  }, [])

  var statesList = props.data.states
  var statesArray = new Array()
  statesArray = statesList?.split(',')
  let ddlStates: any[] = []

  statesArray?.map((item: any) => {
    const selectedStates = state?.find((stt) => stt.name == item)
    ddlStates.push(selectedStates)
  })

  //const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const phoneRegExp = /^.+$/
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required('Name is required')
      .min(3, 'Name must be at least 3 characters')
      .max(50, 'Name cannot exceed 50 characters')
      .matches(/^.+$/, 'Enter a valid name'),
    phone: yup
      .string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(10, 'too short'),
    mapFile: yup.string().required('Map file is required'),
    states: yup.array().required('Plaese select state').min(1, 'Pick at least 1 state'),
  })

  const formik = useFormik<EditFormValues>({
    initialValues: {
      id: props.data.id,
      name: props.data.name,
      states: ddlStates,
      phone: props.data.phone,
      mapFile: props.data.mapFile,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      editInsuranceCompany(values)
      formik.resetForm()
    },
    enableReinitialize: true, // Enable form reinitialization when props change
  })

  function closeModal() {
    props.stateManager()
  }
  const editInsuranceCompany = async (values: any) => {
    let payload = {
      name: values.name,
      phone: values.phone,
      mapFile: values.mapFile,
      states: values.states,
    }
    try {
      const result = await insuranceServices.PutInsuranceCompany(values.id, payload)

      if (result) {
        toast.success('Insurance company updated successfully', { position: 'top-center' })
        props.GetInsuranceCompanies()
        closeModal()
      }
    } catch (error) {
      toast.error('Something went wrong', { position: 'top-center' })
      throw error
    }
  }

  const { heading, description, buttonName } = props
  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <form onSubmit={formik.handleSubmit}>
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        <h1 className="font-medium leading-tight text-2xl mt-0 mb-2 text-black-600">{heading}</h1>
                        <div className="relative p-6 flex-auto">
                          <p className="my-4 text-slate-500 text-lg leading-relaxed">{description}</p>
                          <div className="mb-5">
                            <label className="text-sm font-bold text-gray-700">Name</label>
                            <Input
                              id="name"
                              type="text"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="name"
                              placeholder="Enter name"
                              error={formik.touched.name && Boolean(formik.errors.name)}
                            />
                            {formik.touched.name && formik.errors.name && (
                              <p className="text-red-500 text-sm mt-1">{formik.errors.name}</p>
                            )}
                          </div>
                          <div className="mb-5">
                            <label className="text-sm font-bold text-gray-700">Phone</label>
                            <Input
                              id="phone"
                              type="text"
                              value={formik.values.phone}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="phone"
                              placeholder="Enter phone"
                              error={formik.touched.phone && Boolean(formik.errors.phone)}
                            />
                            {formik.touched.phone && formik.errors.phone && (
                              <p className="text-red-500 text-sm mt-1">{formik.errors.phone}</p>
                            )}
                          </div>
                          <div className="mb-5">
                            <label className="text-sm font-bold text-gray-700">Mapfile</label>
                            <Input
                              id="mapFile"
                              type="text"
                              value={formik.values.mapFile}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="mapFile"
                              placeholder="Enter mapFile"
                              error={formik.touched.mapFile && Boolean(formik.errors.mapFile)}
                            />
                            {formik.touched.mapFile && formik.errors.mapFile && (
                              <p className="text-red-500 text-sm mt-1">{formik.errors.mapFile}</p>
                            )}
                          </div>
                          <div className="mb-5">
                            <label className="text-sm font-bold text-gray-700">States</label>
                            <Multiselect
                              options={state}
                              onSelect={(e) => formik.setFieldValue('states', e)}
                              onRemove={(e) => formik.setFieldValue('states', e)}
                              displayValue="name"
                              placeholder="Company State"
                              className="text-sm"
                              selectedValues={formik.values.states}
                            />
                            {formik.touched.states && formik.errors.states && (
                              <p className="text-red-500 text-sm mt-1">{formik.errors.states}</p>
                            )}
                          </div>
                        </div>
                      </Dialog.Title>
                    </Dialog.Title>

                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <div className="modal-footer">
                        <Button className="flex items-center" type="submit">
                          Edit
                        </Button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </form>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ToastContainer />
    </div>
  )
}

export default EditInsuranceCompany
