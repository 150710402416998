import { StyledBoolQuestion } from "../../../components/common/checkBoxQuestion";
import { StyledFastField } from "../../../components/common/styledFastField";
import { QuestionParams } from "../../../models/questionnaire";

export default function GenericForm({ fields, requiredFields = true }: { fields: QuestionParams[], requiredFields?: boolean }) {
  function isRequiredValidation(value: string) {
    let error;
    if (!value) {
      error = "This question is required";
    }
    return error;
  }

  return (
    <>
      {fields.map((field) => {
        if (field.type === "dualBoolean") {
          return (
            <StyledBoolQuestion
              key={field.id}
              label={field.name}
              name={field.id}
              validate={field.isRequired && requiredFields && isRequiredValidation}
              isRequired={requiredFields &&field.isRequired}
            />
          );
        } else {
          return (
            <StyledFastField
              key={field.id}
              label={field.name}
              type={field.type}
              name={field.id}
              selectValues={field.dropDownAnswers}
              validate={field.isRequired && requiredFields && isRequiredValidation}
              isRequired={requiredFields &&field.isRequired}
            />
          );
        }
      })}
    </>
  );
}
