import { ErrorMessage, Field } from "formik";
import CustomErrorMessage from "../../../components/common/customFIeldErrorMessage";

export default function InsuranceForm({
  insuranceOptions,
}: {
  insuranceOptions: { label: string; value: string }[];
}) {
  return (
    <>
      {insuranceOptions.map((insurance, index) => {
        return (
          <label className="w-full my-2" key={index}>
            <div className="flex justify-between">
              <span className="block text-gray-700">{insurance.label}</span>

              <Field
                type="checkbox"
                name={insurance.value}
                // className="px-4 py-3 rounded-lg bg-textFieldBackground/[.15] mt-2 border focus:border-blue-500 focus:bg-textFieldBackground/[.30] focus:outline-none"
                // checked:bg-blue-600 appearance-none border-gray-300
                className="h-8 w-8 border rounded-sm bg-white border-blue-300 checked:border-blue-600 focus:outline-none transition duration-200 align-top  cursor-pointer"
              />
            </div>
            <ErrorMessage name={insurance.value}>
              {(msg) => <CustomErrorMessage message={msg} />}
            </ErrorMessage>
            <hr className="my-4" />
          </label>
        );
      })}
    </>
  );
}
