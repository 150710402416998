import {
  CheckIcon,
  PlusCircleIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import ReactSelect, { ActionMeta, SingleValue } from "react-select";
import Swal from "sweetalert2";
import {
  CreateQuestionInput,
  QuestionType,
} from "../../../datasources/graphql/client-types";

const questionTypesToSelectArray = Object.values(QuestionType).map((value) => ({
  label: value,
  value,
}));

export function AddQuestion({
  handleAddQuestion,
  finishAdd,
}: {
  handleAddQuestion: (question: CreateQuestionInput) => void;
  finishAdd: () => void;
}) {  
  const [questionName, setQuestionName] = useState<string>("");
  const [position, setPosition] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [friendlyQuestionName, setFriendlyID] = useState<string>("");
  const [questionTypeValue, setQuestionTypeValue] = useState<QuestionType>(
    QuestionType.String
  );
  const [questionIsRequired, setQuestionIsRequired] = useState<boolean>(false);
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [newSelectOption, setNewSelectOption] = useState<string>("");

  function handleChange(
    newValue: SingleValue<{
      label: QuestionType;
      value: QuestionType;
    }>,
    actionMeta: ActionMeta<{
      label: QuestionType;
      value: QuestionType;
    }>
  ) {
    setQuestionTypeValue(newValue?.value || QuestionType.String);
  }

  function cancelEdit() {
    setQuestionName("");
    setQuestionTypeValue(QuestionType.String);
    setSelectOptions([]);
    setNewSelectOption("");
    finishAdd();
  }

  async function submitQuestion() {
    try {
      const result = await Swal.fire({
        icon: "question",
        title: "Are you sure?",
        html: "Do you want to add this question",
        showCancelButton: true,
      });

      if (!result.isConfirmed) {
        return;
      }

      const input = {
        name: questionName,
        friendlyId: friendlyQuestionName,
        type: questionTypeValue,
        isRequired: questionIsRequired,
        extras: "extras",
        position: position,
        requireStorage: "false",
        rowType: "invalid",
        page: page,
        dropDownAnswers: selectOptions.length > 0 ? selectOptions : undefined,
      };

      console.log(position);

      handleAddQuestion(input);

      await Swal.fire({ icon: "success", title: "Question added" });
      finishAdd();
    } catch (error) {
      Swal.fire({ icon: "error", title: "Error", html: `${error}` });
    }
  }

  function handleQuestionNameChange(event: any) {
    setQuestionName(event.target.value);
  }

  function handleFriendlyNameChange(event: any){
    setFriendlyID(event.target.value);
  }

  function handlePositionChange(event: any){
    setPosition(parseInt(event.target.value) ? parseInt(event.target.value) : 0);
  }
  function handlePageChange(event: any){
    setPage(parseInt(event.target.value) ? parseInt(event.target.value) : 0);
  }

  function handleIsRequiredChange() {
    setQuestionIsRequired((prev) => !prev);
  }

  function isDropdown() {
    return questionTypeValue === "select";
  }

  function createNewDropDownOption() {
    setSelectOptions((prev) => [...prev, newSelectOption]);
    setNewSelectOption("");
    return;
  }

  function handleNewOptionChange(event: any) {
    setNewSelectOption(event.target.value);
  }

  function deleteSelectOption(index: number) {
    setSelectOptions((prev) =>
      prev.filter((value, valueIndex) => index !== valueIndex)
    );
  }

  return (
    <div className="flex justify-between w-full px-6 py-4 bg-background rounded-xl mb-4 min-w[300px]">
      <div className="w-full">
        <div className="flex flex-col">
          <div className="flex justify-between">
            <input value={questionName} placeholder={"Question"} onChange={handleQuestionNameChange}/> 
            <ReactSelect
              defaultValue={{
                label: QuestionType.String,
                value: QuestionType.String,
              }}
              placeholder={"Select the type of the question"}
              isClearable
              options={questionTypesToSelectArray}
              onChange={handleChange}
              name="questionType"
            />
            <input value={friendlyQuestionName} placeholder={"Friendly ID"}  onChange={handleFriendlyNameChange} />
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                checked={questionIsRequired}
                onChange={handleIsRequiredChange}
                name="isRequired"
              />{" "}
              <span> is Required  </span>
            </label>
            {isDropdown() && (
              <div>
                {selectOptions.map((option, index) => (
                  <div key={index} className="flex justify-between">
                    <span>{option}</span>
                    <button
                      onClick={() => deleteSelectOption(index)}
                      className="w-[20px] relative"
                    >
                      <TrashIcon color={"red"} />
                    </button>
                  </div>
                ))}
                <div className="flex">
                  <input
                    placeholder="Create new option"
                    onChange={handleNewOptionChange}
                    value={newSelectOption}
                  />
                  <button
                    onClick={createNewDropDownOption}
                    className="w-[20px] relative"
                  >
                    <PlusCircleIcon color="blue" />
                  </button>
                </div>
              </div>
            )}
          </div>
          <div>
          <label>
              <span> Position:  </span>
            </label>
            <input value={position} placeholder={"Pos"} onChange={handlePositionChange} /> 
          </div>
          <div>
          <label>
              <span> Page:  </span>
            </label>
            <input value={page} placeholder={"Page"} onChange={handlePageChange} /> 
          </div>
        </div>

        <div className="flex w-full justify-end">
          <button className="mr-2 w-[24px] relative" onClick={submitQuestion}>
            <CheckIcon color="green" />
          </button>
          <button onClick={cancelEdit} className="w-[24px] relative">
            <XIcon color="red" />
          </button>
        </div>
      </div>
    </div>
  );
}
