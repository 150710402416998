import apiClient from './api'
class PaymentService {
      async GetIntellipayWidget() {
        try {
          const response = await apiClient.get(`/Payment/intellipay-widget`)        
          return { success: true, data: response.data };
        } catch (err) {
          return {success: false}
          console.log(err)
        }
      }
      async PostPayment(postData: any) {
        try {
          const response = await apiClient.post(`/Payment`, postData)
          return response.data
        } catch (err) {
          console.log(err)
        }
      }
      async ScheduleNextPayment(paymentId: number) {
        try {
            let postData = {
                paymentId: paymentId
              }
          const response = await apiClient.post(`/Payment/schedule`, postData)
          return response.data
        } catch (err) {
          console.log(err)
        }
      }
}


export default new PaymentService()