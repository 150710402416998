import { ErrorMessage, FastField, FieldAttributes } from "formik";
import CustomErrorMessage from "./customFIeldErrorMessage";

export const StyledFastField = (props: FieldAttributes<any>) => {
  const { label, selectValues, isRequired, ...restOfProps } = props;

  return (
    <label className="w-full my-2">
      <span className="block text-gray-700">{props.label} {isRequired && <span className="text-red-500">*Required</span>}</span>
      {props.type === "select" ? (
        <FastField
          as="select"
          className="w-full px-4 py-3 rounded-lg bg-textFieldBackground/[.15] mt-2 border focus:border-blue-500 focus:bg-textFieldBackground/[.30] focus:outline-none"
          {...restOfProps}
        >
          {props.selectValues.map((value: string, index: number) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </FastField>
      ) : (
        <FastField
          {...restOfProps}
          as={restOfProps.type === "textarea" ? "textarea" : "input"}
          className="w-full px-4 py-3 rounded-lg bg-textFieldBackground/[.15] mt-2 border focus:border-blue-500 focus:bg-textFieldBackground/[.30] focus:outline-none"
        />
      )}
      <ErrorMessage name={restOfProps.name}>
        {(msg) => <CustomErrorMessage message={msg} />}
      </ErrorMessage>
    </label>
  );
};
