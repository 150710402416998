import { Input, Button, Checkbox } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { H3, P, Link} from "../../components/typography/typography";
import { AtSymbolIcon, LockClosedIcon } from '@heroicons/react/solid'
import { Roles, useGetUserLazyQuery } from "../../datasources/graphql/client-types";
import useAuth from "../../hooks/useAuth";
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup' 
import credentialService from '../../Services/credential.services';
import userService from "../../Services/user.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
interface FormSigIn {
  email: string
  password: string
}

export default function LoginForm() {
  const [errorMessage, setErrorMessage] = useState('');
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const navigate = useNavigate();
  const auth = useAuth();

  const schema = yup.object().shape({
    email: yup.string().email('Email format is invalid').required('Email is required'),
    password: yup.string().required('password is required'),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormSigIn>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  const watchFields = watch()

  useEffect(() => {
    errors.email = undefined
    errors.password = undefined
    setErrorMessage('')
  },[watchFields])

  function resendEmail() {
    userService.ResendConfirmationEmail(emailAddress);
    toast.success("Check your inbox for instructions to verify your email.", {
      position: toast.POSITION.TOP_RIGHT});
  }

  async function onSubmit() {
    try {
      
      const email = watchFields.email
      const password = watchFields.password
      setEmailAddress(email);

      const user = await auth.signin({ email, password }); 

      //If instead of a user, we received an error message, show that as a toast message
      if(typeof user === 'string' || user instanceof String){
        toast.error(user,{
          position: toast.POSITION.TOP_RIGHT});
      }
      else{
        if (user?.role === Roles.Admin || user?.role === Roles.PracticeAdmin || user?.role=="0")
        {
          navigate("/questionnairesAdmin", { replace: true });
        } else {
          let url = await credentialService.GetNextPage();
          navigate("/"+url, { replace: true });
          //navigate("/landingpage", { replace: true });
        }
      }
    
    } catch (error) {
      //If the error came back just as an error message, then display the error message.  Otherwise, it was probably a connection issue
      if(typeof error === 'string' || error instanceof String){
        if (error.toString().startsWith("You need to verify your email address before logging in")) {
          setShowSendEmail(true);
        }
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT});
          setErrorMessage(error.toString())
        }
        else {
          toast.error("Connection error has occurred!", {
            position: toast.POSITION.TOP_RIGHT});
          setErrorMessage('Connection error has occurred')
        }
      
      console.log(error)
    }
  }

  return (
    <div>
  <div className="w-full text-center">
  {showSendEmail && <Button onClick={() => resendEmail()}>Click here to resend the email </Button>}</div>
  
    <section className="flex flex-col md:flex-row items-center">
    {!showSendEmail &&
      <div className="bg-white w-full flex items-center justify-center">
        <div className="w-full text-center">
          {/* <H3 className="mb-3">
            Hello Again!
          </H3> */}
          <P>Welcome to your credentialing experience.  Login here with your account.</P>
          <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <Input
                label="Email"
                className="mb-5"
                icon={ <AtSymbolIcon className="text-blue-600"/> }
                {...register('email')}
              />
              <Input
                type="password"
                label="Password"
                className="mb-5"
                icon={ <LockClosedIcon className="text-blue-600"/> }
                {...register('password')}
              />
              <div className="flex justify-between items-center flex-wrap">
                <Checkbox label="Remember me" className="" containerProps={{className: "-ml-3"}} />
                <Link to="/forgot" className="ml-2">
                  Forgot your password?
                </Link>
              </div>
              <Button type="submit">Login</Button>
              {errors.email?.message && <P className="text-red-600">{errors.email?.message}</P>}
              {errors.password?.message && <P className="text-red-600">{errors.password?.message}</P>}
              {errorMessage && <P className="text-red-600">{errorMessage}</P>}
              
            </div>
            
          </form>
          
        </div>
      </div>
    }
    <ToastContainer />
    </section>
    </div>
  );
}

