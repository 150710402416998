class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem("user")!);
      return user?.refreshTokenGuid;
    }
  
    getLocalAccessToken() {
      const user = JSON.parse(localStorage.getItem("user")!);
      return user?.accessToken;
    }
  
    updateLocalAccessToken(token: any) {
      let user = JSON.parse(localStorage.getItem("user")!);
      user.accessToken = token;
      console.log(user.accessToken);
      localStorage.setItem("user", JSON.stringify(user));
    }
  
    getUser() {
        const res=localStorage.getItem("user");
       // console.log(res);
        if(res != "undefined"){
            return JSON.parse(localStorage.getItem("user")!);
        }
    }
  
    setUser(user: any) {
      //console.log(JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
    }
  
    removeUser() {
      localStorage.removeItem("user");
    }
  }
  
  export default new TokenService();