import { Dialog, Transition } from '@headlessui/react'
import { Button, Input } from '@material-tailwind/react'
import { useEffect, useState, Fragment } from 'react'
import * as yup from 'yup'
import { P } from '../../../components/typography/typography'
import { useNavigate } from 'react-router-dom'
import statesService from '../../../Services/states.service'
import insuranceServices from '../../../Services/insurance.services'
import Multiselect from 'multiselect-react-dropdown'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useFormik } from 'formik'

const CreateInsuranceCompanyDialog = (props: any) => {
  const [showModal, setShowModal] = useState(true)
  const [selectedState, setSelectedState] = useState([])
  const [stateId, setStateId] = useState('')
  const [state, setState] = useState([{ name: '', abbreviation: '', id: '' }])
  const [errorMessage, setErrorMessage] = useState('')
  const [insuranceCompany, setInsuranceCompany] = useState()
  const navigate = useNavigate()

  const initialValues = {
    name: '',
    stateId: [],
    phoneNo: '',
    mapFile: '',
  }
  //const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const phoneRegExp = /^.+$/
  const schema = yup.object().shape({
    name: yup
      .string()
      .required('Name is required')
      .min(3, 'Name must be at least 3 characters')
      .max(50, 'Name cannot exceed 50 characters')
      .matches(/^.+$/, 'Enter a valid name'),
    phoneNo: yup.string().required('required').matches(phoneRegExp, 'Phone number is not valid').min(10, 'too short'),
    mapFile: yup.string().required('Map file is required'),
    stateId: yup.array().required('Please select State').min(1, 'Pick at least 1 state'),
  })
  let timer: any

  const { values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      console.log(values)
      createInsuranceCompany(values)
      resetForm()
    },
  })

  function closeModal() {
    props.stateManager()
    resetForm()
  }

  useEffect(() => {
    const getStateList = async () => {
      const result = await statesService.GetStates()
      setState(result)
    }
    getStateList()
  }, [])

  const toasterDidMount = () => {
    timer = setTimeout(() => toast.success('Company added successfully', { position: 'top-center' }), 3000)
  }

  const toasterWillUnmount = () => {
    clearTimeout(timer)
  }
  const createInsuranceCompany = async (values: any) => {
    let objInsuranceCompany = {
      name: values.name,
      phone: values.phoneNo,
      mapFile: values.mapFile,
      states: values.stateId,
    }
    console.log(objInsuranceCompany)
    try {
      const result = await insuranceServices.CreateInsuranceCompany(objInsuranceCompany)

      if (result) {
        props.GetInsuranceCompanies()
      }
      toasterDidMount()
      closeModal()
      //toasterWillUnmount();
    } catch (error) {
      toast.error('Something went wrong', { position: 'top-center' })
      throw error
    }
  }

  const { heading, description, buttonName, requireName, requirePhoneNo, requireMapFile } = props
  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <form className="mt-6" onSubmit={handleSubmit}>
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      <h1 className="font-medium leading-tight text-2xl mt-0 mb-2 text-black-600">{heading}</h1>
                      <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">{description}</p>
                        {requireName && (
                          <div className="mb-4">
                            <Input
                              label="Name"
                              className="mb-5"
                              required={true}
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              //onBlur={handleBlur}
                            />
                            {errors?.name && <P className="text-red-600">{errors.name}</P>}
                          </div>
                        )}
                        {requirePhoneNo && (
                          <div className="mb-4">
                            <Input
                              label="Phone Number"
                              className="mb-5"
                              required={true}
                              name="phoneNo"
                              value={values.phoneNo}
                              onChange={handleChange}
                              //onBlur={handleBlur}
                            />
                            {errors?.phoneNo && <P className="text-red-600">{errors.phoneNo}</P>}
                          </div>
                        )}
                        {requireMapFile && (
                          <div className="mb-4">
                            <Input
                              label="Map File"
                              className="mb-5"
                              required={true}
                              name="mapFile"
                              value={values.mapFile}
                              onChange={handleChange}
                              //onBlur={handleBlur}
                            />
                            {errors?.mapFile && <P className="text-red-600">{errors.mapFile}</P>}
                          </div>
                        )}
                        <div className="">
                          <Multiselect
                            options={state}
                            onSelect={(e) => setFieldValue('stateId', e)}
                            onRemove={(e) => setFieldValue('stateId', e)}
                            displayValue="name"
                            placeholder="Company State"
                            className="text-sm"
                          ></Multiselect>
                          {errors?.stateId && <P className="text-red-600">{errors.stateId}</P>}
                        </div>
                      </div>
                      {/*footer*/}
                    </Dialog.Title>

                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <div className="modal-footer">
                        <Button
                          className="flex items-center"
                          type="submit"
                          onClick={() => {
                            closeModal
                          }}
                        >
                          {buttonName}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            />
                          </svg>
                        </Button>

                        {errorMessage && <P className="text-red-600">{errorMessage}</P>}
                      </div>
                    </div>
                  </Dialog.Panel>
                </form>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ToastContainer />
    </div>
  )
}
export default CreateInsuranceCompanyDialog
