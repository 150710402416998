import { Input, Button } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { H3, P } from '../../components/typography/typography'
import { AtSymbolIcon, LockClosedIcon, UserCircleIcon } from '@heroicons/react/solid'
import useAuth from '../../hooks/useAuth'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import userService  from "../../Services/user.service";
import credentialService from '../../Services/credential.services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface FormSigUp {
  firstName: string
  lastName: string
  email: string
  pass: string
  confirmPass: string
}

export default function SignUpForm() {
  const [errorMessage, setErrorMessage] = useState('')
  const [isRegistered, setIsRegistered] = useState(false)
  const navigate = useNavigate()
  const auth = useAuth()

  let schema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup.string().email('Email format is invalid').required('Email is required'),
    pass: yup.string().min(6, 'Must contain at least 6 characters').required('password is required'),
    confirmPass: yup.string().required('Confirm password is required').oneOf([yup.ref('pass'), null], 'Password and Confirm Password must match')
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormSigUp>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  const watchFields = watch()

  useEffect(() => {
    errors.firstName = undefined
    errors.lastName = undefined
    errors.email = undefined
    errors.pass = undefined
    errors.confirmPass = undefined
    setErrorMessage('')
  }, [watchFields])

  async function onSubmit() {
    try {
      const firstName = watchFields.firstName
      const lastName = watchFields.lastName
      const email = watchFields.email
      const pass = watchFields.pass

      // const user = await getUser({
      //   variables: {
      //     email,
      //   },
      // })

      // if(user) {
      //   setErrorMessage('email is already registered')
      // }
      const signUpPayLoad = JSON.stringify({ firstName:  firstName,lastName:lastName,email:email,password: pass });

      const result = await userService.RegisterUser(signUpPayLoad);
      // await auth.signup({
      //   firstName,
      //   LastName,
      //   email,
      //   pass,
      //   role: [],
      // })
 // console.log(result);
    if(result=="email already exists")
    {
      toast.error("Sign up failed, Email already exists!",{
        position: toast.POSITION.TOP_RIGHT});
      // Swal.fire({
      //   title: 'Failure',
      //   icon: 'error',
      //   html: 'Email already exists!',
      // })
    }
    else{
      toast.success("Account created successfully! Please check your email for instructions to verify your account.",{
        position: toast.POSITION.TOP_RIGHT});
        setIsRegistered(true);
    }
      
    } catch (error: any) {
      toast.error("Connection error has occurred!",{
        position: toast.POSITION.TOP_RIGHT});
      setErrorMessage('Connection error has occurred')
      console.log('e', error)
    }
  }

  return (
    <section className="flex flex-col md:flex-row items-center">
      {!isRegistered &&
      <div className="bg-white w-full flex items-center justify-center">
        <div className="w-full text-center">
          {/* <H3 className="mb-3">
            Get Started
          </H3> */}
          <P>Follow a few short steps to get started on your credentialing journey.</P>

          <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <Input
                label="First Name"
                className="mb-5"
                icon={<UserCircleIcon className="text-blue-600" />}
                tabIndex={1}
                {...register('firstName')}
              />
                <Input
                label="Last Name"
                className="mb-5"
                icon={<UserCircleIcon className="text-blue-600" />}
                tabIndex={1}
                {...register('lastName')}
              />
              <Input
                label="Email"
                className="mb-5"
                icon={<AtSymbolIcon className="text-blue-600" />}
                tabIndex={3}
                {...register('email')}
              />
              <Input
                label="Password"
                type="password"
                className="mb-5"
                icon={<LockClosedIcon className="text-blue-600" />}
                tabIndex={4}
                {...register('pass')}
              />
              <Input
                label="Confirm Password"
                type="password"
                className="mb-5"
                icon={<LockClosedIcon className="text-blue-600" />}
                tabIndex={4}
                {...register('confirmPass')}
              />
              <Button type="submit" tabIndex={5}>
                Sign Up
              </Button>
              {errors.firstName?.message && <P className="text-red-600">{errors.firstName?.message}</P>}
              {errors.lastName?.message && <P className="text-red-600">{errors.lastName?.message}</P>}
              {errors.email?.message && <P className="text-red-600">{errors.email?.message}</P>}
              {errors.pass?.message && <P className="text-red-600">{errors.pass?.message}</P>}
              {errors.confirmPass?.message && <P className="text-red-600">{errors.confirmPass?.message}</P>}
              {errorMessage && <P className="text-red-600">{errorMessage}</P>}
            </div>
            <ToastContainer />
          </form>
        </div>
      
      </div>
      }
      {isRegistered &&      
      <div className="bg-white w-full flex items-center justify-center">
        <div className="w-full text-center">
        Account created successfully! Please check your email for instructions to verify your account.
        </div>
      </div>
}
    </section>
  )
}