import { Button} from '@material-tailwind/react'
import { Tabs, TabsHeader, TabsBody, Tab } from '@material-tailwind/react'
import React, { useState, Fragment, useEffect } from 'react'
import questionnaireServices from '../../../Services/questionnaire.services'
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QuestionsContainer from './QuestionsContainer';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { getYupSchemaFromMetaData } from './yupSchemaCreator';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import credentialService from '../../../Services/credential.services';
import constants from '../../../constants/constants';
import {useLocation} from 'react-router-dom';
import ChangeRequestDialog from '../../NewAdmin/ManageInsuranceCompany/ChangeRequestDialog';
import { Roles } from '../../../datasources/graphql/client-types';

type LocationState = {
  customer: {
    path: string;
  },
  credentialRequestId :{
    path : number;
  }, 
  // admin: {
  //   path: boolean;
  // }
}
type FormValues = {
  [key: string]: string; 
};

const validateInput = (value: any, validationRegex: any) => {
  const regex = new RegExp(validationRegex);
  if (!_.isEmpty(validationRegex) && !regex.test(value)) {
    return 'Invalid input';
  }
  return undefined;
};


const validate = (values : FormValues, postedQuestion : any) => {
  const errors: { [key: string]: string } = {};
  postedQuestion.forEach((question : any) => {
    const fieldName = question?.id.toString();
    const fieldValue = values[fieldName];
    const validationRegex = question?.validationRegex;
    const error = validateInput(fieldValue, validationRegex);
    if (error) {
      errors[fieldName] = error;
    }
  });
  return errors;
};

function InsuranceContainer() {
  const location = useLocation();
  const [modal, setModal] = useState(true)
  const [postedQuestions, setPostedQuestions] = useState([])
  const [steps, setSteps] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [iscreateStep, setIsCreateStep] = useState(false);
  const [isPostQuestion, setIsPostQuestion] = useState(false);
  const [disablePreviousButton, setDisablePreviousButton] = useState(false)
  const [disableNextButton, setDisableNextButton] = useState(false)
  const [currentStepOrder, setCurentStepOrder] = useState('1');
  const [changeRequestOpen, setChangeRequestOpen] = useState(false)
  const [questionForChangeRequest, setQuestionForChangeRequest] = useState({})
  const [isAdmin, setIsAdmin] = useState(false);
  const [dentistEmail, setDentistEmail] = useState<any>('');
  const [dentistCredentialRequestId, setDentistCredentialRequestId] = useState<any>()
  const [currentStep, setCurrentStep] = useState({name:'',id:'',order:'1',status:''})
  const [currentStepName, setCurrentStepName] = useState('');
  const questionnaireLocalStorage = JSON.parse(localStorage.getItem("questionnaireData")!);
  const loggedInUserData = JSON.parse(localStorage.getItem("user")!);

  const setDataForAdmin =() =>{
    if(loggedInUserData?.user?.role == Roles.Admin ||loggedInUserData?.user?.role == Roles.PracticeAdmin || loggedInUserData?.user?.role == "0"){
      setIsAdmin(true)
      const {customer,credentialRequestId} = location.state as LocationState;
      setDentistEmail(customer);
      setDentistCredentialRequestId(credentialRequestId);
    }
  }
  
  const navigate = useNavigate();
  let currentStepId: any;

  const styles = {
    root: {
      margin: 0,
      width: '100%',
      padding: 4
    },
    leftColumn: {
      //backgroundColor: 'green',
      marginTop: "10px",
      alignItems: 'center',
    },
    rightColumn: {
      marginTop: "10px",
      alignItems: 'center',
      display: 'block',
    },
    // createStepBox: {
    //   alignContent: 'center',
    //   alignItems: 'center',
    //   alignText: 'center',
    //   marginLeft: "30px",
    //   padding: "20px",
    //   height: '150px',
    //   width: "300px",
    //   margin: '10px'
    // },
    createStepBox: {
      alignContent: 'center',
      alignItems: 'center',
      alignText: 'center',
      padding:'3%',
      width: "100%",
    },
    questionDisplayBox: {

      width: '100%',
      border: '1px solid #DEDEDE',
    },
    submitButton: {
      width : "100%",
      marginBottom: '1%'
    },
    navigationButton: {
      display: 'flex',
      //alignContent :'right'
    },
    inline:{
      display: 'flex',
      justifyContent: "center",
      alignItems: "center"
    },
    loadingIcon :{
      paddingLeft : '50%'
    },
    stepCompleted :{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    stepPending: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor:'orange',
      color: 'white',
      width: '30px',
      height: '30px',
      marginRight: '5px',
      borderRadius: '50%',
    },
    stepInProgress: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      color: 'blue',
    },
    checkmark: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '30px',
      height: '30px',
      marginRight: '5px',
      borderRadius: '50%',
      backgroundColor: 'green',
      color: 'white',
    }
  }
  const getPostedQuestion = (postedQuestion: any) => {
    setPostedQuestions(postedQuestion)
    console.log("createQuestionnaire", postedQuestions)
  }
  const goBack = () => {
    navigate(-1); 
  };
  const data = [
    {
      label: 'Questionnaire',
      value: 'all',
    },
    // {
    //   label: 'Change Requests',
    //   value: 'changeRequests',
    // },
  ]
  const visibility = ''
  let questionnaireSteps;
  let insuranceSteps;
  let questions;
  const setLocalStorage = async (step: any) => {
    let postedQuestion;
    localStorage.setItem("currentStep", JSON.stringify(step));
    const currentStepData = JSON.parse(localStorage.getItem("currentStep")!)
    setCurrentStep(currentStepData)
    setCurrentStepName(currentStepData?.name)
    if(loggedInUserData?.user?.role == Roles.Admin ||loggedInUserData?.user?.role == Roles.PracticeAdmin || loggedInUserData?.user?.role == "0"){
       postedQuestion = await questionnaireServices.GetAdminCredentialRequestQuestions(dentistCredentialRequestId, currentStepData?.order)
    }
    else{
       postedQuestion = await questionnaireServices.GetInsuranceQuestions(currentStepData?.order)
    }
    currentStepId = step.id;
    setPostedQuestions(_.get(postedQuestion, 'data', []))
    setCurentStepOrder(step?.order)
    // step?.order === 1 ? setDisablePreviousButton(true) : setDisablePreviousButton(false)
    // step?.order === steps.length ? setDisableNextButton(true) : setDisableNextButton(false)

  }
  const getQuestion = async () => {
    try {
      questionnaireSteps = await questionnaireServices.GetQuestionnaireSteps(questionnaireLocalStorage?.id)
      setSteps(_.get(questionnaireSteps, 'steps', []))
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  useEffect( () => {
    const  getQuestion = async () => {
      try {
        localStorage.setItem("currentStep", JSON.stringify(currentStep));
        if(loggedInUserData?.user?.role == Roles.Admin ||loggedInUserData?.user?.role == Roles.PracticeAdmin || loggedInUserData?.user?.role == "0"){
          setIsAdmin(true)
          const {customer,credentialRequestId} = location.state as LocationState;
          console.log({customer,credentialRequestId})
          setDentistEmail(customer);
          setDentistCredentialRequestId(credentialRequestId);
          insuranceSteps =  await questionnaireServices.GetAdminCredentialRequestSteps(dentistCredentialRequestId)
          questions = await questionnaireServices.GetAdminCredentialRequestQuestions(dentistCredentialRequestId, 1)
         }
         else {
          insuranceSteps =  await questionnaireServices.GetInsuranceSteps() 
          questions =  await questionnaireServices.GetInsuranceQuestions(1)
         }
         setSteps(_.get(insuranceSteps, 'data', []));
         setLocalStorage(insuranceSteps?.data?.[0])
         setCurrentStepName(_.get(insuranceSteps, 'data[0].name',''));
         setPostedQuestions(_.get(questions, 'data', []))
        // insuranceSteps =  isAdmin ? await questionnaireServices.GetAdminCredentialRequestSteps(dentistCredentialRequestId)
        // :await questionnaireServices.GetInsuranceSteps() 
        // setSteps(_.get(insuranceSteps, 'data', []));
        // setLocalStorage(insuranceSteps?.data?.[0])
        // setCurrentStepName(_.get(insuranceSteps, 'data[0].name',''));
        // questions = isAdmin ? await questionnaireServices.GetAdminCredentialRequestQuestions(dentistCredentialRequestId, 1)
        // : await questionnaireServices.GetInsuranceQuestions(1)
        // setPostedQuestions(_.get(questions, 'data', []))
      } catch (error) {
        console.log(error)
        throw error
      }
    }
    getQuestion()
  }, [isAdmin])

  function openCreateStep() {
    setIsCreateStep(true)
  }
  const previousStep = async () => {
    const currentStepData = JSON.parse(localStorage.getItem("currentStep")!)
    console.log({ currentStepData, steps })
    steps.map((step:any) => {
      if(currentStepData?.order === step?.order +  1){
        console.log(step)
        setLocalStorage(step)
      }
    })
  }
  function nextStep() {
    const currentStepData = JSON.parse(localStorage.getItem("currentStep")!)
    console.log({ currentStepData, steps })
    steps.map((step:any) => {
      if(currentStepData?.order === step?.order -  1){
        console.log(step)
        setLocalStorage(step)
      }
    })
  }
  const selectedQuestionForReview = (question :any)=>{
    setQuestionForChangeRequest(question)
  }

  const togglePopUp = () => {
   setChangeRequestOpen(!changeRequestOpen)
  }
  const answerQuestioinsSchema = getYupSchemaFromMetaData(postedQuestions, [], []);
  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
    },
    onSubmit: values => {
      submitAnswers(values, postedQuestions);

      //alert(JSON.stringify(values, null, 2));
    }, 
    validate: (values) => validate(values, postedQuestions),
    validationSchema: answerQuestioinsSchema
  });
  const submitAnswers = async (values: any, postedQuestion: any) => {
    let payload: any[] = []
    postedQuestion.map((question: any) => {
      const isMultiSelectDropdown = question?.questionType === constants?.questionTypes?.multiSelectDropdown && Object.prototype.toString.call(values[`${question?.id}`]) === '[object Array]'
      let answerObject = {
        QuestionId: question?.id,
        Answer: isMultiSelectDropdown ? values[`${question?.id}`].join(',') : values[`${question?.id}`] || ''
        
      }
      payload.push(answerObject)
    })
    try {
      
      const result = await questionnaireServices.SubmitAnswers(payload, currentStepOrder)
      console.log(result)
      if(result?.status ===  200){
        toast.success( `${currentStep.name} responses submitted successfully`,
        { position: "top-center" })
        formik.resetForm({values : ''});
        insuranceSteps =  await questionnaireServices.GetInsuranceSteps();
        setSteps(_.get(insuranceSteps, 'data', []));
        if(steps.length > parseInt(currentStep.order)){
          steps.map((step: any) => {
            if(currentStep.order + 1 === step.order){
              setLocalStorage(step)
            }
          })}
          else{
            //last step
            const result = await credentialService.CompleteQuestionnaire();
            if(result){
            toast.success( `Questinnaire Submitted successfully`,
            { position: "top-center" })
             let url = await credentialService.GetNextPage();
             navigate("/"+url, { replace: true });
          }
          else{
            toast.error( `Questinnaire Submission Failed`,
            { position: "top-center" })
          }
        }
      }
      else {
        toast.error("Response could not be submitted",
        { position: "top-center" })
      }
    }
    catch (error) {
      console.log(error)
      toast.error("Response could not be submitted",
        { position: "top-center" })
      throw error
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <div className="mx-auto px-4 sm:px-0 flex-grow">
        {/* <div className="py-5" style={{ border: '1px solid red' }}> */}
        {/* <div
            className="flex flex-row mb-1 sm:mb-0 justify-between w-full"
            style={{ border: '1px solid green', display: 'inline' }}
          > */}
        <h1 className="text-2xl leading-tight text-3xl">
          <div style={{ display: 'inline-block' }}>
            {isAdmin && (
              <button onClick={goBack} className="full-left px-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                </svg>
              </button>
            )}
          </div>
          {!isAdmin ? 'Credentialing Questionnaire' : 'Order'}
        </h1>
        <p className="px-18">{!isAdmin ? 'Begin your credentialing journey here' : dentistEmail}</p>
        {/* </div> */}
        {/* </div> */}
      </div>
      <Tabs value="html">
        <TabsHeader>
          {data.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => {
                setModal(false)
              }}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>

        <TabsBody>
          <div className="grid grid-cols-10 gap-5">
            {steps?.length > 0 ? (
              <div className="col-span-3 w-full" style={styles.leftColumn}>
                {steps?.map((step: any) => (
                  <button
                    onClick={() => {
                      setLocalStorage(step)
                    }}
                    style={styles.createStepBox}
                  >
                    <div
                      className={`max-w-sm rounded bg-blue-${
                        step?.order == currentStepOrder ? `800` : `300`
                      } overflow-hidden shadow-lg w-full`}
                    >
                      <div className="px-6 py-4 w-full flex items-center" style={styles.inline}>
                        <div className="font-bold text-xl mb-2 text-white flex-grow">{step?.name}</div>
                        {step?.status === 'Completed' && (
                            <a
                            href="#"
                            className="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                            data-te-toggle="tooltip"
                            title="Step Completed"
                            >
                          <div style={styles.stepCompleted}>
                            <span style={styles.checkmark}>&#10003;</span>
                          </div>
                          </a>
                        )}
                        {step?.status === 'In Progress' && (
                           <a
                           href="#"
                           className="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                           data-te-toggle="tooltip"
                           title="Step In Progress"
                           >
                          <div style={styles.stepInProgress}>
                            <span style={styles.stepPending}>X</span>
                          </div>
                          </a>
                        ) }
                        {step?.status === 'Pending' && (
                           <a
                           href="#"
                           className="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                           data-te-toggle="tooltip"
                           title="Step In Progress"
                           >
                          <div style={styles.stepInProgress}>
                            <span style={styles.stepPending}>X</span>
                          </div>
                          </a>
                        )}
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            ) : (
              <div className="col-span-3 w-full" style={styles.leftColumn}>
                <div style={styles.loadingIcon}>
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              </div>
            )}
            <div className="col-span-7 w-full" style={styles.rightColumn}>
              <div>
                <div style={styles.inline}>
                  {/* <div >
                    <Button
                      disabled={disablePreviousButton}
                      onClick={() => previousStep()}
                      //style={styles.navigationButton}
                    >
                      <ArrowNarrowLeftIcon width={20} />
                    </Button>
                  </div> */}
                  <div>{!_.isEmpty(currentStepName) ? currentStepName : '' || ''}</div>
                  {/* <div >
                    <Button 
                    disabled={disableNextButton} 
                    onClick={() => nextStep()} 
                    style={styles.navigationButton}>
                      <ArrowNarrowRightIcon width={20} />
                    </Button>
                  </div> */}
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div style={styles.questionDisplayBox}>
                  <div className=" rounded-lg bg-white shadow-lg">
                    <div className="p-6 flex flex-col justify-start">
                      <QuestionsContainer
                        postedQuestion={postedQuestions}
                        formik={formik}
                        isAdmin={isAdmin}
                        togglePopUp={togglePopUp}
                        selectedQuestionForReview={selectedQuestionForReview}
                      />
                    </div>
                    <br />
                    {!isAdmin && (
                      <Button type="submit" style={styles.submitButton}>
                        {currentStep.order == steps.length.toString() ? 'Submit' : 'Next'}
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </TabsBody>
      </Tabs>
      <ToastContainer />
      <ChangeRequestDialog
        isOpen={changeRequestOpen}
        togglePopUp={togglePopUp}
        questionForChangeRequest={questionForChangeRequest}
        credentialRequestId={dentistCredentialRequestId}
      />
    </div>
  )
}

export default InsuranceContainer
