import { ReactNode } from 'react'
import { H3, P } from '../components/typography/typography'
import { Button, Card, CardHeader } from '@material-tailwind/react'
import { PlusCircleIcon } from '@heroicons/react/solid'
import useAuth from '../hooks/useAuth'
import { Roles } from '../datasources/graphql/client-types'
import { Outlet } from 'react-router-dom'
import TopBar from '../components/navigation/topbar'
import SideBar from '../components/navigation/sidebar'

type Props = {
  children?: ReactNode
}

const MainPage = ({ children }: Props) => {
  return (
    <>
      <div className="flex w-full">
        <SideBar />
        <div className="min-h-screen max-h-screen flex-grow flex flex-col">
          <TopBar />
          <div className="bg-grey-back flex-grow overflow-auto p-10">
            <Outlet />
          </div>
        </div>
      </div>

      {/* <div className="w-full flex justify-between items-center mb-10">
        <div>
          <H3 className="mb-2">Insurace Requests</H3>
          <P className="text-bluish-grey w-96">
            Fusce gravida laoreet elit, non condimentum quam ultrices eget. In massa quam, semper vel enim vel.
          </P>
        </div>
        <div>
          <Button variant="filled" size="lg">
            New Insurance Request
          </Button>
        </div>
      </div>
      <div className="w-full flex gap-8">
        <div className="w-60 bg-white p-5 rounded-lg border-2 border-transparent h-80 shrink-0">Card</div>
        <div className="w-60  p-5 rounded-lg border-2 border-grey-300 border-dashed cursor-pointer flex justify-center items-center">
          <div className="text-grey-300 flex flex-col items-center text-center">
            <PlusCircleIcon className="mb-5" width={80} />
            <p>NEW INSURANCE REQUEST</p>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default MainPage
