import { Button, Checkbox, Input, Option, Select, Textarea } from '@material-tailwind/react'
import React, { useState, Fragment, useEffect } from 'react'
import questionnaireServices from '../../../Services/questionnaire.services'
import { Dialog, Transition } from '@headlessui/react'
import _ from 'lodash'
import * as Yup from 'yup'
import { P } from '../../../components/typography/typography'
import { useFormik } from 'formik'
import constants from '../../../constants/constants'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const initialValues = {
  label: '',
  questionType: '',
  helpText: '',
  required: false,
  answerOptions: '',
  friendlyId: '',
  regex: '',
}
const postQuestionSchema = Yup.object({
  label: Yup.string()
    .required('Label is required')
    .min(1, 'Label must be at least 1 character')
    .max(1000, 'Label cannot exceed 1000 characters'),
  questionType: Yup.string().required('Please select a Question Type'),
  friendlyId: Yup.string().required('Friendly ID  is required').min(3, 'Friendly ID must be at least 3 characters'),
})
const ManageQuestionDialog = (props: any) => {
  const UpdatedInitialValues = !props?.isUpdateQuestion ? initialValues : props?.initialValuesForQuestion
  const [question, setQuestion] = useState([])
  //const [questionnaire, setQuestionnaire] = useState()
  //const [checkbox, setCheckbox] = useState(false)
  //const [questionType, setQuestionType] = useState('');
  const [inputFields, setInputFields] = useState([
    {
      fullName: '',
    },
  ])
  const [inputValues, setInputValues] = useState<any>([])
  const [addOptions, setAddOptions] = useState(false)

  const { values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: UpdatedInitialValues,
    //initialValues: initialValues,
    validationSchema: postQuestionSchema,
    onSubmit: (values) => {
      !props?.isUpdateQuestion ? postQuestion(values) : updateQuestion(values)
      resetForm()
      closeModal()
    },
  })

  const questionTypeHandle = (question: any) => {
    setFieldValue('questionType', question)
    // setQuestionType(question)
    if (question == 'Single-Select Dropdown' || question == 'Multi-Select Dropdown') {
      setAddOptions(true)
    } else {
      setAddOptions(false)
    }
  }

  useEffect(() => {
    const getQuestionTypes = async () => {
      try {
        const result = await questionnaireServices.GetQuestionTypes()
        setQuestion(result)
      } catch (error) {
        console.log(error)
        throw error
      }
    }
    getQuestionTypes()
  }, [])
  //const currentStepData = JSON.parse(localStorage.getItem("currentStep")!)
  const postQuestion = async (values: any) => {
    const currentStepData = JSON.parse(localStorage.getItem('currentStep')!)
    const postQuestionPayload = [
      {
        questionType: values?.questionType,
        prompt: values?.label,
        friendlyId: values?.friendlyId,
        Label: values?.label,
        required: values?.required,
        HelpText: values?.helpText,
        answerOptions: values?.answerOptions,
        validationRegex: values?.regex,
      },
    ]
    try {
      const result = await questionnaireServices.PostQuestion(
        props.questionnaireLocalStorage.id,
        currentStepData?.id,
        postQuestionPayload,
      )
      props?.getPostedQuestion()
      //setQuestionnaire(result)
      if (result) {
        toast.success('Question was added successfully', { position: 'top-center' })
      } else {
        toast.error('Please select a step and re-try', { position: 'top-center' })
      }
    } catch (error) {
      console.log(error)
      toast.error('Question adding failed', { position: 'top-center' })
      throw error
    }
  }
  const updateQuestion = async (values: any) => {
    const currentStepData = JSON.parse(localStorage.getItem('currentStep')!)
    console.log({ values })
    const postQuestionPayload = [
      {
        id: props?.questionOrderAndId?.id,
        questionType: values?.questionType,
        prompt: values?.label,
        friendlyId: values?.friendlyId,
        Label: values?.label,
        required: values?.required,
        HelpText: values?.helpText,
        answerOptions: values?.answerOptions,
        //order : props?.questionOrderAndId?.order,
        order: values?.order,
        validationRegex: values?.regex,
      },
    ]
    try {
      const result = await questionnaireServices.EditQuestion(
        props.questionnaireLocalStorage?.id,
        currentStepData?.id,
        postQuestionPayload,
      )
      if (result) {
        props?.getPostedQuestion()
        toast.success('Question was updated successfully', { position: 'top-center' })
      } else {
        toast.error('Question updation was failed', { position: 'top-center' })
      }
      //setQuestionnaire(result)
    } catch (error) {
      toast.error('Something went wrong', { position: 'top-center' })
      console.log(error)
      throw error
    }
  }

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        fullName: '',
      },
    ])
    console.log(inputFields)
  }
  const removeInputFields = (index: any) => {
    const rows = [...inputFields]
    rows.splice(index, 1)
    setInputFields(rows)
  }
  const handleInputChange = (event: any, index: any) => {
    const values = [...inputValues]
    values[index] = event.target.value
    setInputValues(values)
  }
  function closeModal() {
    props.stateManager()
    resetForm()
  }
  return (
    <div>
      <Transition appear show={props.isPostQuestion || props.isUpdateQuestion} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <form className="mt-6" onSubmit={handleSubmit}>
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-3xl font-semibold">
                          {!props?.isUpdateQuestion
                            ? constants.buttonNames.addQuestion
                            : constants.buttonNames.updateQuestion}
                        </h3>
                      </div>
                    </Dialog.Title>

                    <div className="relative p-6 flex-auto">
                      <p className="my-4 text-slate-500 text-lg leading-relaxed">
                        Set the parameters of the question you want to add
                      </p>
                      <div className="dropdown mb-4">
                        <Select
                          label="Question Type"
                          // onChange={questionTypeHandle}
                          value={values.questionType}
                          //onChange={setFieldValue("questiontype",value)}
                          onChange={(value) => {
                            questionTypeHandle(value)
                          }}
                        >
                          {question?.map((question, index) => (
                            <Option value={question}>{question}</Option>
                          ))}
                        </Select>
                        {errors?.questionType ? (
                          <P className="text-red-600">{errors.questionType as React.ReactChild}</P>
                        ) : null}
                      </div>
                      <div className="mb-4">
                        <Input
                          label="Label"
                          className="mb-5"
                          required={true}
                          name="label"
                          value={values.label}
                          onChange={handleChange}
                          //onBlur={handleBlur}
                        />
                        {errors?.label ? <P className="text-red-600">{errors?.label as React.ReactChild}</P> : null}
                      </div>
                      <div className="mb-4">
                        <Textarea
                          label="Help Text"
                          className="mb-5"
                          required={false}
                          name="helpText"
                          value={values.helpText}
                          onChange={handleChange}
                          //onBlur={handleBlur}
                        />
                        {errors?.helpText ? (
                          <P className="text-red-600">{errors.helpText as React.ReactChild}</P>
                        ) : null}
                      </div>
                      <div>
                        {(addOptions ||
                          (props?.isUpdateQuestion &&
                            (props?.initialValuesForQuestion?.questionType ===
                              constants?.questionTypes?.singleSelectDropdown ||
                              props?.initialValuesForQuestion?.questionType ===
                                constants?.questionTypes?.multiSelectDropdown))) && (
                          // <div>
                          //   <Input type="text" className="mb-5"
                          //   name="dynamicOptions"
                          //   value={values.dynamicOptions} />
                          //   {(inputFields.length !== 1) ? <button className="btn btn-outline-danger" onClick={removeInputFields}>x</button> : ''}
                          // </div>
                          <div>
                            <Textarea
                              label="Answer Options"
                              className="mb-5"
                              required={true}
                              name="answerOptions"
                              value={values.answerOptions}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="
                                    Please enter your options separated by commas (e.g., option1, option2, option3)."
                            />
                          </div>
                        )}
                      </div>
                      {/* <div>
                        {addOptions &&
                          <div>
                            <button className="btn btn-outline-success " onClick={addInputField}>Add Options</button>
                          </div>
                        }
                      </div> */}
                      <div className="mb-4">
                        <Input
                          label="Friendly ID"
                          className="mb-5"
                          required={true}
                          name="friendlyId"
                          value={values.friendlyId}
                          onChange={handleChange}
                          //onBlur={handleBlur}
                        />
                        {errors?.friendlyId ? (
                          <P className="text-red-600">{errors?.friendlyId as React.ReactChild}</P>
                        ) : null}
                      </div>
                      {props?.isUpdateQuestion && (
                        <Select
                          label="Select the order"
                          value={values.order}
                          onChange={(value) => {
                            setFieldValue('order', value)
                          }}
                        >
                          {props?.postedQuestions?.map((question: any) => (
                            <Option value={question?.order}>{question?.order}</Option>
                          ))}
                        </Select>
                      )}
                      <div className="mb-4">
                        <Input
                          label="Regular Expression"
                          className="mb-5"
                          required={false}
                          name="regex"
                          value={values.regex}
                          onChange={handleChange}
                          //onBlur={handleBlur}
                        />
                      </div>
                      <div className="mb-4">
                        <Checkbox
                          label="Make this question required"
                          onChange={(e) => {
                            setFieldValue('required', e.target.checked)
                          }}
                          defaultChecked={values.required}
                          name="required"
                        />
                      </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <div className="modal-footer">
                        <Button
                          className="flex items-center"
                          type="submit"
                          // onClick={() => {
                          //   closeModal
                          //   postQuestion()
                          // }}
                        >
                          {!props?.isUpdateQuestion
                            ? constants?.buttonNames?.addQuestion
                            : constants?.buttonNames?.updateQuestion}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </form>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ToastContainer />
    </div>
  )
}

export default ManageQuestionDialog
