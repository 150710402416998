import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const renderComponentInSwal = async (
  element: React.ReactElement,
  message1: string,
  message2: string
) => {
  const MySwal = withReactContent(Swal);
  try {
    const result = await MySwal.fire({
      html: element,
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: true,
      backdrop: true,
    });

    if (result.isConfirmed) {
      await Swal.fire(
        message1 ? message1 : "Success",
        message2 ? message2 : "Success",
        "success"
      );
    }
  } catch (err) {
    await Swal.fire("Error", "La operación no se pudo completar", "error");
    throw err;
  }
};

export const createInSwal = async (
  targetInfo: string,
  createFunction: () => Promise<void>
) => {
  try {
    if (!createFunction)
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: `Bad Params`,
      });

    const result = await Swal.fire({
      title: "Are you sure?",
      text: `Do you want to create the ${targetInfo ? targetInfo : "element"}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "NO",
      confirmButtonText: "YES!",
    });

    if (!result.isConfirmed) {
      return;
    }

    await createFunction();

    await Swal.fire({
      icon: "success",
      title: `The ${targetInfo ? targetInfo : "element"} has been created.`,
    });
    return;
  } catch (e) {
    await Swal.fire({
      icon: "error",
      title: "Error",
      text: `Error creating the ${targetInfo ? targetInfo : "element"}. ${e}`,
    });
  }
};

/// actionPresent ejem deleting
/// actionPast ejem deleted
/// action delete
export const updateInSwal = async (
  targetInfo: string,
  callbackFunction: () => Promise<void>
) => {
  try {
    if (!callbackFunction)
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: `Bad Params`,
      });

    const result = await Swal.fire({
      title: "Are you sure?",
      text: `Do you want to update the ${targetInfo ? targetInfo : "element"}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "NO",
      confirmButtonText: "YES!",
    });

    if (!result.isConfirmed) {
      return;
    }

    await callbackFunction();

    Swal.fire({
      icon: "success",
      title: `The ${targetInfo ? targetInfo : "element"} has been updated.`,
    });
  } catch (e) {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: `Error updating the ${targetInfo ? targetInfo : "element"}. ${e}`,
    });
  }
};

export const deleteInSwal = async (
  targetInfo: string,
  deleteFunction: () => Promise<void>
) => {
  try {
    if (!deleteFunction)
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: `Bad Params`,
      });

    const result = await Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete the ${targetInfo ? targetInfo : "element"}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "NO",
      confirmButtonText: "YES!",
    });

    if (!result.isConfirmed) {
      return;
    }

    await deleteFunction();

    Swal.fire({
      icon: "success",
      title: `The ${targetInfo ? targetInfo : "element"} has been eliminated.`,
    });
  } catch (e) {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: `Error deleting the ${targetInfo ? targetInfo : "element"}. ${e}`,
    });
  }
};
