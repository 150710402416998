import { Button, Input } from '@material-tailwind/react'
//import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react'
import React, { useState, Fragment, useEffect } from 'react'
import questionnaireServices from '../../../Services/questionnaire.services'
// import CreateNewStep from './QuestionnaireStep'
// import UpdateQuestion from './ManageQuestion'
import { Dialog, Transition } from '@headlessui/react'
import _ from 'lodash'
import * as Yup from 'yup'
//import { yupResolver } from '@hookform/resolvers/yup'
//import { useForm } from 'react-hook-form'
import { P } from '../../../components/typography/typography'
import { Formik, useFormik } from 'formik'
import constants from '../../../constants/constants'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const initialValues = {
  name: '',
}
const createStepSchema = Yup.object({
  name: Yup.string()
    .required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(25, 'Name cannot exceed 25 characters'),
})

function QuestionnaireStepDialog(props: any) {
  const [currentStep, setCurrentStep] = useState({})
  const UpdatedInitialValues = !props?.isEditStep ? initialValues : props?.initialValuesForStep

  const { values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: UpdatedInitialValues,
    validationSchema: createStepSchema,
    onSubmit: (values) => {
      !props?.isEditStep ? createStep(values) : updateStep(values)
      props.setLocalStorage(currentStep)
      resetForm()
      closeModal()
      props.getQuestion()
    },
  })

  const createStep = async (values: any) => {
    const id = props.questionnaireLocalStorage.id
    const name = values?.name
    const payload = { Name: name }
    try {
      const result = await questionnaireServices.CreateQuestionnaireStep(id, payload)
      if (result) {
        toast.success('Added step successfully', { position: 'top-center' })
      }
      localStorage.setItem('currentStep', JSON.stringify(result))
      const currentStepData = JSON.parse(localStorage.getItem('currentStep')!)
      setCurrentStep(currentStepData)
    } catch (error) {
      console.log(error)
      toast.error('Adding step failed', { position: 'top-center' })
      throw error
    }
  }
  const updateStep = async (values: any) => {
    const id = props.questionnaireLocalStorage.id
    const name = values?.name
    const payload = { Name: name }
    try {
      const result = await questionnaireServices.EditStep(id, props.initialValuesForStep?.id, payload)
      if (result) {
        toast.success('Updated step successfully', { position: 'top-center' })
      }
      localStorage.setItem('currentStep', JSON.stringify(result))
      const currentStepData = JSON.parse(localStorage.getItem('currentStep')!)
      setCurrentStep(currentStepData)
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  function closeModal() {
    props.stateManager()
    resetForm()
  }
  return (
    <div>
      <Transition appear show={props.iscreateStep} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <form className="mt-6" onSubmit={handleSubmit}>
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      <h1 className="font-medium leading-tight text-2xl mt-0 mb-2 text-black-600">Create New Step</h1>
                      <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">
                          Please set a friendly name to the step
                        </p>
                        <div className="mb-4">
                          <Input
                            label="Step Name"
                            className="mb-5"
                            required={true}
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            // onBlur={handleBlur}
                          />
                          {errors?.name && <P className="text-red-600">{errors.name as React.ReactChild}</P>}
                        </div>
                      </div>
                    </Dialog.Title>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={closeModal}
                      >
                        CANCEL
                      </button>
                      <div className="modal-footer">
                        <Button
                          className="flex items-center"
                          type="submit"
                          // onClick={() => {
                          //   props.stateManager()
                          //    props.getQuestion()
                          //  }}
                        >
                          {!props?.isEditStep ? constants?.buttonNames?.createStep : constants?.buttonNames?.updateStep}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </form>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ToastContainer />
    </div>
  )
}

export default QuestionnaireStepDialog
