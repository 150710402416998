import { Dialog, Transition } from '@headlessui/react'
import { useState, Fragment } from 'react'
import { Button, Textarea } from '@material-tailwind/react'
import questionnaireServices from '../../../Services/questionnaire.services'
import { ToastContainer, toast } from 'react-toastify'
import constants from '../../../constants/constants'

function ChangeRequestDialog(props: any) {
  const { questionForChangeRequest, credentialRequestId } = props
  const [comment, setComment] = useState('')

  const onSubmit = async () => {
    let payload = [
      {
        answerID: questionForChangeRequest?.answerId,
        credentialRequestId,
        comment,
      },
    ]
    try {
      const result = await questionnaireServices.postAdminComments(payload)
      if (result?.status === 200) {
        props.togglePopUp()
        toast.success(constants.toastMessage.adminReviewCommentSuccess, { position: 'top-center' })
      }
    } catch (error) {
      console.log(error)
      toast.error(constants.toastMessage.adminReviewCommentError, { position: 'top-center' })
      throw error
    }
  }

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.togglePopUp}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <form className="mt-6" onSubmit={() => {}}>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      <h1 className="font-medium leading-tight text-2xl mt-0 mb-2 text-black-600">
                        New Change Request
                      </h1>
                      <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">
                          You are about to create a new change request, the user will be asked to update his answer for
                          this{' '}
                        </p>
                      </div>
                      {/*footer*/}
                      <div className="grid grid-cols-10 gap-1">
                        <div className="col-span-5 w-full">
                          <label>{questionForChangeRequest?.label}</label>
                          {questionForChangeRequest?.required ? <label style={{ color: 'red' }}>*</label> : ''}
                          <br />
                          <label style={{ wordBreak: 'break-word' }}>{questionForChangeRequest?.helpText}</label>
                        </div>
                        <div className="col-span-5 w-full" style={{ border: '1px solid black' }}>
                          <label>{questionForChangeRequest?.answer}</label>
                        </div>
                      </div>
                      <div>
                        <Textarea
                          placeholder="What is the problem with this question?"
                          className="mb-5"
                          name="comments"
                          required
                          onChange={(e) => {
                            setComment(e.target.value)
                          }}
                        />
                      </div>
                    </Dialog.Title>
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={props.togglePopUp}
                      >
                        Cancel
                      </button>
                      <div className="modal-footer">
                        <Button className="flex items-center" onClick={onSubmit}>
                          Create New Change Request
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ToastContainer />
    </div>
  )
}

export default ChangeRequestDialog
