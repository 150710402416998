import { Button } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import Table from '../../../components/common/agGrid'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import insuranceServices from '../../../Services/insurance.services'
import CreateInsuranceCompanyDialog from './CreateInsuranceCompanyDialog'
import EditInsuranceCompany from './EditInsuranceCompany'
import DeleteInsuranceCompany from './DeleteInsuranceCompany'

const InsuranceCompanyList = (props: any) => {
  const [modal, setModal] = useState(false)
  const [insuranceCompany, setInsuranceCompany] = useState([])
  const [rowData, setRowData] = useState({})
  const [dataLoaded, setDataLoaded] = useState(false)
  const [nullCheck, setNullcheck] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const visbility = ''
  const statusValue = 'all'
  const [isInsuranceCompanyEditOpen, setIsInsuranceCompanyEditOpen] = useState(false)
  const [isInsuranceCompanyDeleteOpen, setIsInsuranceCompanyDeleteOpen] = useState(false)
  const [rowDataDelete, setRowDataDelete] = useState({})

  useEffect(() => {
    GetInsuranceCompanies()
  }, [])

  const GetInsuranceCompanies = async () => {
    console.log('load companies')
    setDataLoaded(false)
    setNullcheck(false)
    var response = await insuranceServices.GetAllInsuranceCompanies()
    if (response != null) {
      setNullcheck(true)
    }
    setInsuranceCompany(response)
    setDataLoaded(true)
  }

  const columns = [
    { headerName: 'Name', field: 'name' },
    { headerName: 'Phone Number', field: 'phone' },
    { headerName: 'Map File', field: 'mapFile' },
    { headerName: 'States', field: 'states' },
    {
      headerName: 'Edit',
      field: 'edit',
      valueFormatter: (params: { value: string }) => {
        return 'Edit'
      },
      cellStyle: { color: 'blue', 'text-decoration': 'underline' },
    },
    {
      headerName: 'Delete',
      field: 'delete',
      valueFormatter: (params: { value: string }) => {
        return 'Delete'
      },
      cellStyle: { color: 'blue', 'text-decoration': 'underline' },
    },
  ]
  const stateManager = () => {
    setIsOpen(false)
    setIsInsuranceCompanyEditOpen(false)
    setIsInsuranceCompanyDeleteOpen(false)
  }
  const toggleState = () => {
    setIsOpen(true)
  }

  const getSelectedInsuranceCompany = (data: any) => {
    if (data.column.colId == 'edit') {
      setRowData(data.data)
      setIsInsuranceCompanyEditOpen(true)
    } else if (data.column.colId == 'delete') {
      console.log(data.data)
      setRowDataDelete(data.data)
      setIsInsuranceCompanyDeleteOpen(true)
    }
  }
  return (
    <div>
      <div className="mx-auto px-4 sm:px-8 flex-grow">
        <div className="py-5">
          <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
            <h1 className="text-2xl leading-tight text-3xl">{props.heading}</h1>
            <div className={`text-end ${visbility}`}>
              <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
                <Button className="flex items-center" onClick={toggleState}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  {props.buttonName}
                </Button>
              </form>
            </div>
          </div>
          <p>{props.subTitle}</p>
        </div>
      </div>
      <div>
        {dataLoaded ? (
          <>
            <Table rowData={insuranceCompany} columns={columns} getOnclickCellData={getSelectedInsuranceCompany} />

            <CreateInsuranceCompanyDialog
              isOpen={isOpen}
              stateManager={stateManager}
              heading={'New Insurance Company'}
              buttonName={'Create Insurance Company'}
              requireName={true}
              requirePhoneNo={true}
              requireMapFile={true}
              GetInsuranceCompanies={GetInsuranceCompanies}
            />
          </>
        ) : (
          <p>Loading...</p>
        )}
        <EditInsuranceCompany
          isOpen={isInsuranceCompanyEditOpen}
          stateManager={stateManager}
          data={rowData}
          requireName={true}
          requirePhoneNo={true}
          requireMapFile={true}
          GetInsuranceCompanies={GetInsuranceCompanies}
        />
        <DeleteInsuranceCompany
          isOpen={isInsuranceCompanyDeleteOpen}
          stateManager={stateManager}
          dataDelete={rowDataDelete}
          GetInsuranceCompanies={GetInsuranceCompanies}
        />
      </div>
    </div>
  )
}

export default InsuranceCompanyList
