import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Loader } from "../../../components/common/loader";
import {
  QuestionnaireSubmittedStatus,
  useAcceptQuestionnaireSubmittedMutation,
  useGetSubmittedQuestionnaireByIdQuery,
} from "../../../datasources/graphql/client-types";
import { updateInSwal } from "../../../helpers/swalHelper";
import useAuth from "../../../hooks/useAuth";
import { AnswerWithChangeRequest } from "../../../models/changeRequests";
import { QuestionParams } from "../../../models/questionnaire";
import ChangeRequest from "../../user/changeRequestQuestionnaire/changeRequest";
import AddChangeRequest from "./addChangeRequest";
import ShowQuestionnaire from "./showQuestionnaire";

export default function AddChangeRequestQuestionnaire() {
  const { questionnaireId } = useParams();
  const { user } = useAuth();
  const { data, loading, error } = useGetSubmittedQuestionnaireByIdQuery({
    variables: {
      questionnaireSubmittedId: questionnaireId || "",
      userId: user.id || "",
    },
  });
  const [acceptQuestionnaire] = useAcceptQuestionnaireSubmittedMutation();
  const [answersWithChangeRequests, setAnswersWithChangeRequests] = useState<
    AnswerWithChangeRequest[]
  >([]);
  const [addChangeRequest, setAddChangeRequest] = useState<boolean>(false);
  const [question, setQuestion] = useState<QuestionParams>();
  const [questions, setQuestions] = useState<QuestionParams[]>([]);
  const router = useNavigate();

  useEffect(() => {
    if (data?.getSubmittedQuestionnaireById || !loading) {
      const answersWithChangeRequestsInfo: AnswerWithChangeRequest[] =
        data!.getSubmittedQuestionnaireById!.answers!.map((answer) => {
          const changeRequests =
            data?.getSubmittedQuestionnaireById.requestChanges.filter(
              (changeRequest) =>
                changeRequest.idQuestion === answer.id &&
                changeRequest.status === "pending" // Just show changeRequests that are not submitted, just pendings
            ) || [];

          setQuestions((prev) => [...prev, answer.question]);

          return { answer, changeRequests };
        });

      const filteredAnswers = answersWithChangeRequestsInfo.filter(
        (answer) => answer.changeRequests.length > 0
      );
      console.log(filteredAnswers);

      setAnswersWithChangeRequests(filteredAnswers);
    }
  }, [data, loading]);

  if (loading) return <Loader />;

  //Change Route if the questionnaire is not in ChangeRequest Status
  if (
    data?.getSubmittedQuestionnaireById.status ===
      QuestionnaireSubmittedStatus.Approved ||
    data?.getSubmittedQuestionnaireById.status ===
      QuestionnaireSubmittedStatus.PendingPayment ||
    data?.getSubmittedQuestionnaireById.status ===
      QuestionnaireSubmittedStatus.Rejected
  ) {
    Swal.fire({
      icon: "error",
      title: "Invalid Questionnaire",
      html: "This questionnaire can't have change requests",
    }).then((result) => {
      router("/questionnairesAdmin");
    });
  }

  function handleAddChangeRequest() {
    setAddChangeRequest(true);
  }

  async function handleAcceptQuestionnaire() {
    await updateInSwal("questionnaire", async () => {
      await acceptQuestionnaire({
        variables: {
          qId: data!.getSubmittedQuestionnaireById.id,
        },
      });
    });
  }

  function handleSetQuestion(id: string) {
    setQuestion(questions.find((question) => question.id === id));
    setAddChangeRequest(true);
  }

  return (
    <div className="w-full bg-background flex justify-center items-center min-h-ScreenWithNavbar">
      <ShowQuestionnaire
        questionnaire={data!.getSubmittedQuestionnaireById}
        setQuestion={handleSetQuestion}
      />
      <div className="w-full max-w-3xl bg-onBackground rounded-2xl py-6 px-6">
        <div className="flex justify-between mb-4">
          <h2 className="w-full text-4xl font-bold mb-4">Change Requests</h2>
          <button
            className="bg-primary px-4 py-2 text-white rounded-2xl"
            onClick={handleAcceptQuestionnaire}
          >
            Accept Questionnaire
          </button>
          <button
            className="bg-primary px-4 py-2 text-white rounded-2xl ml-4"
            onClick={handleAddChangeRequest}
          >
            Add Change Request
          </button>
          {/* <button onClick={handleAddChangeRequest}>Decline Questionnaire</button> */}
        </div>

        <div>
          {addChangeRequest && (
            <AddChangeRequest
              setIsFinish={() => setAddChangeRequest(false)}
              question={question!}
              answers={data!.getSubmittedQuestionnaireById!.answers}
              qSubmittedId={data?.getSubmittedQuestionnaireById.qId || ""}
            />
          )}
          {answersWithChangeRequests.map((answer) => (
            <ChangeRequest answer={answer} key={answer.answer.id} isAdmin />
          ))}
        </div>
      </div>
    </div>
  );
}
