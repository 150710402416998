import { Route, Routes } from "react-router-dom";
import "./App.css";
import RequireAuth from "./helpers/requireAuth";
import Credentialing from "./screens/user/credentialing/credentialing";
import MainForm from "./screens/user/form/mainForm";
import State from "./screens/user/state/state";
import AuthPage from "./screens/auth/authPage";
import MainPage from "./screens/mainPage";
import SignUpPage from "./screens/signup/signUpPage";
import Insurances from "./screens/user/insurances/insurances";
import ManageQuestionnaires from "./screens/admin/manageQuestionnaires/manageQuestionnaires";
import UsersAndAccess from "./screens/admin/users/users";
import EditQuestionnaire from "./screens/admin/editQuestionnaires/editQuestionnaire";
import AddQuestionnaire from "./screens/admin/addQuestionnaires/addQuestionnaire";
import ReviewSubmittedQuestionnaire from "./screens/admin/reviewQuestionnaires/reviewSubmittedQuestionnaire";
import RequestsAdmin from "./screens/admin/requestsAdmin/requestAdmin";
import NewManageQuestionnaires from "./screens/NewAdmin/ManageQuestionnaires/ManageQuestionnaires";
import AddQuestionaires from "./screens/NewAdmin/ManageQuestionnaires/AddQuestionaires";
import QuestionaireContainer from "./screens/NewAdmin/ManageQuestionnaires/QuestionaireContainer";
import Plans from "./screens/NewAdmin/ManageQuestionnaires/Plans";
import PriceOptions from "./screens/pricing/PriceOptions";
import InsuranceCompanyList from "./screens/NewAdmin/ManageInsuranceCompany/InsuranceCompanyList";
import InsuranceRequestList from "./screens/NewAdmin/ManageInsuranceCompany/InsuranceRequestList";
import InsuranceRequestView from "./screens/Dentist/ManageInsuranceRequest/InsuranceRequestView";
import InsuranceContainer from "./screens/Dentist/ManageInsuranceRequest/InsuranceContainer";
import Signature from "./screens/Dentist/Signature";
import InitialLandingPage from "./screens/Dentist/ManageInsuranceRequest/InitialLandingPage";
import MailingList from "./screens/Dentist/ManageInsuranceRequest/MailingList";
import SelectInsurance from "./screens/Dentist/ManageInsuranceRequest/SelectInsurance";
import InsuranceChangeRequest from "./screens/Dentist/ManageInsuranceRequest/InsuranceChangeRequest";
import ViewInsuranceRequestStatus from "./screens/NewAdmin/ManageInsuranceRequests/ViewInsuranceRequestStatus";
import ConfirmRegistration from "./screens/auth/confirmRegistration";


function App() {
  return (
    <Routes>
      {/* Unprotected Routes */}
      <Route path="/" element={<AuthPage />} />
      <Route path="/login" element={<AuthPage />} />
      <Route path="/signup" element={<AuthPage />} />
      <Route path="/confirm-registration" element={<ConfirmRegistration />} />

      {/* Protected Routes */}
      <Route element={<MainPage />}>



        <Route
          path="/credentialing"
          element={
            <RequireAuth>
              <InitialLandingPage />
            </RequireAuth>
          }
        />
        <Route
          path="/questionnaire/:stateId"
          element={
            <RequireAuth>
              <MainForm />
            </RequireAuth>
          }
        />
        <Route
          path="/landingpage"
          element={
            <RequireAuth>
              <InitialLandingPage />
            </RequireAuth>
          }
        />
        <Route
          path="/selectinsurance"
          element={
            <RequireAuth>
              <SelectInsurance />
            </RequireAuth>
          }
        />
        <Route
          path="/insurances/:stateId"
          element={
            <RequireAuth>
              <Insurances />
            </RequireAuth>
          }
        />

        <Route
          path="/mailinglist"
          element={
            <RequireAuth>
              <MailingList />
            </RequireAuth>
          }
        />

        {/* Admin Routes */}
        <Route
          path="/requestsAdmin"
          element={
            <RequireAuth requireAdmin>
              <RequestsAdmin />
            </RequireAuth>
          }
        />
        <Route
          path="/insuranceRequests"
          element={
            <RequireAuth requireAdmin>
              {/* // <Manage Insurance Request /> */}
              <InsuranceRequestList
                heading={"Credential Requests"}
                data={[
                  {
                    label: "All",
                    value: "all"

                  }, {
                    label: "Pending",
                    value: "pending"

                  }, {
                    label: "Change Requested",
                    value: "changeRequested"
                  }]}
                buttonName={"New Request"}
                subTitle={"Manage requests made by dentists."} />

            </RequireAuth>
          }
        />
        <Route
          path="/insuranceRequestsDentist"
          element={
            <RequireAuth requireAdmin>
              {/* // <Manage Insurance Request /> */}
              <InsuranceRequestView
                heading={"Insurance Requests"}
                data={[
                  {
                    label: "All",
                    value: "all"

                  }, {
                    label: "Inprogress",
                    value: "inprogress"

                  }, {
                    label: "Ready To Send",
                    value: "readyToSend"
                  }, {
                    label: "Sent",
                    value: "sent"
                  }, {
                    label: "Changes Requested",
                    value: "changesRequested"

                  }, {
                    label: "Insurance Company Feedback",
                    value: "insuranceCompanyFeedback"

                  }, {
                    label: "Final Approval",
                    value: "finalApproval"
                  }, {
                    label: "Credential Complete",
                    value: "credentialComplete"
                  }]}
                buttonName={"New Request"}
                subTitle={"Lorem ipsum dolor sit amet."} />

            </RequireAuth>
          }
        />
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              {/* // <Manage Insurance Request /> */}
              <InsuranceRequestView
                heading={"Credentialing Dashboard"}
                data={[
                  {
                    label: "All",
                    value: "all"

                  }, {
                    label: "In Progress",
                    value: "inprogress"

                  }, 
                  // {
                  //   label: "Ready To Send",
                  //   value: "readyToSend"
                  // }, {
                  //   label: "Sent",
                  //   value: "sent"
                  // }, 
                  {
                    label: "Changes Requested",
                    value: "changesRequested"

                  },
                  // {
                  //   label: "Insurance Company Feedback",
                  //   value: "insuranceCompanyFeedback"

                  // }, {
                  //   label: "Final Approval",
                  //   value: "finalApproval"
                  // }, 
                  {
                    label: "Credential Complete",
                    value: "credentialComplete"
                  }]}
                buttonName={"New Request"}
                subTitle={"See below for credentialing statuses"} />

            </RequireAuth>
          }
        />
        <Route
          path="/insuranceCompany"
          element={
            <RequireAuth requireAdmin>
              {/* // <Manage Insurance Company /> */}
              <InsuranceCompanyList
                heading={"Insurance Company"}
                data={[
                  {
                    label: "Name",
                    value: "name"

                  }, {
                    label: "Phone Number",
                    value: "phoneNo"

                  }, {
                    label: "Map File",
                    value: "mapFile"
                  },
                  {
                    label: "State",
                    value: "state"
                  }]}
                buttonName={"New Insurance Company"}
              />

            </RequireAuth>
          }
        />
        <Route
          path="/insuranceChangeRequest"
          element={
            <RequireAuth requireAdmin>
              <InsuranceChangeRequest
                heading={"Insurance change Requests"}
                data={[
                  {
                    label: "Questionarie",
                    value: "questionarie"

                  }, {
                    label: "Change Requests",
                    value: "changeRequests"

                  }, {
                    label: "Settings",
                    value: "settings"
                  }]}
                subTitle={"Lorem ipsum dolor sit amet."} />

            </RequireAuth>
          }
        />
        <Route
          path="/viewInsuranceRequestsStatus"
          element={
            <RequireAuth requireAdmin>
              <ViewInsuranceRequestStatus
                heading={"Insurance Requests Status"}
                buttonName={"New Request"}
                subTitle={"Lorem ipsum dolor sit amet."} />

            </RequireAuth>
          }
        />
        <Route
          path="/questionnairesAdmin"
          element={
            <RequireAuth requireAdmin>
              {/* // <ManageQuestionnaires /> */}
              <NewManageQuestionnaires
                heading={"Questionnaires"}
                data={[
                  {
                    label: "All",
                    value: "all"

                  }, {
                    label: "Published",
                    value: "published"

                  }, {
                    label: "Draft",
                    value: "draft"
                  }]}
                buttonName={"New Questionnaire"}
                subTitle={"View and edit all available questionnaires."} />

            </RequireAuth>
          }
        />
        <Route
          path="/createQuestionnaire"
          element={
            <QuestionaireContainer />
          }

        />
        <Route
          path="/createInsurance"
          element={
            <InsuranceContainer />
          }

        />
        <Route
          path="/plans"
          element={
            <PriceOptions />
          }
        />
        <Route
          path="/signature"
          element={
            <Signature />
          }

        />
        <Route
          path="/questionnaire/add/:stateId"
          element={
            <RequireAuth requireAdmin>
              <AddQuestionnaire />
            </RequireAuth>
          }
        />
        <Route
          path="/questionnaire/:qId/edit"
          element={
            <RequireAuth requireAdmin>
              <EditQuestionnaire />
            </RequireAuth>
          }
        />
        <Route
          path="/submittedQuestionnaireAdmin/:questionnaireId/review"
          element={
            <RequireAuth requireAdmin>
              <ReviewSubmittedQuestionnaire />
            </RequireAuth>
          }
        />
        <Route
          path="/usersAndAccess"
          element={
            <RequireAuth requireAdmin>
              <UsersAndAccess />
            </RequireAuth>
          }
        />
      </Route>


    </Routes>
  );
}

export default App;
