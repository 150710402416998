import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { Loader } from "../components/common/loader";
import useAuth from "../hooks/useAuth";
import { User, userIsAdmin } from "../models/user";
import NotFound from "../screens/notFound/NotFound";

export default function RequireAuth({
  children,
  requireAdmin,
}: {
  children: JSX.Element;
  requireAdmin?: boolean;
}) {
  let { user } = useAuth(); 
  let location = useLocation(); 

  function userIsNotLoaded(user: User) {
    return user.id === "unknown";
  }

  if (userIsNotLoaded(user)) return <Loader />;

  if (user?.id?.length === 0)
    return <Navigate to="/login" state={{ from: location }} replace />;

  //admins does not have to follow normal flow
  if (userIsAdmin(user)) return children;

  if (requireAdmin) return <NotFound />;

  // if (
  //   !user.questionnaireSubmitted &&
  //   location.pathname !== "/landingpage" &&
  //   !location.pathname.startsWith("/questionnaire") &&
  //   !location.pathname.startsWith("/insurances")
  // ) {
  //   return <Navigate to="/landingpage" state={{ from: location }} replace />;
  // }

  return children;
}
