import { useState } from "react";
import { Plan } from "../../../datasources/graphql/client-types";
import InsurancesSelection from "./insuranceSelection";
import InsurancesPayment from "./payment/InsurancesPayment";

export default function Insurances() {
  const [plan, setPlan] = useState<Plan>();
  const [insurances, setInsurances] = useState<string[]>([]);


  console.log(plan)
  return (
    <>
      {plan ? (
        <InsurancesPayment insurancesPlan={plan} insurances={insurances} />
      ) : (
        <InsurancesSelection setPlan={setPlan} setInsurances={setInsurances} />
      )}
    </>
  );
}
