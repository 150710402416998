import { Progress } from "@material-tailwind/react";

function QuestionnaireContainer({
  children,
  progress = 0
}: {
  children: JSX.Element;
  progress?: number
}) {
  return (
    <div className="w-full bg-white">
      <Progress value={progress} barProps={{className: "transition-all duration-1000"}}/>
      <div className="p-8">
        {/* <h3>quetionaire!</h3> */}
        <div>{ children }</div>
      </div>
    </div>
  )
}
export default QuestionnaireContainer