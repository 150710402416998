import { useState } from 'react';
import { ChangeRequestModel } from './ChangeRequestModel';
import ChangeRequestItem from './ChangeRequestItem';
import './ChangeRequestTable.css';

interface ChangeRequestProps {
  items: ChangeRequestModel[];
  getChangeRequestsData: (credentialrequestid: any) => Promise<void>;
}

function ChangeRequests({ items, getChangeRequestsData }: ChangeRequestProps) {
   const [currentIdx, setCurrentIdx] = useState(-1);
   const btnOnClick = (idx: number) => {
      setCurrentIdx((currentValue) => (currentValue !== idx ? idx : -1));
    };
 
   return (
     <ul className="accordion">
       {items?.map((item, idx) => (
         <ChangeRequestItem
           key={idx}
           data={item}
           isOpen={idx === currentIdx}
           btnOnClick={() => btnOnClick(idx)}
           getChangeRequestsData={getChangeRequestsData}
         />
       ))}
     </ul>
   );
 }
 
 export default ChangeRequests;