import { useEffect, useState } from 'react'
import { Tabs } from '@material-tailwind/react'
import Table from '../../../../src/components/common/agGrid'
import adminChangeRequests from '../../../Services/credential.services'
import UpdateStatusDialog from '../ManageInsuranceCompany/UpdateStatusDialog'
import { useNavigate } from 'react-router-dom'
import credentialServices from '../../../Services/credential.services'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import constants from '../../../constants/constants'
import fileServices from '../../../Services/file.service'

const ViewInsuranceRequestStatus = (props: any) => {
  const [insuranceRequestStatus, setInsuranceRequestStatus] = useState([])
  const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState(false)
  const [data, setData] = useState({ insuranceStatus: '', credentialRequestId: '', insuranceRequestId: '' })
  const navigate = useNavigate()

  const credentialrequestData = JSON.parse(localStorage.getItem('credentialrequestid')!)
  const stateManager = () => {
    setIsUpdateStatusModalOpen(false)
  }

  useEffect(() => {
    getInsuranceRequestsData()
  }, [])

  const getInsuranceRequestsData = async () => {
    try {
      const result = await adminChangeRequests.GetInsuranceRequests(credentialrequestData.credentialRequestId)
      console.log(result)
      setInsuranceRequestStatus(result)
    } catch (error) {
      throw error
    }
  }
  const getSelectedCustomerData = (data: any) => {
    if (data.column.colId == 'edit') {
      setData(data.data)
      setIsUpdateStatusModalOpen(true)
    } else if (data.column.colId == 'signedPdfAvailable') {
      if (data.data.signedPdfAvailable) {
        fileServices.getFile(data.data.signedPdfVaultId)
      }
    }
  }
  const columns = [
    { headerName: 'Name', field: 'name' },
    { headerName: 'Credential Status', field: 'credStatus' },
    //{ headerName: "Credential Request Id", field: "credentialRequestId" },
    { headerName: 'Date', field: 'requestDate' },
    { headerName: 'Insurance Status', field: 'insuranceStatus' },
    //{ headerName: "Insurance Request Id", field: "insuranceRequestId" },
    //{ headerName: "Message", field: "message" },

    {
      headerName: 'Change Status',
      field: 'edit',
      valueFormatter: (params: { value: string }) => {
        return 'Change Status'
      },
      cellStyle: { color: 'blue', 'text-decoration': 'underline' },
    },
    {
      headerName: 'Download',
      field: 'signedPdfAvailable',
      valueFormatter: (params: { value: boolean }) => {
        if (params.value) {
          return 'Download'
        } else {
          return ''
        }
      },
      cellStyle: { color: 'blue', 'text-decoration': 'underline' },
    },
  ]

  const btnOnClick = () => {
    navigate('/insuranceRequests')
  }

  const UpdateInsuranceStatus = async (status: any) => {
    const payload = { status: status }
    const result = await credentialServices.UpdateInsuranceStatus(
      data?.credentialRequestId,
      data?.insuranceRequestId,
      payload,
    )
    if (result) {
      getInsuranceRequestsData()
      toast.success('Status Updated successfully', { position: 'top-center' })
    } else {
      toast.error('Status Updation Failed', { position: 'top-center' })
    }
    stateManager()
  }

  return (
    <div>
      <div className="mx-auto flex-grow">
        <div className="py-5">
          <div className="flex flex-row">
            <button onClick={btnOnClick} className="full-left px-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
              </svg>
            </button>
            <h1 className="text-2xl leading-tight text-3xl">{props.heading}</h1>
          </div>
          {/* <p>{props.subTitle}</p> */}
        </div>
      </div>
      <div>
        <Tabs value="html">
          <Table rowData={insuranceRequestStatus} columns={columns} getOnclickCellData={getSelectedCustomerData} />
          <UpdateStatusDialog
            isOpen={isUpdateStatusModalOpen}
            stateManager={stateManager}
            data={data}
            //getInsuaranceRequestStatusData={getInsuaranceRequestStatusData}
            currentStatus={data.insuranceStatus}
            submitMethod={UpdateInsuranceStatus}
            statusOptions={constants.insuranceRequestStatusOptions}
          />
        </Tabs>
      </div>
      <ToastContainer />
    </div>
  )
}
export default ViewInsuranceRequestStatus
