import { Form, Formik, FormikValues } from "formik";
import { useRef } from "react";
import {
  useAcceptChangeRequestMutation,
  useUpdateAnswerOfQuestionnaireMutation,
} from "../../../datasources/graphql/client-types";
import { updateInSwal } from "../../../helpers/swalHelper";
import useCredentialSubmit from "../../../hooks/useSubmit";
import { AnswerWithChangeRequest } from "../../../models/changeRequests";
import GenericForm from "../form/genericForm";

const statusColor = {
  approved: "#16a34a",
  pending: "#9F7300",
};

export default function ChangeRequest({
  answer,
  isAdmin = false,
}: {
  answer: AnswerWithChangeRequest;
  isAdmin?: boolean;
}) {
  const formRef = useRef(null);
  const { submitting, setSubmitting } = useCredentialSubmit();
  const [updateQuestion] = useUpdateAnswerOfQuestionnaireMutation();
  const [acceptChangeRequest] = useAcceptChangeRequestMutation();

  if (submitting) {
    if (formRef.current) {
      (formRef as FormikValues).current.handleSubmit();
      setSubmitting(false);
    }
  }

  async function handleUpdateQuestion(value: string) {
    await updateQuestion({
      variables: {
        answerUpdateInput: {
          answerId: answer.answer.id,
          newValue: value,
        },
      },
    });
  }

  async function handleSubmit(values: any, actions: any) {
    try {
      await updateInSwal(
        "change request",
        async () =>
          await handleUpdateQuestion(values[answer.answer.question.id])
      );
    } catch (error) {
      throw error;
    }
  }

  async function handleAcceptChangeRequest(changeRequestId: string) {
    try {
      await updateInSwal("change request", async () => {
        await acceptChangeRequest({
          variables: { requestId: changeRequestId },
        });
      });
    } catch (error) {}
  }

  return (
    <div
      className="flex flex-col bg-background rounded-2xl mb-5 px-4 py-4 font-bold"
      key={answer.answer.id}
    >
      {/* This is needed for Tailwind to import the colors for bg and texts */}
      <div className="w-0 h-0">
        <span className="bg-[#9F7300] text-[#9F7300]"></span>
        <span className="bg-[#dc2626] text-[#dc2626]"></span>
      </div>
      {/* <div className="w-full flex justify-between"><p>Question: {answer.answer.question.name}</p></div> */}
      <div className="flex flex-col">
        <p>Change Request:</p>
        <ul className="list-item list-disc ml-5">
          {answer.changeRequests?.map((changeRequest, index) => (
            <li
              className="flex w-full justify-between items-center"
              key={index}
            >
              <p className="">{changeRequest.reason}</p>
              <div className="flex items-center">
                {isAdmin && (
                  <button
                    className="mr-4 py-2 px-4 bg-primary text-white rounded-2xl"
                    onClick={() => handleAcceptChangeRequest(changeRequest.id)}
                  >
                    Accept Change request
                  </button>
                )}
                <p
                  className={`text-[${
                    statusColor[changeRequest.status]
                  }] font-bold`}
                >
                  {answer.changeRequests[0].status}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          [answer.answer.question.id]: answer.answer.value,
        }}
        innerRef={formRef}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col items-end">
            <p className="w-full mt-2">Question: </p>
            <div className="w-full bg-onBackground px-4 py-4 rounded-xl">
              <GenericForm
                fields={[answer.answer.question]}
                requiredFields={false}
              />
            </div>

            <div className="flex justify-end w-full">
              <button
                className="bg-blue-700 rounded-lg py-3 px-4 text-white my-4 max-w-sm"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
