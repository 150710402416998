import { Button } from '@material-tailwind/react'
import { useEffect, useState, Fragment } from 'react'
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react'
import Table from '../../../../src/components/common/agGrid'
import questionnaireServices from '../../../Services/questionnaire.services'
import moment from 'moment'
import AddQuestionnaireDialog from './AddQuestionnaireDialog'
import { useNavigate } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import UpdateQuestionnaireStatesDialog from './UpdateQuestionnaireStatesDialog'
import UpdateQuestionnaireInsuranceDialog from './UpdateQuestionnaireInsuranceDialog'

function ManageQuestionnaires(props: any) {
  const [questionnaire, setQuestionnaire] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isStateModalOpen, setIsStateModalOpen] = useState(false)
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false)
  const [modal, setModal] = useState(false)
  const [deleteQuestionnaireModal, setDeleteQuestionnaireModal] = useState(false)
  const [deleteQuestionnaireData, setDeleteQuestionnaireData] = useState<{ id?: string; name?: string }>({})
  const [updateStateQuestionnaireModal, setUpdateStateQuestionnaireModal] = useState(false)
  const [updateStateQuestionnaireData, setUpdateStateQuestionnaireData] = useState<{ id?: string; name?: string }>({})
  const [updateInsuranceQuestionnaireModal, setUpdateInsuranceQuestionnaireModal] = useState(false)
  const [updateInsuranceQuestionnaireData, setUpdateInsuranceQuestionnaireData] = useState<{ id?: string; name?: string }>({})

  const navigate = useNavigate()
  useEffect(() => {
    getQuestionnaireData('all')
    setModal(true)
  }, [isStateModalOpen,isInsuranceModalOpen])
  const getQuestionnaireData = async (value: any) => {
    try {
      setModal(false)
      const result = await questionnaireServices.GetQuestionnaires(1, 99999, value)
      result.map((item: any) => {
        item.status = item.status.toUpperCase()
        item.lastEditedUTC = moment(item.lastEditedUTC.slice(0, 10), 'YYYYMMDD').fromNow()
      })
      setQuestionnaire(result)
      setModal(true)
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  const getSelectedQuestionnaireId = async (data: any) => {
    if (data.column.colId == 'delete') {
      setDeleteQuestionnaireModal(true)
      setDeleteQuestionnaireData(data?.data)
    } else if (data.column.colId == 'states') {
      setUpdateStateQuestionnaireModal(true)
      setUpdateStateQuestionnaireData(data?.data)
      toggleStateManagerModal();
    } else if (data.column.colId=='insuranceCompanyName') {
      setUpdateInsuranceQuestionnaireModal(true)
      setUpdateInsuranceQuestionnaireData(data?.data)
      toggleInsuranceManagerModal();
      
    
    }
     else {
      localStorage.setItem('questionnaireData', JSON.stringify(data.data))
      localStorage.setItem('currentStep', '')
      navigate('/createQuestionnaire')
    }
  }

  const columns = [
    {
      headerName: 'Quest. ID',
      field: 'id',
      cellRenderer: (questionnaireID: any) => (
        <a href="#" role="button">
          {questionnaireID.value}
        </a>
      ),
      cellStyle: { color: 'blue', 'text-decoration': 'underline' },
    },
    { headerName: 'Name', field: 'name' },
    { headerName: 'State', field: 'states' },
    {
      headerName: 'Status',
      field: 'status',
      cellClassRules: {
        cellDraft: 'x == "DRAFT"',
        cellPublished: 'x =="PUBLISHED"',
      },
    },
    { headerName: 'Insurance Company', field: 'insuranceCompanyName'},
    { headerName: 'Date', field: 'lastEditedUTC' },
    {
      headerName: 'Delete',
      field: 'delete',
      valueFormatter: (params: { value: string }) => {
        return 'Delete'
      },
      cellStyle: { color: 'red', 'text-decoration': 'underline' },
    },
  ]

  const visbility = ''
  const stateManager = () => {
    setIsOpen(false)
    setIsStateModalOpen(false);
    setIsInsuranceModalOpen(false);
  }
  const toggleState = () => {
    setIsOpen(true)
    //  modal == false ? setModal(true) : setModal(false)
  }
  const toggleStateManagerModal = () => {
    setIsStateModalOpen(true)
    //  modal == false ? setModal(true) : setModal(false)
  }
  const toggleInsuranceManagerModal = () => {
    setIsInsuranceModalOpen(true)
    //  modal == false ? setModal(true) : setModal(false)
  }
  const deleteQuestionnaire = async () => {
    const result = await questionnaireServices.DeleteQuestionnaire(deleteQuestionnaireData?.id || '')
    if (result) {
      getQuestionnaireData('all')
      setDeleteQuestionnaireModal(false)
      toast.success('Questionnaire deleted successfully', { position: 'top-center' })
    } else {
      setDeleteQuestionnaireModal(false)
      toast.success('Questionnaire could not be deleted', { position: 'top-center' })
    }
  }
  function closeModal() {
    setDeleteQuestionnaireModal(false)
  }
  return (
    <div>
      <div className="mx-auto px-4 sm:px-8 flex-grow">
        <div className="py-5">
          <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
            <h1 className="text-2xl leading-tight text-3xl">{props.heading}</h1>
            <div className={`text-end ${visbility}`}>
              <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
                <Button className="flex items-center" onClick={toggleState}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  {props.buttonName}
                </Button>
              </form>
            </div>
          </div>
          <p>{props.subTitle}</p>
        </div>
      </div>
      {/* <hr /> */}
      <div>
        <Tabs value="html">
          <TabsHeader className="z-10">
            {props.data?.map(({ label, value }: any) => (
              <Tab
                id="tabQ"
                key={value}
                value={value}
                onClick={() => {
                  getQuestionnaireData(value)
                }}
              >
                {`${label} `}
              </Tab>
            ))}
          </TabsHeader>
          {/* {!modal ? */}
          {modal ? (
            <TabsBody className="z-10">
              <Table rowData={questionnaire} columns={columns} getOnclickCellData={getSelectedQuestionnaireId} />
            </TabsBody>
          ) : (
            <p>Loading...</p>
          )}
          {/* : */}
          <AddQuestionnaireDialog
            isOpen={isOpen}
            stateManager={stateManager}
            heading={'Create new questionnaire'}
            description={
              'You are about to create a new questionnaire, please give it a name and select a state to continue.'
            }
            buttonName={'Create Questionnaire'}
            requireName={true}
          />
          {/* } */}
          <UpdateQuestionnaireStatesDialog
            isOpen={isStateModalOpen}
            stateManager={stateManager}
            heading={'Select States'}
            description={
              'Please select all the states that should be associated with this questionnaire.'
            }
            buttonName={'Save'}
            requireName={true}
            questionnaireId={updateStateQuestionnaireData?.id}
          />
            <UpdateQuestionnaireInsuranceDialog
            isOpen={isInsuranceModalOpen}
            stateManager={stateManager}
            heading={'Select Insurance Company'}
            description={
              'Please select the insurance company that should be associated with this questionnaire.'
            }
            buttonName={'Save'}
            requireName={true}
            questionnaireId={updateInsuranceQuestionnaireData?.id}
          />
        </Tabs>
      </div>
      <div>
        <Transition appear show={deleteQuestionnaireModal} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {`Are you sure on deleting ${deleteQuestionnaireData?.name || ''}?`}
                    </Dialog.Title>
                    <div className="mt-4" style={{ textAlign: 'right' }}>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                        style={{ margin: '2px' }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={deleteQuestionnaire}
                        style={{ margin: '2px' }}
                      >
                        {'Yes Delete'}
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
      <ToastContainer />
    </div>
  )
}

export default ManageQuestionnaires
