import { Dialog, Transition } from '@headlessui/react'
import React, { useState, Fragment, useEffect } from 'react'
import { Button, Option, Select } from '@material-tailwind/react'
import { ToastContainer, toast } from 'react-toastify'
import { P } from '../../../components/typography/typography'
import 'react-toastify/dist/ReactToastify.css'

function StepSelectionDialog(props: any) {
  const [step, setStep] = useState(props?.currentStep)
  const [stepSelected, setStepSelected] = useState(false)

  const { currentStep } = props
  function closeModal() {
    props.stateManager()
  }
  const handleSubmit = async () => {
    try {
      if (step) {
        console.log(step)
        props?.submitMethod(step)
      } else {
        setStepSelected(true)
      }
    } catch (error) {
      toast.error('Something went wrong', { position: 'top-center' })
      throw error
    }
  }
  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    <h1 className="font-medium leading-tight text-2xl mt-0 mb-2 text-black-600">Select Step</h1>
                    <div className="relative p-6 flex-auto">
                      <p className="my-4 text-slate-500 text-lg leading-relaxed">Please select an option</p>
                      <div className="mb-4">
                        <Select
                          label={'Select the step to move Question'}
                          onChange={(value) => {
                            setStep(value)
                          }}
                          value={props.currentStatus}
                        >
                          {props?.stepsOptions?.map((step: any) => {
                            return (
                              <Option key={step.id} value={step}>
                                {step.name}
                              </Option>
                            )
                          })}
                        </Select>
                        {stepSelected && <P className="text-red-600">Please select an option</P>}
                      </div>
                    </div>
                  </Dialog.Title>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={closeModal}
                    >
                      CANCEL
                    </button>
                    <div className="modal-footer">
                      <Button
                        className="flex items-center"
                        type="submit"
                        onClick={() => {
                          handleSubmit()
                        }}
                      >
                        Move
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ToastContainer />
    </div>
  )
}
export default StepSelectionDialog
