import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { StyledFastField } from "../../../components/common/styledFastField";
import {
  ChangeRequestInput,
  useCreateChangeRequestMutation,
} from "../../../datasources/graphql/client-types";
import { createInSwal } from "../../../helpers/swalHelper";
import {
  AnswerProps,
  AnswerWithChangeRequest,
} from "../../../models/changeRequests";
import { QuestionParams } from "../../../models/questionnaire";
import SelectQuestion from "./selectQuestion";

export default function AddChangeRequest({
  setIsFinish,
  question,
  answers,
  qSubmittedId,
}: {
  setIsFinish: () => void;
  question: QuestionParams;
  qSubmittedId: string;
  answers: AnswerProps[];
}) {
  const [answer, setAnswer] = useState<AnswerProps>();
  const [selectedQuestion, setSelectedQuestion] = useState<{
    name: string;
    id: string;
  }>();
  const [createChangeRequest] = useCreateChangeRequestMutation();

  async function handleCreateChangeRequest(reason: string) {
    try {
      const createChangeRequestInput: ChangeRequestInput = {
        answerId: answer?.id || "",
        qSubmittedId: answer?.qSubmittedId || "",
        reason,
      };

      await createChangeRequest({
        variables: {
          ChangeRequestInput: createChangeRequestInput,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async function handleSubmit(values: any, actions: any) {
    try {
      createInSwal(
        "Change Request",
        async () => await handleCreateChangeRequest(values.reason)
      );
    } catch (error) {
      throw error;
    }
  }

  function validation(values: { reason: string }) {
    let errors: any = {};
    if (!values.reason) {
      errors.reason = "The reason is required";
    }
    return errors;
  }

  // function handleSelectedQuestion(name: string, id: string) {
  //   setSelectedQuestion({ name, id });
  //   const searchAnswer = answers.find((answer) => answer.question.id === id);
  //   setAnswer(searchAnswer);
  //   console.log(name, id);
  // }

  useEffect(() => {
    const searchAnswer = answers.find(
      (answer) => answer.question.id === question.id
    );
    setAnswer(searchAnswer);

    return () => {
      setAnswer(undefined);
    };
  }, [question, answers]);

  return (
    <div className="flex flex-col bg-background rounded-2xl mb-5 px-4 py-4 font-bold">
      <div className="w-full flex flex-col justify-between">
        {/* <SelectQuestion
          questions={questions}
          setSelectedQuestion={handleSelectedQuestion}
        /> */}
        <p>Question: {question.name}</p>
        <p>Answer: {answer?.value ?? ""}</p>
      </div>

      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          reason: "",
        }}
        validate={validation}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col items-end">
            <StyledFastField name="reason" label="Reason: " />

            <div className="flex justify-end w-full">
              <button
                className="bg-blue-700 rounded-lg py-3 px-4 text-white my-4 max-w-sm"
                type="submit"
                disabled={isSubmitting}
              >
                Submit change request
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
