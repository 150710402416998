import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";
import MobileAppBar from "./components/mobileAppBar";
import { AuthLink } from "./interfaces";
import { adminNavigation, userMenu, userNavigation } from "./menus";
import MobileMenuButtom from "./components/mobileMenuButtom";
import useAuth from "../../hooks/useAuth";
import LoggedSection from "./components/loggedSection";
import LogInAppBar from "./components/logInAppBar";
import { userIsAdmin } from "../../models/user";
import CredentalLogo from "../../icons/credentalLogo";
import { useNavigate } from 'react-router-dom'
import { wrap } from "module";
import statesService from "../../Services/states.service";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { sidebarStore } from "../../screens/store/SidebarStore";
import { observer } from "mobx-react-lite";


const SideBar = observer((props: any) => {
  const { user, signout } = useAuth();
  const [menu, setMenu] = useState<AuthLink[]>([]);
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState<AuthLink>();
  const [canCreateNewRequest, setCanCreateNewRequest] = useState(false);

  const logOut = async () => {
    console.log("logout");
    await signout();
    window.localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    if (userIsAdmin(user)) {
      setMenu([...adminNavigation]);
      setActiveMenu([...adminNavigation][0]);
    } else {
      setMenu([...userNavigation]);
      setActiveMenu([...userNavigation][0]);
    }
  }, [user]);

  useEffect(() => {
    const handleCredentialNewRequest = async () => {
      try {
        const canNewRequest = await statesService.GetCredentialNewRequestStatus();
        sidebarStore.setCanCreateNewRequest(canNewRequest.data);
      } catch (error) {
        console.error("Error checking request status:", error);
      }
    };
    handleCredentialNewRequest();
  }, []);

  return (
    <div className="bg-white min-h-screen w-56 text-black flex-shrink-0 border-r border-solid border-grey-200">
      {/* Logo */}
      <div className="mb-2 px-7 py-2 border-b border-grey-200 h-16 flex items-center">
        <CredentalLogo width={150} />
      </div>
      {/* Navigation */}
      <div className="px-3 py-4 flex flex-col">
        {menu.map((item, i) => {
          // Dynamically handle New Credentialing Request or Change Insurance Companies
          if (
            (sidebarStore.canCreateNewRequest && item.name === "Change Insurance Companies") ||
            (!sidebarStore.canCreateNewRequest && item.name === "New Credentialing Request")
          ) {
            return null;
          }

          return (
            <Link to={item.href} key={i}>
              <div
                id={"div_" + i}
                onClick={() => setActiveMenu(item)}
                className={`flex text-base items-center py-2 px-4 rounded mb-1 cursor-pointer transition-all ease-out duration-200 hover:text-primary-500 ${
                  activeMenu === item && "bg-primary-50 text-primary-500 font-bold"
                }`}
              >
                <div className="w-5 mr-3">{item.icon}</div>
                {item.name === "Log out" ? (
                  <p className="font-regular" onClick={logOut}>
                    {item.name}
                  </p>
                ) : (
                  <p className="font-regular">{item.name}</p>
                )}
              </div>
            </Link>
          );
        })}
      </div>
      <ToastContainer />
    </div>
  );
})
export default SideBar
