import {
  ChangeRequestStatus,
  QuestionnaireSubmittedStatus,
  QuestionType,
} from "../../../datasources/graphql/client-types";

interface showQuestionnaireParams {
  __typename?: "QuestionnaireSubmmited";
  id: string;
  qId: string;
  status: QuestionnaireSubmittedStatus;
  timestamp: number;
  user: {
    __typename?: "User";
    name: string;
    id?: string | null;
    email: string;
  };
  answers: Array<{
    __typename?: "QuestionnaireAnswer";
    id: string;
    value: string;
    type: string;
    qSubmittedId: string;
    question: {
      __typename?: "Question";
      id: string;
      qId: string;
      type: QuestionType;
      name: string;
      extras: string;
      isRequired: boolean;
      position: number;
      rowType: string;
      requireStorage: string;
      dropDownAnswers?: Array<string> | null;
    };
  }>;
  requestChanges: Array<{
    __typename?: "ChangeRequest";
    id: string;
    idQuestion: string;
    qSubmittedId: string;
    status: ChangeRequestStatus;
    reason: string;
  }>;
}

export default function ShowQuestionnaire({
  questionnaire,
  setQuestion,
}: {
  questionnaire: showQuestionnaireParams;
  setQuestion: (id: string) => void;
}) {
  return (
    <div className="flex flex-col bg-onBackground py-8 px-8 rounded-2xl mr-4 justify-center">
      <p className="w-full text-center">Questions</p>
      {questionnaire.answers.map((answer) => (
        <div className="mb-4 w-full">
          <div>
            <span className="font-extrabold">Question:</span>{" "}
            {answer.question.name}
          </div>
          <div>
            <span className="font-extrabold">Answer:</span> {answer.value}
          </div>
          <button
            className="bg-primary px-4 py-2 text-white rounded-2xl my-4"
            onClick={() => setQuestion(answer.question.id)}
          >
            Add Change Request
          </button>
        </div>
      ))}
    </div>
  );
}
