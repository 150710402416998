import React, { Component } from "react";
import CanvasDraw from "react-canvas-draw";
import { Button } from '@material-tailwind/react'
import userService from "../../Services/user.service";

function SaveSignature(signatureString:string) {
    let signaturePayload = {
        signature:signatureString
    }
    userService.PostSignature(signaturePayload);
}        


import { useState } from "react";

function Signature() {
    let saveableCanvas:any = null;
    return(
        <div>
            <CanvasDraw canvasWidth={1000} canvasHeight={500} brushRadius={0.8} hideGrid={true} mouseZoomFactor={0.1} lazyRadius={0.1} ref={canvasDraw => (saveableCanvas = canvasDraw)}/>
            
            <Button
            onClick={() => {
            saveableCanvas.clear();
            }}
            >
                CLEAR
            </Button>&nbsp;
            <Button
            onClick={() => {
                SaveSignature(saveableCanvas.getDataURL("image/jpg"));
            }}
            >
                Save
            </Button>
            </div>
    )
}
export default Signature;
