const constants = {
  questionTypes: {
    singleLine: 'Single-Line',
    multiLine: 'Multi-Line',
    url: 'URL',
    email: 'Email',
    number: 'Number',
    phoneNumber: 'Phone Number',
    currency: 'Currency',
    date: 'Date',
    time: 'Time',
    singleSelectDropdown: 'Single-Select Dropdown',
    multiSelectDropdown: 'Multi-Select Dropdown',
    file:"File",
    explanation:"Explanation"
  },
  buttonNames: {
    addQuestion: 'Add Question',
    updateQuestion: 'Update Question',
    createStep: 'Create Step',
    updateStep: 'Update Step',
  },
  toastMessage: {
    adminReviewCommentSuccess : 'Comments posted successfully',
    adminReviewCommentError : 'Comments could not be posted'
  },
  insuranceRequestStatusOptions: [
    { text: 'In Progress', value: 'In Progress' },
    { text: 'Changes Requested', value: 'Changes Requested' },
    { text: 'Credential Complete', value: 'Credential Complete' },
 ],
 credentialRequestStatusOptions: [
  { text: 'Started', value: 'Started' },
  { text: 'Feedback Requested', value: 'Feedback Requested'},
  { text: 'Processing', value: 'Processing'},
  { text: 'Credential Complete', value: 'Credential Complete'},
 ]
}
export default constants
