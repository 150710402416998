import { QuestionParams } from '../../../models/questionnaire'
import { Input } from '@material-tailwind/react'
import * as yup from 'yup'
import { QuestionType } from '../../../datasources/graphql/client-types'
import { OptionalObjectSchema } from 'yup/lib/object'
import { useFormik } from 'formik'
import { P } from '../../../components/typography/typography'

type Props = {
  questions: QuestionParams[]
  register: any
  errors: any
}

const DynamicForm = ({ questions, register, errors }: Props) => {
  return (
    <>
      {questions.map((question) => {
        if (question.type === 'string')
          return (
            <div key={question.id}>
              <Input label={question.name} {...register(question.id)} />
              {errors[question.id]?.message && <P className="text-red-600">{errors[question.id]?.message}</P>}
            </div>
          )
      })}
    </>
  )
}

export default DynamicForm
