//import { Roles } from "../datasources/graphql/client-types"

export interface User {
  id: string,
  name: string,
  email: string,
  questionnaireSubmitted: boolean,
  role: string,
  firstName: string,
  lastName: string
}

export function userEmpty() : User {
  return {
    email: '',
    id: '',
    name: '',
    questionnaireSubmitted: false,
    role: '',
    firstName: '',
    lastName: ''  }
}

export function userIsAdmin(user: User) {
//console.log(user.role== "0");
  if(user.role=== "practiceAdmin" || user.role=== "admin" || user.role== "0"){
    return true;
  }
  else{
    return false;
  } 
}

export interface UserCredentials{
  email: string,
  password: string,
}