import apiClient from './api'
class QuestionnaireService {
  async GetQuestionnaires(pageNo: any, pageSize: any, status: any) {
    try {
      const response = await apiClient.get(`/questionnaire/list/${status}?page=${pageNo}&resultsperpage=${pageSize}`)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
  async GetQuestion(questionId: any) {
    try {
      const response = await apiClient.get(`/questionnaire/question/${questionId}`)

      return response.data
    } catch (err) {
      console.log(err)
    }
  }
  async PostQuestion(Id: any, stepId: any, postData: any) {
    try {
      const response = await apiClient.post(`/questionnaire/${Id}/step/${stepId}/questions`, postData)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
  async GetQuestionTypes() {
    try {
      const response = await apiClient.get(`/Questionnaire/QuestionTypes`)

      return response.data
    } catch (err) {
      console.log(err)
    }
  }
  async GetQuestions(Id: any, stepId: any) {
    try {
      const response = await apiClient.get(`/questionnaire/${Id}/step/${stepId || '1'}/questions`)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
  async CreateQuestionnaireStep(Id: any, name: any) {
    try {
      const response = await apiClient.post(`/questionnaire/${Id}/step`, name)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
  async CreateQuestionnaire(postData: any) {
    try {
      const response = await apiClient.post('/Questionnaire', postData)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
  async GetQuestionnaireSteps(Id: any) { 
    try {
      const response = await apiClient.get(`/questionnaire/${Id}`); 
      return response.data;
      
    } catch (err) {
        console.log(err); 
    }
  }
  async GetQuestionnaireStates(Id: any) { 
    try {
      const response = await apiClient.get(`/questionnaire/${Id}/states`); 
      return response.data;
      
    } catch (err) {
        console.log(err); 
    }
  }
  async SetQuestionnaireStates(Id: any, states: any) { 
    try {
      const response = await apiClient.post(`/questionnaire/${Id}/states`, states); 
      return response.data;
      
    } catch (err) {
        console.log(err); 
    }
  }
  async GetQuestionnaireInsuranceCompany(Id: any) { 
    try {
      const response = await apiClient.get(`/questionnaire/${Id}/insurance-company`); 
      return response.data;
      
    } catch (err) {
        console.log(err); 
    }
  }
  async SetQuestionnaireInsuranceCompany(QuestionnaireId: any, InsuranceCompanyId: any) { 
    try {
      const response = await apiClient.post(`/questionnaire/${QuestionnaireId}/insurance-company/${InsuranceCompanyId}`); 
      return response.data;
      
    } catch (err) {
        console.log(err); 
    }
  }
  async PublishQuestionnaire(Id: any) { 
    try {
      const response = await apiClient.put(`/questionnaire/${Id}/publish`); 
      return response.data;
    } catch (err) {
        console.log(err); 
    }
  }
  async EditQuestion(Id: any, stepId: any, questionData: any) { 
    try {
      const response = await apiClient.put(`/Questionnaire/${Id}/step/${stepId}/questions`,questionData); 
      return response.data;
    } catch (err) {
        console.log(err); 
    }
  }
  async EditStep(Id: any, stepId: any, questionData: any) { 
    try {
      const response = await apiClient.put(`/Questionnaire/${Id}/step/${stepId}`,questionData); 
      return response.data;
    } catch (err) {
        console.log(err); 
    }
  }
  async DeleteQuestion(Id: any, stepId: any, questionId: any) { 
    try {
      const response = await apiClient.delete(`/Questionnaire/${Id}/step/${stepId}/question/${questionId}`); 
      return response.data;
    } catch (err) {
        console.log(err); 
    }
  }
  async DeleteStep(Id: any, stepId: any) { 
    try {
      const response = await apiClient.delete(`/Questionnaire/${Id}/step/${stepId}`); 
      return response.data;
    } catch (err) {
        console.log(err); 
    }
  }
  async GetInsuranceSteps() { 
    try {
      const response = await apiClient.get(`Credential/Questionnaire/steps`); 
      return response;
    } catch (err) {
        console.log(err); 
    }
  }
  async GetInsuranceQuestions(id : any) { 
    try {
      const response = await apiClient.get(`Credential/Questionnaire/${id}/answers`); 
      return response;
    } catch (err) {
        console.log(err); 
    }
  }
  
  async SubmitAnswers(payload : any, pageNumber : any) { 
    try {
      console.log({payload})
      const response = await apiClient.post(`/Credential/Questionnaire/${pageNumber}/answers`,payload); 
      return response;
    } catch (err) {
        console.log(err); 
    }
  }
  async GetAnswers( pageNumber : any) { 
    try {
      const response = await apiClient.get(`/Credential/Questionnaire/${pageNumber}/answers`); 
      return response;
    } catch (err) {
        console.log(err); 
    }
  }
  async GetAdminCredentialRequestSteps(credentialRequestId : any) { 
    try {
      const response = await apiClient.get(`/Admin/credentialrequest/${credentialRequestId}/steps`); 
      return response;
    } catch (err) {
        console.log(err); 
    }
  }
  async GetAdminCredentialRequestQuestions(credentialRequestId : any,stepNumber:any ) { 
    try {
      const response = await apiClient.get(`/Admin/credentialrequest/${credentialRequestId}/step/${stepNumber}`); 
      return response;
    } catch (err) {
        console.log(err); 
    }
  }
  async postAdminComments(payload : any) { 
    try {
      const response = await apiClient.post(`/Admin/change-request`, payload); 
      return response;
    } catch (err) {
        console.log(err); 
    }
  }
  async DeleteQuestionnaire(id : any) { 
    try {
      const response = await apiClient.delete(`/Questionnaire/${id}`); 
      return response;
    } catch (err) {
        console.log(err); 
    }
  }
  async MoveQuestionToDifferentStep(id: any, sourcestepid: any, questionid: any, destinationstepid: any) {
    try{
      const response = await apiClient.put(`/Questionnaire/${id}/step/${sourcestepid}/question/${questionid}/moveto/${destinationstepid}`);
      return response;
    }
    catch(error){
      console.log(error); 
    }
  }
}
export default new QuestionnaireService()
