import { Dialog, Transition } from '@headlessui/react'
import { useState, Fragment, useEffect } from 'react'
import { Button, Input, Select, Option } from '@material-tailwind/react'
import statesService from '../../../Services/states.service'
import questionnaireServices from '../../../Services/questionnaire.services'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { P } from '../../../components/typography/typography'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const initialValues = {
  name: '',
  stateID: '',
}
const addQuestionnaireSchema = Yup.object({
  name: Yup.string()
    .required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(40, 'Name cannot exceed 40 characters')
    .matches(/^[a-zA-Z ]*$/, 'Enter a valid name'),
  stateID: Yup.string().required('Please select a state'),
})

function AddQuestionnaireDialog(props: any) {
  const [state, newState] = useState([{ name: '', id: '' }])
  const [selectedState, setSelectedState] = useState('')
  const [questionnaire, setQuestionnaire] = useState()
  // const [showModal, setShowModal] = useState(true)
  // const [name, setName] = useState('')
  // const [stateId, setStateId] = useState('')
  // const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  const { values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: addQuestionnaireSchema,
    onSubmit: (values) => {
      createQuestinnaire(values)
      resetForm()
    },
  })

  const stateChangeHandle = (e: any) => {
    setSelectedState(e)
    console.log(e)
  }

  useEffect(() => {
    const getStateList = async () => {
      try {
        const result = await statesService.GetStates()
        newState(result)
      } catch (error) {
        console.log(error)
        throw error
      }
    }
    getStateList()
  }, [])
  const createQuestinnaire = async (values: any) => {
    let newQuestinnaire = {
      StateId: values.stateID,
      Name: values.name,
    }
    try {
      const result = await questionnaireServices.CreateQuestionnaire(newQuestinnaire)
      setQuestionnaire(result)
      localStorage.setItem('questionnaireData', JSON.stringify(result))
      localStorage.setItem('currentStep', '')
      navigate('/createQuestionnaire')
      toast.success('Questionnaire created successfully', { position: 'top-center' })
    } catch (error) {
      console.log(error)
      toast.error('Questionnaire could not be created', { position: 'top-center' })
      throw error
    }
  }

  function closeModal() {
    props.stateManager()
    resetForm()
  }
  const { heading, description, buttonName, requireName } = props

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  {/* <form className="mt-6" onSubmit={createQuestinnaire}> */}
                  <form className="mt-6" onSubmit={handleSubmit}>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      <h1 className="font-medium leading-tight text-2xl mt-0 mb-2 text-black-600">{heading}</h1>
                      <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">{description}</p>
                        {requireName && (
                          <div className="mb-4">
                            <Input
                              label="Name"
                              className="mb-5"
                              required={true}
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              //onBlur={handleBlur}
                            />
                            {errors?.name && <P className="text-red-600">{errors.name}</P>}
                          </div>
                        )}
                        <div className="dropdown">
                          <Select
                            label="State"
                            value={values.stateID}
                            onChange={(value) => setFieldValue('stateID', value)}
                          >
                            {state.map((state) => (
                              <Option value={state.id}>{state.name}</Option>
                            ))}
                          </Select>
                          {errors?.stateID && <P className="text-red-600">{errors.stateID}</P>}
                        </div>
                      </div>
                      {/*footer*/}
                    </Dialog.Title>
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <div className="modal-footer">
                        <Button className="flex items-center" type="submit">
                          {buttonName}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ToastContainer />
    </div>
  )
}

export default AddQuestionnaireDialog
