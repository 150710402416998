import { P, Link } from "../../components/typography/typography";
import SignUpForm from "./signUpForm";
import LoginForm from "./loginForm";
import { useLocation } from "react-router-dom";
import CredentalLogo from "../../icons/credentalLogo";

export default function AuthPage() {

  const location = useLocation();
  const isSignUp = location.pathname == '/signup';

  return (
    <section className="flex justify-center items-stretch min-h-screen bg-grey-50">
      <div className="w-full hidden md:flex bg-gradient-to-br from-primary-600 via-primary to-primary-300 p-8 justify-center items-center">
        <div className="max-w-lg">
          <img
            className="mb-7"
            src="/assets/images/mock-up.png"
            alt="logo"
          />
          <p className="mb-7 text-center text-white  text-lg">The veteran team at Credental knows that proficient and thorough credentialing is the starting point of safe, high-quality dentistry.</p>
          <div className="flex justify-center gap-3">
            <div className="w-3 h-3 rounded-lg bg-white"></div>
            <div className="w-3 h-3 rounded-lg bg-grey-400"></div>
            <div className="w-3 h-3 rounded-lg bg-grey-400"></div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-2xl bg-white p-8 flex flex-col justify-between items-center">
        <div className="max-w-full md:block"></div>
        <div className="w-96 max-w-full bg-white md:p-8 flex items-center flex-col">
          <CredentalLogo />
          <br/>
          { isSignUp ? <SignUpForm /> : <LoginForm />}
        </div>
        <div className="w-96 max-w-full bg-white p-8 text-center">
          {isSignUp ?
          <P>Already have an account? <Link to={'/login'} className="font-bold">Login</Link></P>
          :
          <P>Don't have an account yet? <Link to={'/signup'} className="font-bold">Sign Up</Link></P>
          }
        </div>
      </div>
    </section>
  )
}