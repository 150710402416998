import { ErrorMessage, Field } from "formik";
import CustomErrorMessage from "../../../../components/common/customFIeldErrorMessage";

export default function InsurancePaymentForm({
  montlyPrice,
  annuallyPrice,
}: {
  montlyPrice: number;
  annuallyPrice: number;
}) {
  return (
    <>
      <div className="w-full my-2">
        <div className="flex flex-col">
          <label className="flex w-full justify-between items-center mb-4">
            <Field
              type="radio"
              name={"paymentFrequency"}
              value={"montly"}
              // className="px-4 py-3 rounded-lg bg-textFieldBackground/[.15] mt-2 border focus:border-blue-500 focus:bg-textFieldBackground/[.30] focus:outline-none"
              // checked:bg-blue-600 appearance-none border-gray-300
              className="h-4 w-4 border rounded-sm bg-white border-blue-300 checked:border-blue-600 focus:outline-none transition duration-200 align-top  cursor-pointer"
            />
            <span className="mx-4">Montly - US ${montlyPrice} </span>
          </label>

          <label className="flex w-full justify-between items-center">
            <Field
              type="radio"
              name={"paymentFrequency"}
              value={"annually"}
              className="h-4 w-4 border rounded-sm border-blue-300 checked:border-blue-600 focus:outline-none transition duration-200 align-top  cursor-pointer"
            />
            <span className="mx-4">Annually - US ${annuallyPrice}</span>
          </label>
        </div>
        <ErrorMessage name={"paymentFrequency"}>
          {(msg) => <CustomErrorMessage message={msg} />}
        </ErrorMessage>
      </div>
    </>
  );
}
