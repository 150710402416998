import apiClient from "./api";
class UserService{
 
      async Login(postData: any) { 
        try {
        const response = await apiClient.post("/user/login", postData); 
       // console.log(response.data);
        return response.data;
          
        } catch (err) {
           console.log(err); 
          
        }
      }
      async RegisterUser(postData: any) { 
        try {
        const response = await apiClient.post("/user/register", postData); 
       // console.log(response);
        return response.data;
          
        } catch (err:any) {
          if(err.response.status==400)
          {
            console.log(err); 
            if(err.response.data.message=="A user is already reigstered with this email address.")
              return "email already exists";
          }
            
        }
      }
      async ConfirmRegistration(registerCode: string, email: string) { 
        try {
        const response = await apiClient.get("/user/register/"+registerCode+"/"+email); 
          return true;
        } catch (err:any) {
          return false;
        }
      }

      async ResendConfirmationEmail( email: string) { 
        try {
        const response = await apiClient.get("/user/resend-verification-email/"+email); 
          return true;
        } catch (err:any) {
          return false;
        }
      }

      async GetRefreshToken(guid:any) { 
        try {
          const response = await apiClient.get("/User/refresh-token/"+guid);
        
        return response.data;
          
        } catch (err) {
            console.log(err); 
        }
      }

      async PostSignature(postData:any) { 
        try {
          const response = await apiClient.post("/user/signature", postData); 
        
        return response.data;
          
        } catch (err) {
            console.log(err); 
        }
      }
}
export default new UserService();