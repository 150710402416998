import { Dialog, Transition } from '@headlessui/react'
import { Button, Select, Option } from '@material-tailwind/react'
import { useEffect, useState, Fragment } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { P } from '../../../components/typography/typography'
import { useNavigate } from 'react-router-dom'
import statesService from '../../../Services/states.service'
import insuranceServices from '../../../Services/insurance.services'

interface FormSigIn {
  name: string
  stateId: number
  insuranceId: number
}

const CreateInsuranceRequest = (props: any) => {
  const [state, newState] = useState([{ name: '', id: '' }])
  const [selectedState, setSelectedState] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [insuranceDll, setInsuaranceDll] = useState([{ name: '', id: '' }])
  const navigate = useNavigate()

  const schema = yup.object().shape({
    stateId: yup.number().required('State is required'),
    insuranceId: yup.string().required('Name is required'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormSigIn>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  function closeModal() {
    props.stateManager()
  }
  const stateChangeHandle = (e: any) => {
    setSelectedState(e)
    console.log(e)
    bindInsuaranceOnState(e)
  }

  useEffect(() => {
    const getStateList = async () => {
      try {
        const result = await statesService.GetStates()
        newState(result)
      } catch (error) {
        console.log(error)
        throw error
      }
    }
    getStateList()
  }, [])

  const bindInsuaranceOnState = async (stateId: any) => {
    const dllInsurance = await insuranceServices.GetInsuranceOnState(stateId)
    // const dllInsurance = await statesService.GetStates()
    setInsuaranceDll(dllInsurance)
  }

  const createInsuranceRequest = () => {
    navigate('/insuranceRequests')
  }

  const { heading, description, buttonName, requireName, requirePhoneNo, requireMapFile } = props
  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <form className="mt-6" onSubmit={handleSubmit(createInsuranceRequest)}>
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      <h1 className="font-medium leading-tight text-2xl mt-0 mb-2 text-black-600">{heading}</h1>
                      <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">{description}</p>
                        <div className="mb-4">
                          <div className="dropdown">
                            <Select label="State" onChange={stateChangeHandle} value={''}>
                              {state?.map((state) => (
                                <Option {...register('stateId')} value={state.id}>
                                  {state.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>

                        <div className="mb-4">
                          <div className="dropdown">
                            <Select label="Insurance">
                              {insuranceDll.map((insDll) => (
                                <Option {...register('insuranceId')} value={insDll.id}>
                                  {insDll.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      </div>
                      {/*footer*/}
                    </Dialog.Title>

                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <div className="modal-footer">
                        <Button
                          className="flex items-center"
                          type="submit"
                          onClick={() => {
                            closeModal
                            createInsuranceRequest()
                          }}
                        >
                          {buttonName}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            />
                          </svg>
                        </Button>
                        {errors.name?.message && <P className="text-red-600">{errors.name?.message}</P>}
                        {errorMessage && <P className="text-red-600">{errorMessage}</P>}
                      </div>
                    </div>
                  </Dialog.Panel>
                </form>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
export default CreateInsuranceRequest
