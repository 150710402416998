import React from 'react';
import {useState} from 'react'
import credentialService from '../../../Services/credential.services';
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
  } from "@material-tailwind/react";
  import { Button, Input, Option, Select, Textarea } from '@material-tailwind/react'
  import TokenService from "../../../Services/token.service";
  import axios from "axios";

const FileUpload = (props:any) => {
  const API_URL = process.env.REACT_APP_API_URL;  
  const [isUploaded, setIsUploaded] = useState(true);
  const [fileAnswer, setFileAnswer] = useState(props.question.answer)

  const deleteFile = () => {
    credentialService.DeleteFile(props.question.id);
    setIsUploaded(false);
    setFileAnswer("");
    props.formik.setFieldValue(props.question?.id.toString(), "");
  }


  let getFile = async() => {
    let vaultId = fileAnswer.substring(fileAnswer.indexOf(":")+1);
      axios
          .get(API_URL+"/Credential/download/"+vaultId, {
            responseType:"arraybuffer",
            headers: {
              "Content-Type": "application/json",
              "Authorization":"Bearer "+TokenService.getLocalAccessToken()
            },
          })
          .then((response) => {
            // handle the response
            const element = document.createElement("a");
      
            let blob = new Blob([response.data], {type: response.headers["content-type"]});
            element.href = URL.createObjectURL(blob);
            element.target="_new";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click(); 
                })
          .catch((error) => {
            // handle errors
            console.log(error);
          });
  }

    const handleFileUpload = (event:any) => {
        // get the selected file from the input
        const file = event.target.files[0];
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("formFile", file);
        // make a POST request to the File Upload API with the FormData object
        axios
          .post(API_URL+"/Credential/upload/"+props.question.id, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization":"Bearer "+TokenService.getLocalAccessToken()
            },
          })
          .then((response) => {
            // handle the response
            setFileAnswer(response.data.answer);
            setIsUploaded(true);
            props.formik.setFieldValue(props.question?.id.toString(), response.data.answer);
            console.log(response);
          })
          .catch((error) => {
            // handle errors
            alert("There was an error uploading your file.  Please try again.");
            console.log(error);
          });

      };
  return (
    <div>
        <div className="grid grid-cols-11 gap-1">
            <div className="col-span-5 w-full" title={props.question?.helpText}>
              <br></br>
              <label>{props.question?.label}</label>
              {props.question?.required ? <label style={{ color: 'red' }}>*</label> : ''}
            </div>
            <div className="col-span-5 w-full">
              <br></br>
              
              {fileAnswer != "" && isUploaded ?
                <div>
                  <span>File previously uploaded</span>&nbsp;<Button onClick={getFile}>View</Button>&nbsp;<Button onClick={deleteFile}>Delete</Button>
                </div>
                :<span><input type="file" onChange={handleFileUpload} />
                <input type="hidden" name={props.question?.id.toString()} disabled={props.isAdmin} value={props.formik.values[`${props.question?.id.toString()}`]} />
               </span>
              }

              {/* {formik.errors && <P className="text-red-600">{formik.errors}</P>} */}
            </div>
            <div className="col-span-1 ">
             { props.isAdmin && 
             <Menu placement={"bottom-end"} offset={{ mainAxis: 8 }}>
                <MenuHandler>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                  </svg>
 
                </MenuHandler>
                <MenuList>
                <MenuItem onClick={()=>{props.onChangeRequestSelected(props.question)}}>
                  <div className="flex items-center gap-2 text-grey-500 width=20 ">
       
                    <div>Change Request</div>
                  </div>
                </MenuItem>
                </MenuList>
                </Menu>}
            </div>
          </div>
    </div>
  );
};
export default FileUpload;