import { useEffect } from "react";
import Swal from "sweetalert2";
import { Loader } from "../../../../components/common/loader";
import { useGetPaymentTerminalQuery } from "../../../../datasources/graphql/client-types";

export default function IntelliPayForm({
  value,
  handleSubmit,
}: {
  value: string;
  handleSubmit: () => Promise<void>;
}) {
  const { data, loading, error } = useGetPaymentTerminalQuery();

  if (error) {
    console.log(error)
  }

  useEffect(() => {
    if (data && !loading) {
      var rg = document.createRange();
      document.documentElement.appendChild(
        rg.createContextualFragment(data.getPaymentTerminal)
      );

      (window as any).intellipay.initialize();

      (window as any).intellipay.runOnApproval(function (response: any) {
        console.log(JSON.stringify(response))
        handleSubmit();
      });

      (window as any).intellipay.runOnNonApproval(function (response: any) {
        console.log(JSON.stringify(response))
        Swal.fire({ icon: "error", title: "Payment Not submitted" });
      });
    }
  }, [data, loading]);

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <button data-ipayname="submit" className="ipayfield py-4 px-4 bg-primary text-white rounded-xl">
        Pay Insurrance
      </button>
      <input
        className="ipayfield"
        data-ipayname="amount"
        type="hidden"
        value={value}
      />
    </div>
  );
}
