import { Button, Input, Option, Select, Textarea } from '@material-tailwind/react'
import { Tabs, TabsHeader, TabsBody, Tab } from '@material-tailwind/react'
import React, { useState, Fragment, useEffect } from 'react'
import questionnaireServices from '../../../Services/questionnaire.services'
import { Dialog, Transition } from '@headlessui/react'
import _ from 'lodash'
import QuestionnaireStepDialog from './QuestionnaireStepDialog'
import ManageQuestionDialog from './ManageQuestionDialog'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Multiselect from 'multiselect-react-dropdown'
import constants from '../../../constants/constants'
import { Menu, MenuHandler, MenuList, MenuItem } from '@material-tailwind/react'
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'
import EditIcon from '../../../icons/editIcon'
import DeleteIcon from '../../../icons/deleteIcon'
import DuplicateIcon from '../../../icons/duplicateIcon'
import { useNavigate } from 'react-router-dom'
import StepSelectionDialog from './StepSelectionDialog'
import MoveIcon from '../../../icons/moveIcon'

function QuestionaireContainer() {
  const [modal, setModal] = useState(true)
  const [postedQuestions, setPostedQuestions] = useState([])
  const [steps, setSteps] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [iscreateStep, setIsCreateStep] = useState(false)
  const [isPostQuestion, setIsPostQuestion] = useState(false)
  const [selectedState, setSelectedState] = useState([])
  const [options, setOptions] = useState(false)
  const [currentStep, setCurrentStep] = useState({ name: '', id: '', order: '1' })
  const [isEditStep, setIsEditStep] = useState(false)
  const [isUpdateQuestion, setIsUpdateQuestion] = useState(false)
  const [initialValuesForStep, setInitialValuesForStep] = useState('')
  const [initialValuesForQuestion, setInitialValuesForQuestion] = useState({})
  const [questionOrderAndId, setQuestionOrderAndId] = useState({})
  const [deleteQuestionConfirmation, setDeleteQuestionConfirmation] = useState(false)
  const [currentStepName, setCurrentStepName] = useState('')
  const [questionIdToBeDeleted, setQuestionIdToBeDeleted] = useState()
  const [deleteQuestionnaireConfirmation, setDeleteQuestionnaireConfirmation] = useState(false)
  const [selectStepModal, setSelectStepModal] = useState(false)
  const questionnaireLocalStorage = JSON.parse(localStorage.getItem('questionnaireData')!)
  const styles = {
    root: {
      margin: 0,
      width: '100%',
      padding: 4,
      wordWrap: 'break-word',
    },
    leftColumn: {
      //backgroundColor: 'green',
      marginTop: '2%',
     // alignContent: 'center',
      alignItems: 'center',
      //display: 'block',
    },
    rightColumn: {
      marginTop: '10px',
      alignContent: 'center',
      alignItems: 'center',
      display: 'block',
    },
    createStepBox: {
      // alignContent: 'center',
      // alignItems: 'center',
      // alignText: 'center',
      // marginLeft: '30px',
      // padding: '20px',
      // height: '120px',
      // width: '300px',
      // margin: '10px',
      alignContent: 'center',
      alignItems: 'center',
      alignText: 'center',
      padding: '3%',
      width: '100%',
    },
    questionDisplayBox: {
      width: '100%',
      border: '1px solid #DEDEDE',
    },
    addQuestionButton: {
      width: '100%',
      marginBottom: '1%',
    },
    wordWrap: {
      wordWrap: 'break-word',
    },
    inline: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loadingIcon: {
      paddingLeft: '50%',
    },
  }

  const getPostedQuestion = async () => {
    const postedQuestion = await questionnaireServices.GetQuestions(questionnaireLocalStorage.id, currentStep?.id)
    setPostedQuestions(postedQuestion)
  }
  const data = [
    {
      label: 'Questionnaire',
      value: 'all',
    },
    {
      label: 'Setting',
      value: 'published',
    },
  ]
  const visibility = ''
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }
  const toggleState = () => {
    modal == false ? setModal(true) : setModal(false)
    //setCreateStep(false)
  }
  const toggleStateCreateStep = () => {
    modal == false ? setModal(true) : setModal(false)
    //setCreateStep(true)
  }

  const setLocalStorage = async (step: any) => {
    localStorage.setItem('currentStep', JSON.stringify(step))
    const currentStepData = JSON.parse(localStorage.getItem('currentStep')!)
    setCurrentStepName(currentStepData?.name)
    const postedQuestion = await questionnaireServices.GetQuestions(questionnaireLocalStorage.id, currentStepData?.id)
    setCurrentStep(currentStepData)
    setPostedQuestions(postedQuestion)
  }
  let questionnaireSteps
  const getQuestion = async () => {
    try {
      questionnaireSteps = await questionnaireServices.GetQuestionnaireSteps(questionnaireLocalStorage?.id)
      setSteps(_.get(questionnaireSteps, 'steps', []))
      setCurrentStepName(_.get(questionnaireSteps, 'data[0].name', ''))
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  useEffect(() => {
    const getQuestion = async () => {
      try {
        questionnaireSteps = await questionnaireServices.GetQuestionnaireSteps(questionnaireLocalStorage?.id)
        setSteps(_.get(questionnaireSteps, 'steps', []))
        if (!_.isEmpty(_.get(questionnaireSteps, 'steps', []))) {
          setLocalStorage(_.get(questionnaireSteps, 'steps[0]', {}))
        }
      } catch (error) {
        console.log(error)
        throw error
      }
    }
    getQuestion()
  }, [])

  const publishQuestionnaire = async () => {
    try {
      if (localStorage.getItem('currentStep')!) {
        await questionnaireServices.PublishQuestionnaire(questionnaireLocalStorage?.id)
        setIsOpen(false)
        toast.success('Questionnaire published successfully', { position: 'top-center' })
      } else {
        toast.warn('Please a create a step', { position: 'top-center' })
      }
    } catch (error) {
      toast.error('Questionnaire could not be published', { position: 'top-center' })
      throw error
    }
  }
  function closeModal() {
    setIsOpen(false)
    setDeleteQuestionConfirmation(false)
    setDeleteQuestionnaireConfirmation(false)
  }
  function openModal() {
    setIsOpen(true)
  }

  function openCreateStep() {
    setIsEditStep(false)
    setIsCreateStep(true)
  }
  function openUpdateStep() {
    setIsCreateStep(true)
  }
  function openUpdateQuestion() {
    setIsPostQuestion(false)
  }
  function openPostQuestion() {
    setIsUpdateQuestion(false)
    if (localStorage.getItem('currentStep')!) {
      setIsPostQuestion(true)
    } else {
      toast.warn('Please create a new step', { position: 'top-center' })
    }
  }
  const stateSelectHandle = (e: any) => {
    setSelectedState(e)
    console.log({ e, selectedState })
  }

  const stateRemoveHandle = (e: any) => {
    setSelectedState(e)
    console.log({ e, selectedState })
  }

  const stateManager = () => {
    setIsCreateStep(false)
    setIsPostQuestion(false)
    setIsUpdateQuestion(false)
    setSelectStepModal(false)
    setLocalStorage(currentStep)
  }
  const editQuestion = async (question: any) => {
    try {
      const updateQuestionInitialValues = {
        label: question?.label,
        questionType: question?.questionType,
        helpText: question?.helpText,
        required: question?.required,
        answerOptions: question?.answerOptions || '',
        friendlyId: question?.friendlyId || '',
        order: question?.order || '',
        regex: question?.validationRegex || '',
      }
      setInitialValuesForQuestion(updateQuestionInitialValues)
      setQuestionOrderAndId({ id: question?.id, order: question?.order })

      console.log({ question })
      setLocalStorage(currentStep)
      setIsUpdateQuestion(true)
      openUpdateQuestion()
    } catch (error) {
      throw error
    }
  }
  const openDeleteConfirmationModal = (id: any) => {
    setDeleteQuestionConfirmation(true)
    setQuestionIdToBeDeleted(id)
  }
  const openDeleteQuestionnaireConfirmationModal = () => {
    setDeleteQuestionnaireConfirmation(true)
  }
  const deleteQuestionnaire = async () => {
    try {
      const result = await questionnaireServices.DeleteQuestionnaire(questionnaireLocalStorage?.id)
      if (result) {
        goBack()
        setDeleteQuestionnaireConfirmation(false)
        toast.success('Questionnaire deleted successfully', { position: 'top-center' })
      } else {
        setDeleteQuestionnaireConfirmation(false)
        toast.success('Questionnaire could not be deleted', { position: 'top-center' })
      }
    } catch (error) {
      toast.error('Questionnaire could not be deleted', { position: 'top-center' })
      throw error
    }
  }
  const deleteQuestion = async () => {
    try {
      await questionnaireServices.DeleteQuestion(questionnaireLocalStorage?.id, currentStep?.id, questionIdToBeDeleted)
      setDeleteQuestionConfirmation(false)
      toast.success('Deleted Question successfully', { position: 'top-center' })
      setLocalStorage(currentStep)
    } catch (error) {
      throw error
    }
  }
  const duplicateQuestion = async (question: any) => {
    const postQuestionPayload = [
      {
        questionType: question?.questionType,
        prompt: question?.label,
        friendlyId: question?.friendlyId,
        Label: question?.label,
        required: question?.required,
        HelpText: question?.helpText,
        answerOptions: question?.answerOptions,
      },
    ]
    try {
      await questionnaireServices.PostQuestion(questionnaireLocalStorage.id, currentStep?.id, postQuestionPayload)
      toast.success('Duplicate Question created successfully', { position: 'top-center' })
      setLocalStorage(currentStep)
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  const moveQuestiontoDifferentStep = async (step: any) => {
    const question = JSON.parse(localStorage.getItem('questionToMove')!)
    try {
      const response = await questionnaireServices.MoveQuestionToDifferentStep(
        questionnaireLocalStorage.id,
        currentStep?.id,
        question?.id,
        step?.id,
      )
      if (response?.status === 200) {
        toast.success(`Question successfully moved to ${step?.name}`, { position: 'top-center' })
        setLocalStorage(currentStep)
        setSelectStepModal(false)
      } else {
        setSelectStepModal(false)
        toast.error(`Moving question failed`, { position: 'top-center' })
      }
    } catch (error) {
      console.log(error)
      setSelectStepModal(false)
      toast.error(`Moving question failed`, { position: 'top-center' })
      throw error
    }
  }
  const deleteStep = async (stepToDelete: any) => {
    try {
      await questionnaireServices.DeleteStep(questionnaireLocalStorage?.id, stepToDelete?.id)
      toast.success('Deleted step successfully', { position: 'top-center' })
      const questionnaireSteps = await questionnaireServices.GetQuestionnaireSteps(questionnaireLocalStorage?.id)
      setSteps(_.get(questionnaireSteps, 'steps', []))
      questionnaireSteps?.steps?.map((step: any) => {
        if (step?.order == stepToDelete?.order - 1) {
          setLocalStorage(step)
        }
      })
    } catch (error) {
      throw error
    }
  }
  const duplicateStep = async (currentStep: any) => {
    const id = questionnaireLocalStorage.id
    const name = currentStep?.name
    const payload = { Name: name }
    try {
      await questionnaireServices.CreateQuestionnaireStep(id, payload)
      toast.success('Duplicate Step created successfully', { position: 'top-center' })
      getQuestion()
    } catch (error) {
      throw error
    }
  }
  const editStep = async (currentStep: any) => {
    setIsEditStep(true)
    openUpdateStep()
    setInitialValuesForStep(currentStep)
  }

  return (
    <div style={{ width: '100%' }}>
      <div className="mx-auto px-4 sm:px-8 flex-grow">
        <div className="py-5">
          <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
            <h1 className="text-2xl leading-tight text-3xl">
              <div style={{ display: 'inline-block' }}>
                <button onClick={goBack} className="full-left px-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                  </svg>
                </button>
              </div>
              {questionnaireLocalStorage?.name}
            </h1>
            <div className={`text-end ${visibility}`}>
              <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
                <Button className="flex items-center" onClick={openModal}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  PUBLISH QUESTIONNAIRE
                </Button>
                <Button
                  className="flex items-center bg-red-500 text-white"
                  onClick={openDeleteQuestionnaireConfirmationModal}
                >
                  <DeleteIcon />
                  DELETE QUESTIONNAIRE
                </Button>
                <Transition
                  appear
                  show={isOpen || deleteQuestionConfirmation || deleteQuestionnaireConfirmation}
                  as={Fragment}
                >
                  <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                              {isOpen
                                ? `Hello ${questionnaireLocalStorage?.name}, do you want to publish this questionnaire?`
                                : deleteQuestionConfirmation
                                ? 'Are you sure you want to delete this question?'
                                : deleteQuestionnaireConfirmation
                                ? `Are you sure you want to delete this questionnaire?`
                                : null}
                            </Dialog.Title>
                            <div className="mt-4" style={{ textAlign: 'right' }}>
                              <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={closeModal}
                                style={{ margin: '2px' }}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={
                                  isOpen
                                    ? publishQuestionnaire
                                    : deleteQuestionConfirmation
                                    ? deleteQuestion
                                    : deleteQuestionnaireConfirmation
                                    ? deleteQuestionnaire
                                    : () => {}
                                }
                                style={{ margin: '2px' }}
                              >
                                {isOpen
                                  ? 'Yes Publish'
                                  : deleteQuestionConfirmation || deleteQuestionnaireConfirmation
                                  ? 'Yes Delete'
                                  : ''}
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
              </form>
            </div>
          </div>
          <p></p>
        </div>
      </div>
      <Tabs value="html">
        <TabsHeader>
          {data.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => {
                setModal(false)
              }}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        {/* {!modal ? ( */}
        <TabsBody>
          <div className="grid grid-cols-10 gap-5">
            {/* {steps?.length > 0 ? ( */}
            <div className="col-span-3 w-full" style={styles.leftColumn}>
              {steps?.map((step: any) => (
                <>
                  <button
                    onClick={() => {
                      setLocalStorage(step)
                    }}
                    style={styles.createStepBox}
                  >
                    <div
                      className={`max-w-sm rounded bg-blue-${
                        step?.order == currentStep?.order ? `800` : `300`
                      } overflow-hidden shadow-lg w-full `}
                    >
                      <div className="px-6 py-4 w-full grid grid-cols-10 gap-2">
                        <div
                          className="font-bold text-xl mb-2 text-white col-span-9 w-full"
                          style={{ display: 'inline-block' }}
                        >
                          {step?.name}
                        </div>
                        <div className="col-span-1 w-full" style={{ display: 'inline-block' }}>
                          {/* <p className="text-gray-700 text-base text-white">Step {step?.order}</p> */}
                          <Menu placement={'bottom-end'} offset={{ mainAxis: 8 }}>
                            <MenuHandler>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="white"
                                color="white"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                />
                              </svg>
                              {/* <KebabIcon /> */}
                            </MenuHandler>
                            <MenuList>
                              <MenuItem
                                onClick={() => {
                                  editStep(step)
                                }}
                              >
                                <div className="flex items-center gap-2 text-grey-500 width=20 ">
                                  <EditIcon />
                                  <div>Edit</div>
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  duplicateStep(step)
                                }}
                              >
                                <div className="flex items-center gap-2 text-grey-500">
                                  <DuplicateIcon />
                                  <div>Duplicate</div>
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  deleteStep(step)
                                }}
                              >
                                <div className="flex items-center gap-2 text-grey-500">
                                  <DeleteIcon />
                                  <div>Delete</div>
                                </div>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </div>
                        {/* <p className="text-gray-700 text-base text-white">ID {step?.order}</p> */}
                      </div>
                    </div>
                  </button>
                </>
              ))}
              <button onClick={openCreateStep} style={styles.createStepBox}>
                <div className={`max-w-sm rounded bg-blue-300 overflow-hidden shadow-lg w-full `}>
                  <div className="px-6 py-4 w-full">
                    <p className="font-bold text-xl mb-2 text-white">Create New Step</p>
                  </div>
                </div>
              </button>
            </div>
            {/* ) : (
              <div className="col-span-3 w-full" style={styles.leftColumn}>
               <div style={styles.loadingIcon}>
               <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                </div>
                </div>
            )}  */}

            <div className="col-span-7 w-full" style={styles.rightColumn}>
              <div>
                <div style={styles.inline}>
                  {/* <div>
                    <Button
                    style={styles.navigationButton}
                    >
                      <ArrowNarrowLeftIcon width={20} />
                    </Button>
                  </div> */}
                  <div>{!_.isEmpty(currentStepName) ? currentStepName : '' || ''}</div>
                  {/* <div>
                    <Button>
                      <ArrowNarrowRightIcon width={20} />
                    </Button>
                  </div> */}
                </div>
              </div>
              <div style={styles.questionDisplayBox}>
                <div className=" rounded-lg bg-white shadow-lg">
                  <div className="p-6 flex flex-col justify-start">
                    {postedQuestions?.map((question: any) => {
                      let answerOptions
                      if (!_.isEmpty(question?.answerOptions)) {
                        answerOptions = question?.answerOptions.split(',')
                      }
                      console.log({ answerOptions })
                      //setOptions(answerOptions)

                      switch (question?.questionType) {
                        case constants?.questionTypes?.explanation:
                          return (
                            <div className="grid grid-cols-11 gap-1">
                              <div className="col-span-11 justify-center flex" title={question?.helpText}>
                                <label style={{ fontWeight: 'bold' }}>{question?.label}</label>
                                <Menu placement={'bottom-end'} offset={{ mainAxis: 8 }}>
                                  <MenuHandler>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                      />
                                    </svg>
                                    {/* <KebabIcon color={"black"}/> */}
                                  </MenuHandler>
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        editQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500 width=20 ">
                                        <EditIcon />
                                        <div>Edit</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        duplicateQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DuplicateIcon />
                                        <div>Duplicate</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        openDeleteConfirmationModal(question?.id)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DeleteIcon />
                                        <div>Delete</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectStepModal(true)
                                        localStorage.setItem('questionToMove', JSON.stringify(question))
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <MoveIcon />
                                        <div>Move</div>
                                      </div>
                                    </MenuItem>
                                  </MenuList>
                                </Menu>

                                
                              </div>
                              

                            </div>  
                              )

                        case constants?.questionTypes?.singleLine:
                        case constants?.questionTypes?.url:
                        case constants?.questionTypes?.phoneNumber:
                        case constants?.questionTypes?.number:
                        case constants?.questionTypes?.currency:
                          return (
                            <div className="grid grid-cols-11 gap-1">
                              <div className="col-span-5 w-full" title={question?.helpText}>
                              <span  className="text-red-600">{question?.order} - </span>
                                <label style={{ fontWeight: 'bold' }}>{question?.label}</label>
                                {question?.required ? <label style={{ color: 'red' }}>*</label> : ''}
                                <br />
                              </div>
                              <div className="col-span-3 w-full" style={{ display: 'inline-block' }}>
                                <Input
                                 // label={question?.label}
                                  className="mb-5"
                                  type="text"
                                  required={question?.required}
                                />
                                 <span  className="text-red-900">{question?.friendlyId}</span>
                                {/* <button onClick={()=>{toggleOptions(question?.id)}} >
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          </button>
                                {options && optionsButton()} */}
                              </div>
                              <div className="col-span-2 w-full">
                                
                                <Menu placement={'bottom-end'} offset={{ mainAxis: 8 }}>
                                  <MenuHandler>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                      />
                                    </svg>
                                    {/* <KebabIcon color={"black"}/> */}
                                    
                                  </MenuHandler>
                                 
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        editQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500 width=20 ">
                                        <EditIcon />
                                        <div>Edit</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        duplicateQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DuplicateIcon />
                                        <div>Duplicate</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        openDeleteConfirmationModal(question?.id)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DeleteIcon />
                                        <div>Delete</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectStepModal(true)
                                        localStorage.setItem('questionToMove', JSON.stringify(question))
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <MoveIcon />
                                        <div>Move</div>
                                      </div>
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </div>

                              {/* {
                            options &&
                            <>
                            <Select label="options">
                              <Option value="s">Edit</Option>
                              <Option value="s">Delete</Option>
                              <Option value="s">Duplicate</Option>

                            </Select>
                            </>
                          } */}
                              {/* <div className="menu-nav">
                                <div className="menu-item">click the 3 dots=</div>
                                <div className="dropdown-container" tabIndex={-1}>
                                  <div className="three-dots"></div>
                                  <div className="dropdown">
                                    <a href="#"><div>dropdown 1</div></a>
                                    <a href="#"><div>dropdown 2</div></a>
                                    <a href="#"><div>dropdown 3</div></a>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          )
                        case constants?.questionTypes?.multiLine:
                          return (
                            <div className="grid grid-cols-11 gap-1">
                              <div className="col-span-5 w-full" title={question?.helpText}>
                              <span  className="text-red-600">{question?.order} - </span>
                                <label style={{ fontWeight: 'bold' }}>{question?.label}</label>
                                {question?.required ? <label style={{ color: 'red' }}>*</label> : ''}
                                <br />
                              </div>
                              <div className="col-span-3 w-full">
                                <Textarea   className="mb-5" required={question?.required} />
                                <span  className="text-red-600">{question?.friendlyId}</span>
                              </div>
                              <div className="col-span-2 w-full">
                                <Menu placement={'bottom-end'} offset={{ mainAxis: 8 }}>
                                  <MenuHandler>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                      />
                                    </svg>
                                    {/* <KebabIcon color={"black"}/> */}
                                  </MenuHandler>
                                  
                                  
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        editQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500 width=20 ">
                                        <EditIcon />
                                        <div>Edit</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        duplicateQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DuplicateIcon />
                                        <div>Duplicate</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        openDeleteConfirmationModal(question?.id)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DeleteIcon />
                                        <div>Delete</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectStepModal(true)
                                        localStorage.setItem('questionToMove', JSON.stringify(question))
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <MoveIcon />
                                        <div>Move</div>
                                      </div>
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </div>
                            </div>
                          )
                        case constants?.questionTypes?.email:
                          return (
                            <div className="grid grid-cols-11 gap-1">
                              <div className="col-span-5 w-full" title={question?.helpText}>
                              <span  className="text-red-600">{question?.order} - </span>
                                <label style={{ fontWeight: 'bold' }}>{question?.label}</label>
                                {question?.required ? <label style={{ color: 'red' }}>*</label> : ''}
                                <br />
                              </div>
                              <div className="col-span-3 w-full">
                                <Input
                                  //label={question?.label}
                                  className="mb-5"
                                  type="email"
                                  required={question?.required}
                                />
                                 <span  className="text-red-600">{question?.friendlyId}</span>
                              </div>
                              <div className="col-span-2 w-full">
                                <Menu placement={'bottom-end'} offset={{ mainAxis: 8 }}>
                                  <MenuHandler>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                      />
                                    </svg>
                                    {/* <KebabIcon color={"black"}/> */}
                                  </MenuHandler>
                                   
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        editQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500 width=20 ">
                                        <EditIcon />
                                        <div>Edit</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        duplicateQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DuplicateIcon />
                                        <div>Duplicate</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        openDeleteConfirmationModal(question?.id)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DeleteIcon />
                                        <div>Delete</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectStepModal(true)
                                        localStorage.setItem('questionToMove', JSON.stringify(question))
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <MoveIcon />
                                        <div>Move</div>
                                      </div>
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </div>
                            </div>
                          )
                        case constants?.questionTypes?.singleSelectDropdown:
                          return (
                            <div className="grid grid-cols-11 gap-1">
                              <div className="col-span-5 w-full" title={question?.helpText}>
                              <span  className="text-red-600">{question?.order} - </span>
                                <label style={{ fontWeight: 'bold' }}>{question?.label}</label>
                                {question?.required ? <label style={{ color: 'red' }}>*</label> : ''}
                                <br />
                              </div>
                              <div className="col-span-3 w-full">
                                <Select >
                                  {answerOptions.map((answer: any) => (
                                    <Option value={answer}>{answer}</Option>
                                  ))}
                                </Select>
                                <span  className="text-red-600">{question?.friendlyId}</span>
                              </div>
                              <div className="col-span-2 w-full">
                                <Menu placement={'bottom-end'} offset={{ mainAxis: 8 }}>
                                  <MenuHandler>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                      />
                                    </svg>
                                  </MenuHandler>
                                   
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        editQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500 width=20 ">
                                        <EditIcon />
                                        <div>Edit</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        duplicateQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DuplicateIcon />
                                        <div>Duplicate</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        openDeleteConfirmationModal(question?.id)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DeleteIcon />
                                        <div>Delete</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectStepModal(true)
                                        localStorage.setItem('questionToMove', JSON.stringify(question))
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <MoveIcon />
                                        <div>Move</div>
                                      </div>
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </div>
                            </div>
                          )
                        case constants?.questionTypes?.multiSelectDropdown:
                          return (
                            <div className="grid grid-cols-11 gap-1">
                              <div className="col-span-5 w-full" title={question?.helpText}>
                              <span  className="text-red-600">{question?.order} - </span>
                               <label style={{ fontWeight: 'bold' }}>{question?.label}</label>
                                {question?.required ? <label style={{ color: 'red' }}>*</label> : ''}
                                <br />
                              </div>
                              <div className="col-span-3 w-full">
                                <Multiselect
                                  // label={question?.label}
                                  isObject={false}
                                  options={answerOptions}
                                  onSelect={stateSelectHandle}
                                  onRemove={stateRemoveHandle}
                                  //displayValue="name"
                                  placeholder="Select"
                                ></Multiselect>
                                 <span  className="text-red-600">{question?.friendlyId}</span>
                              </div>
                              <div className="col-span-2 w-full">
                                <Menu placement={'bottom-end'} offset={{ mainAxis: 8 }}>
                                  <MenuHandler>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                      />
                                    </svg>
                                    {/* <KebabIcon color={"black"}/> */}
                                  </MenuHandler>
                                  
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        editQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500 width=20 ">
                                        <EditIcon />
                                        <div>Edit</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        duplicateQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DuplicateIcon />
                                        <div>Duplicate</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        openDeleteConfirmationModal(question?.id)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DeleteIcon />
                                        <div>Delete</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectStepModal(true)
                                        localStorage.setItem('questionToMove', JSON.stringify(question))
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <MoveIcon />
                                        <div>Move</div>
                                      </div>
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </div>
                            </div>
                          )
                        case constants?.questionTypes?.date:
                          return (
                            <div className="grid grid-cols-11 gap-1">
                              <div className="col-span-5 w-full" title={question?.helpText}>
                              <span  className="text-red-600">{question?.order} - </span>
                                <label style={{ fontWeight: 'bold' }}>{question?.label}</label>
                                {question?.required ? <label style={{ color: 'red' }}>*</label> : ''}
                                <br />
                              </div>
                              <div className="col-span-3 w-full">
                                <Input
                                  //label={question?.label}
                                  className="mb-5"
                                  type="date"
                                  required={question?.required}
                                />
                                 <span  className="text-red-600">{question?.friendlyId}</span>
                              </div>
                              <div className="col-span-2 w-full">
                                <Menu placement={'bottom-end'} offset={{ mainAxis: 8 }}>
                                  <MenuHandler>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                      />
                                    </svg>
                                    {/* <KebabIcon color={"black"}/> */}
                                  </MenuHandler>
                                  
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        editQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500 width=20 ">
                                        <EditIcon />
                                        <div>Edit</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        duplicateQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DuplicateIcon />
                                        <div>Duplicate</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        openDeleteConfirmationModal(question?.id)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DeleteIcon />
                                        <div>Delete</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectStepModal(true)
                                        localStorage.setItem('questionToMove', JSON.stringify(question))
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <MoveIcon />
                                        <div>Move</div>
                                      </div>
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </div>
                            </div>
                          )
                        case constants?.questionTypes?.time:
                          return (
                            <div className="grid grid-cols-11 gap-1">
                              <div className="col-span-5 w-full" title={question?.helpText}>
                              <span  className="text-red-600">{question?.order} - </span>
                                <label style={{ fontWeight: 'bold' }}>{question?.label}</label>
                                {question?.required ? <label style={{ color: 'red' }}>*</label> : ''}
                                <br />
                              </div>
                              <div className="col-span-3 w-full">
                                <Input
                                  //label={question?.label}
                                  className="mb-5"
                                  type="time"
                                  required={question?.required}
                                />
                                 <span  className="text-red-600">{question?.friendlyId}</span>
                              </div>
                              <div className="col-span-2 w-full">
                                <Menu placement={'bottom-end'} offset={{ mainAxis: 8 }}>
                                  <MenuHandler>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                      />
                                    </svg>
                                    {/* <KebabIcon color={"black"}/> */}
                                  </MenuHandler>
                                
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        editQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500 width=20 ">
                                        <EditIcon />
                                        <div>Edit</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        duplicateQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DuplicateIcon />
                                        <div>Duplicate</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        openDeleteConfirmationModal(question?.id)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DeleteIcon />
                                        <div>Delete</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectStepModal(true)
                                        localStorage.setItem('questionToMove', JSON.stringify(question))
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <MoveIcon />
                                        <div>Move</div>
                                      </div>
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </div>
                            </div>
                          )
                        case constants?.questionTypes?.file:
                          return (
                            <div className="grid grid-cols-11 gap-1">
                              <div className="col-span-5 w-full" title={question?.helpText}>
                              <span  className="text-red-600">{question?.order} -</span>
                                <label style={{ fontWeight: 'bold' }}>{question?.label}</label>
                                {question?.required ? <label style={{ color: 'red' }}>*</label> : ''}
                                <br />
                              </div>
                              <div className="col-span-3 w-full">
                                <Input
                                  //label={question?.label}
                                  className="mb-5"
                                  type="file"
                                  required={question?.required}
                                />
                                 <span  className="text-red-600">{question?.friendlyId}</span>
                              </div>
                              <Menu placement={'bottom-end'} offset={{ mainAxis: 8 }}>
                                  <MenuHandler>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                      />
                                    </svg>
                                    {/* <KebabIcon color={"black"}/> */}
                                  </MenuHandler>
                                   
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        editQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500 width=20 ">
                                        <EditIcon />
                                        <div>Edit</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        duplicateQuestion(question)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DuplicateIcon />
                                        <div>Duplicate</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        openDeleteConfirmationModal(question?.id)
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <DeleteIcon />
                                        <div>Delete</div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectStepModal(true)
                                        localStorage.setItem('questionToMove', JSON.stringify(question))
                                      }}
                                    >
                                      <div className="flex items-center gap-2 text-grey-500">
                                        <MoveIcon />
                                        <div>Move</div>
                                      </div>
                                    </MenuItem>
                                  </MenuList>
                                </Menu>

                            </div>
                          )
                      }
                    })}
                  </div>
                  <br />

                  <Button type="button" onClick={openPostQuestion} style={styles.addQuestionButton}>
                    Add Question
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </TabsBody>
        {/* ) : createStep ? ( */}
        {/* <CreateNewStep questionnaireLocalStorage={questionnaireLocalStorage} /> */}

        {/* ) : ( */}
        {/* <UpdateQuestion questionnaireLocalStorage={questionnaireLocalStorage}
            getPostedQuestion={getPostedQuestion} /> */}

        {/* )} */}
      </Tabs>

      <>
        <QuestionnaireStepDialog
          iscreateStep={iscreateStep}
          stateManager={stateManager}
          questionnaireLocalStorage={questionnaireLocalStorage}
          getQuestion={getQuestion}
          setLocalStorage={setLocalStorage}
          isEditStep={isEditStep}
          initialValuesForStep={initialValuesForStep}
        />

        <ManageQuestionDialog
          isPostQuestion={isPostQuestion}
          stateManager={stateManager}
          questionnaireLocalStorage={questionnaireLocalStorage}
          getPostedQuestion={getPostedQuestion}
          isUpdateQuestion={isUpdateQuestion}
          initialValuesForQuestion={initialValuesForQuestion}
          setPostedQuestions={setPostedQuestions}
          questionOrderAndId={questionOrderAndId}
          postedQuestions={postedQuestions}
        />
        <StepSelectionDialog
          isOpen={selectStepModal}
          stateManager={stateManager}
          // steps={steps}
          submitMethod={moveQuestiontoDifferentStep}
          stepsOptions={steps}
          currentStep={currentStep}
        />
      </>
      <ToastContainer />
    </div>
  )
}

export default QuestionaireContainer
