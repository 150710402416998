import { Link as ReactRouterLink, To } from "react-router-dom";
import { ReactChild } from "react";

const H1 = (props: {className?: String, children: ReactChild|ReactChild[]}) => <h1 className={`text-5xl font-sans ${props.className}`}>{props.children}</h1>

const H2 = (props: {className?: String, children: ReactChild|ReactChild[]}) => <h2 className={`text-4xl font-sans ${props.className}`}>{props.children}</h2>

const H3 = (props: {className?: String, children: ReactChild|ReactChild[]}) => <h3 className={`text-3xl font-sans ${props.className}`}>{props.children}</h3>

const H4 = (props: {className?: String, children: ReactChild|ReactChild[]}) => <h4 className={`text-2xl font-sans ${props.className}`}>{props.children}</h4>

const H5 = (props: {className?: String, children: ReactChild|ReactChild[]}) => <h5 className={`text-xl font-sans ${props.className}`}>{props.children}</h5>

const P = (props: {className?: String, children: ReactChild|ReactChild[]}) => <p className={`text-base font-sans text-grey-700 font-light ${props.className}`}>{props.children}</p>

const Link = (props: {className?: String, to?: To, children: ReactChild|ReactChild[], onClick?: Function}) => (
  props.to ?
  <ReactRouterLink
    className={`text-base font-sans font-light text-blue-700 hover:text-blue-500 ${props.className}`}
    to={props.to}
  >
    {props.children}
  </ReactRouterLink>
  :
  <a className={`text-base font-sans font-light text-blue-700 hover:text-blue-500 ${props.className}`}>
    {props.children}
  </a>
)

export { H1, H2, H3, H4, H5, P, Link };