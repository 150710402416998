import {
  CheckIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import ReactSelect, { ActionMeta, SingleValue } from "react-select";
import Swal from "sweetalert2";
import {
  QuestionType,
  useDeleteQuestionMutation,
  useUpdateQuestionMutation,
} from "../../../datasources/graphql/client-types";
import { QuestionParams } from "../../../models/questionnaire";

const questionTypesToSelectArray = Object.values(QuestionType).map((value) => ({
  label: value,
  value,
}));

export function EditableQuestion({ question }: { question: QuestionParams }) {
  const [deleteQuestion] = useDeleteQuestionMutation();
  const [editQuestion] = useUpdateQuestionMutation({
    // updateQueries: GetQuestionnaireByIdDocument
  });
  const [editable, setEditable] = useState(false);
  const [questionName, setQuestionName] = useState<string>(question.name);
  const [position, setPosition] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [friendlyQuestionName, setFriendlyID] = useState<string>("");
  const [questionTypeValue, setQuestionTypeValue] = useState<QuestionType>(
    question.type
  );
  const [questionIsRequired, setQuestionIsRequired] = useState<boolean>(
    question.isRequired
  );
  const [selectOptions, setSelectOptions] = useState<string[]>([
    ...(question.dropDownAnswers || []),
  ]);
  const [newSelectOption, setNewSelectOption] = useState<string>("");

  async function handleDeleteQuestion() {
    try {
      const result = await Swal.fire({
        title: "Are you sure",
        icon: "warning",
        html: `Your want to delete ${question.name}`,
        showCancelButton: true,
      });

      await deleteQuestion({
        variables: {
          id: question.id,
        },
      });

      if (result.isConfirmed) {
        await Swal.fire({
          icon: "success",
          title: "Question deleted",
        });
      }
    } catch (error) {
      Swal.fire({ icon: "error", title: "Error", html: `${error}` });
    }
  }

  function toggleEdit() {
    setEditable((prev) => !prev);
  }

  function handleChange(
    newValue: SingleValue<{
      label: QuestionType;
      value: QuestionType;
    }>,
    actionMeta: ActionMeta<{
      label: QuestionType;
      value: QuestionType;
    }>
  ) {
    setQuestionTypeValue(newValue?.value || QuestionType.String);
  }

  function cancelEdit() {
    setQuestionName("");
    setEditable(false);
    setQuestionTypeValue(question.type);
    setSelectOptions(question.dropDownAnswers || []);
    setNewSelectOption("");
  }

  async function submitQuestion() {
    try {
      const result = await Swal.fire({
        icon: "question",
        title: "Are you sure?",
        html: "Do you want to edit this question",
        showCancelButton: true,
      });

      if (!result.isConfirmed) {
        return;
      }

      const input = {
        name: questionName,
        type: questionTypeValue,
        isRequired: questionIsRequired,
        extras: question.extras,
        friendlyId: friendlyQuestionName,
        position: position,
        page: page,
        requireStorage: "",
        rowType: "",
        dropDownAnswers: selectOptions.length > 0 ? selectOptions : undefined,
      };

      console.log(input);

      editQuestion({
        variables: {
          input,
          questionId: question.id,
        },
      });

      await Swal.fire({ icon: "success", title: "Question changed" });
      setEditable(false);
    } catch (error) {
      Swal.fire({ icon: "error", title: "Error", html: `${error}` });
    }
  }

  function handleQuestionNameChange(event: any) {
    setQuestionName(event.target.value);
  }

  function handleFriendlyNameChange(event: any){
    setFriendlyID(event.target.value);
  }

  function handleIsRequiredChange() {
    setQuestionIsRequired((prev) => !prev);
  }

  function isDropdown() {
    return questionTypeValue === "select";
  }

  function createNewDropDownOption() {
    setSelectOptions((prev) => [...prev, newSelectOption]);
    setNewSelectOption("");
    return;
  }
  function handlePositionChange(event: any){
    setPosition(parseInt(event.target.value) ? parseInt(event.target.value) : 0);
  }
  function handlePageChange(event: any){
    setPage(parseInt(event.target.value) ? parseInt(event.target.value) : 0);
  }


  function handleNewOptionChange(event: any) {
    setNewSelectOption(event.target.value);
  }

  function deleteSelectOption(index: number) {
    setSelectOptions((prev) =>
      prev.filter((value, valueIndex) => index !== valueIndex)
    );
  }

  return (
    <div className="flex justify-between w-full px-6 py-4 bg-background rounded-xl mb-4 min-w[300px]">
      {editable ? (
        <div>
          <div className="flex flex-col">
            <div className="flex justify-between">
              <input value={questionName} onChange={handleQuestionNameChange} />
              <ReactSelect
                defaultValue={{ label: question.type, value: question.type }}
                placeholder={"Select the type of the question"}
                isClearable
                options={questionTypesToSelectArray}
                onChange={handleChange}
                name="questionType"
              />
              <input value={friendlyQuestionName} placeholder={"Friendly ID"}  onChange={handleFriendlyNameChange} />
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={questionIsRequired}
                  onChange={handleIsRequiredChange}
                  name="isRequired"
                />{" "}
                <span>is Required</span>
              </label>
              {isDropdown() && (
                <>
                  {selectOptions.map((option, index) => (
                    <div key={index} className="flex justify-between">
                      <span>{option}</span>
                      <button
                        onClick={() => deleteSelectOption(index)}
                        className="w-[20px] relative"
                      >
                        <TrashIcon color={"red"} />
                      </button>
                    </div>
                  ))}
                  <div className="flex">
                    <input
                      placeholder="Create new option"
                      onChange={handleNewOptionChange}
                      value={newSelectOption}
                    />
                    <button
                      onClick={createNewDropDownOption}
                      className="w-[20px] relative"
                    >
                      <PlusCircleIcon color="blue" />
                    </button>
                  </div>
                </>
              )}
            </div>
            <div>
          <label>
              <span> Position:  </span>
            </label>
            <input value={position} placeholder={"Pos"} onChange={handlePositionChange} /> 
          </div>
          <div>
          <label>
              <span> Page:  </span>
            </label>
            <input value={page} placeholder={"Page"} onChange={handlePageChange} /> 
          </div>
          </div>
          

          <div className="flex w-full justify-end">
            <button className="mr-2 w-[24px] relative" onClick={submitQuestion}>
              <CheckIcon color="green" />
            </button>
            <button onClick={cancelEdit} className="w-[24px] relative">
              <XIcon color="red" />
            </button>
          </div>
        </div>
      ) : (
        <div className="flex justify-between w-full">
          <div className="w-1/2">{question.name}</div>
          <div className="w-1/4 flex min-w-min justify-end">
            <button onClick={toggleEdit} className="w-[24px] relative">
              <PencilIcon color="blue" />
            </button>
            <button
              onClick={handleDeleteQuestion}
              className="w-[24px] relative"
            >
              <TrashIcon color={"red"} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
