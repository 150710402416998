import 'react-toastify/dist/ReactToastify.css';
import ChangeRequests from './ChangeRequests';
import { Tabs, TabsBody } from "@material-tailwind/react";
import adminChangeRequests from '../../../Services/credential.services';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

const InsuranceChangeRequest = (props: any) => {

    const [changeRequests, setChangeRequests] = useState([]);
    const visbility = "";
    const credentialrequestData = JSON.parse(localStorage.getItem("credentialrequestid")!);
    console.log(props);
    const navigate = useNavigate();

    useEffect(() => {
        getChangeRequestsData(1);
    }, [])

    const getChangeRequestsData = async (credentialrequestid: any) => {
        try {
            const result = await adminChangeRequests.GetChangeRequestsData(credentialrequestData.credentialRequestId);
            setChangeRequests(result);
        } catch (error) {

            throw error;
        }
    };

    const btnOnClick = () => {
        navigate('/insuranceRequests');
    }

    function closeModal() {
        props.stateManager()
        //resetForm()
    }

    return (
        <><div className="container" >
            <div>
                <div className="mx-auto flex-grow">
                    <div className="py-5">
                        <div className="flex flex-row  w-full">
                            <button onClick={btnOnClick} className='full-left px-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                </svg>
                            </button>
                            <h1 className="text-2xl leading-tight text-3xl">
                                {props.heading}
                            </h1>
                            <div className={`text-end ${visbility}`}>

                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <Tabs value="html">

                        {/* <TabsHeader>
                            {props.data?.map(({ label, value }: any) => (
                                <Tab key={value} value={value} onClick={() => { getChangeRequestsData(value) }}>
                                    {`${label}`}
                                </Tab>
                            ))}
                        </TabsHeader> */}

                        <TabsBody>
                            <ChangeRequests
                                items={changeRequests}
                                getChangeRequestsData={getChangeRequestsData}
                            />
                        </TabsBody>
                    </Tabs>
                </div>
            </div>

        </div>

        </>

    )
}

export default InsuranceChangeRequest